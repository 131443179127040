// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import { ProducerSiteItem } from 'util/siteManagementTypes';
import { InputTagField } from '../InputTagField';
import { REGEX_OBJECT } from 'util/ConstantValues';
import {
  validateFieldEN,
  validateId,
  validatePostCodeForm,
  validateRequiredField,
  validateStringField,
} from 'util/validator';
import { uniqBy } from 'lodash';
import PostalCodeForm from '../PostalCodeForm';

interface Props {
  initForm: ProducerSiteItem;
  setInputForm: (value: ProducerSiteItem) => void;
  type: ModalType;
  provinceSuggestions: string[];
  districtSuggestions: string[];
  machineTypeSuggestions: string[];
  enrollableGradeSuggestions: string[];
  acceptanceGradeSuggestions: string[];
  enrollableTypeSuggestions: string[];
  acceptanceMaterialSuggestions: string[];
  packingStyleSuggestions: string[];
  qualityInspectionSuggestions: string[];
  producerMethodSuggestions: string[];
  onCreateProducerSite: (createProducerForm: ProducerSiteItem) => void;
  onUpdateProducerSite: (updateProducerForm: ProducerSiteItem) => void;
}

const CreateUpdateProducerSiteForm: React.FC<Props> = ({
  initForm,
  provinceSuggestions,
  districtSuggestions,
  machineTypeSuggestions,
  enrollableGradeSuggestions,
  acceptanceGradeSuggestions,
  enrollableTypeSuggestions,
  acceptanceMaterialSuggestions,
  packingStyleSuggestions,
  qualityInspectionSuggestions,
  producerMethodSuggestions,
  type,
  onCreateProducerSite,
  onUpdateProducerSite,
  setInputForm,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [formHasError, setFormHasError] = useState(false);

  const renderStep = (
    currentStep: number,
    formProps: FormRenderProps<ProducerSiteItem, Partial<ProducerSiteItem>>
  ) => {
    if (currentStep === 1) {
      return (
        <div>
          <div className={styles.textFrame}>
            <InputDataListField
              label={t('site_management.modal.field_id') || ''}
              placeholder={t('site_management.modal.field_id') || ''}
              name="id"
              required
              maxlength={20}
              disabled={type === ModalType.update}
            />
          </div>
          <div className={styles.textFrame}>
            <InputDataListField
              label={t('site_management.modal.field_name') || ''}
              placeholder={t('site_management.modal.field_name') || ''}
              name="name"
              required
              maxlength={100}
            />
            <InputDataListField
              label={t('site_management.modal.site_name_en')}
              placeholder={t('site_management.modal.site_name_en')}
              name="englishName"
              required
              maxlength={100}
              validRegex={REGEX_OBJECT.halfWidthCharacter}
            />
          </div>
          <PostalCodeForm
            provinceSuggestions={provinceSuggestions}
            districtSuggestions={districtSuggestions}
            formProps={formProps}
            mode={type}
          />
        </div>
      );
    }

    return (
      <>
        <div className={styles.textFrame}>
          <InputTagField
            label={t('site_management.modal.field_compression_method')}
            placeholder={
              t('site_management.modal.field_compression_method') || ''
            }
            name="producerMethod"
            maxlength={100}
            tagValues={formProps.values.producerMethod}
            dataSuggestions={producerMethodSuggestions.map((item) => ({
              name: item,
            }))}
            required
            onChangeData={(tags) => {
              formProps.form.mutators.setValue('producerMethod', uniqBy(tags));
            }}
          />

          <InputTagField
            label={t('site_management.modal.field_machine_type')}
            placeholder={t('site_management.modal.field_machine_type') || ''}
            name="machineType"
            maxlength={100}
            tagValues={formProps.values.machineType}
            dataSuggestions={machineTypeSuggestions.map((item) => ({
              name: item,
            }))}
            onChangeData={(tags) => {
              formProps.form.mutators.setValue('machineType', uniqBy(tags));
            }}
          />

          <InputTagField
            label={t('site_management.receivable_material_name')}
            placeholder={t('site_management.receivable_material_name') || ''}
            name="acceptanceMaterial"
            maxlength={100}
            required
            tagValues={formProps.values.acceptanceMaterial}
            dataSuggestions={acceptanceMaterialSuggestions.map((item) => ({
              name: item,
            }))}
            onChangeData={(tags) => {
              formProps.form.mutators.setValue(
                'acceptanceMaterial',
                uniqBy(tags)
              );
            }}
          />

          <InputTagField
            label={t('site_management.acceptable_grade')}
            placeholder={t('site_management.acceptable_grade') || ''}
            name="acceptanceGrade"
            maxlength={100}
            required
            tagValues={formProps.values.acceptanceGrade}
            dataSuggestions={acceptanceGradeSuggestions.map((item) => ({
              name: item,
            }))}
            onChangeData={(tags) => {
              formProps.form.mutators.setValue('acceptanceGrade', uniqBy(tags));
            }}
          />

          <InputTagField
            label={t('site_management.modal.field_waste_permits') || ''}
            placeholder={t('site_management.modal.field_waste_permits') || ''}
            name="enrollableType"
            required
            maxlength={100}
            tagValues={formProps.values.enrollableType}
            dataSuggestions={enrollableTypeSuggestions.map((item) => ({
              name: item,
            }))}
            onChangeData={(tags) => {
              formProps.form.mutators.setValue('enrollableType', uniqBy(tags));
            }}
            validRegex={REGEX_OBJECT.halfWidthCharacter}
          />

          <InputTagField
            label={t('site_management.enrollable_grade') || ''}
            placeholder={t('site_management.enrollable_grade') || ''}
            name="enrollableGrade"
            maxlength={100}
            tagValues={formProps.values.enrollableGrade}
            dataSuggestions={enrollableGradeSuggestions.map((item) => ({
              name: item,
            }))}
            required
            onChangeData={(tags) => {
              formProps.form.mutators.setValue('enrollableGrade', uniqBy(tags));
            }}
          />

          <InputTagField
            label={t('site_management.packing_styles')}
            placeholder={t('site_management.packing_styles') || ''}
            name="packingStyles"
            required
            maxlength={100}
            tagValues={formProps.values.packingStyles}
            dataSuggestions={packingStyleSuggestions.map((item) => ({
              name: item,
            }))}
            onChangeData={(tags) => {
              formProps.form.mutators.setValue('packingStyles', uniqBy(tags));
            }}
          />

          <InputTagField
            label={t('site_management.quality_inspection_method')}
            placeholder={t('site_management.quality_inspection_method') || ''}
            name="qualityInspectionMethod"
            maxlength={100}
            required
            tagValues={formProps.values.qualityInspectionMethod}
            dataSuggestions={qualityInspectionSuggestions.map((item) => ({
              name: item,
            }))}
            onChangeData={(tags) => {
              formProps.form.mutators.setValue(
                'qualityInspectionMethod',
                uniqBy(tags)
              );
            }}
          />

          <InputDataListField
            label={t('site_management.modal.field_staff_tel') || ''}
            placeholder={t('site_management.modal.field_staff_tel') || ''}
            maxlength={15}
            name="staffTel"
            validRegex={REGEX_OBJECT.number}
          />

          <InputDataListField
            label={
              t('site_management.modal.field_prev_year_input_amount') || ''
            }
            placeholder={
              t('site_management.modal.field_prev_year_input_amount') || ''
            }
            name="prevYearInputAmount"
            validRegex={REGEX_OBJECT.decimalNumberWithDot5}
            endExtraText="kg"
            required
          />
          <InputDataListField
            label={t('site_management.modal.field_prev_year_scope1') || ''}
            placeholder={
              t('site_management.modal.field_prev_year_scope1') || ''
            }
            name="prevYearScope1"
            validRegex={REGEX_OBJECT.decimalNumberWithDot10}
            endExtraText="kg-CO2"
            required
          />
          <InputDataListField
            label={t('site_management.modal.field_prev_year_scope2') || ''}
            placeholder={
              t('site_management.modal.field_prev_year_scope2') || ''
            }
            name="prevYearScope2"
            validRegex={REGEX_OBJECT.decimalNumberWithDot10}
            endExtraText="kg-CO2"
            required
          />
        </div>
      </>
    );
  };

  const renderButtonSubmit = () => {
    if (step === 1) {
      return t('site_management.modal.button_next');
    }

    return type === ModalType.add
      ? t('site_management.modal.compression_button_add')
      : t('site_management.modal.compression_button_update');
  };

  const renderValidate = (currentStep: number, values: ProducerSiteItem) => {
    if (currentStep === 1) {
      return {
        id: validateId(t('site_management.modal.field_id'), 20, values.id),
        name: validateStringField(
          t('site_management.modal.field_name'),
          100,
          values.name
        ),
        englishName: validateFieldEN(
          t('site_management.modal.site_name_en'),
          values.englishName,
          100
        ),
        ...validatePostCodeForm(values),
      };
    }

    return {
      producerMethod: validateRequiredField(
        values.producerMethod,
        t('site_management.modal.field_compression_method') || ''
      ),
      acceptanceMaterial: validateRequiredField(
        values.acceptanceMaterial,
        t('site_management.receivable_material_name') || ''
      ),
      acceptanceGrade: validateRequiredField(
        values.acceptanceGrade,
        t('site_management.receivable_material_name') || ''
      ),
      enrollableType: validateFieldEN(
        t('site_management.modal.field_waste_permits'),
        values.enrollableType
      ),
      enrollableGrade: validateRequiredField(
        values.enrollableGrade,
        t('site_management.enrollable_grade') || ''
      ),
      packingStyles: validateRequiredField(
        values.packingStyles,
        t('site_management.packing_styles') || ''
      ),
      qualityInspectionMethod: validateRequiredField(
        values.qualityInspectionMethod,
        t('site_management.quality_inspection_method') || ''
      ),
      prevYearInputAmount: validateRequiredField(
        values.prevYearInputAmount,
        t('site_management.modal.field_prev_year_input_amount') || ''
      ),
      prevYearScope1: validateRequiredField(
        values.prevYearScope1,
        t('site_management.modal.field_prev_year_scope1') || ''
      ),
      prevYearScope2: validateRequiredField(
        values.prevYearScope2,
        t('site_management.modal.field_prev_year_scope2') || ''
      ),
    };
  };

  const handleSubmit = (formData: ProducerSiteItem) => {
    type === ModalType.add
      ? onCreateProducerSite(formData)
      : onUpdateProducerSite(formData);
  };

  const handleStep = (
    currentStep: number,
    direction: 'next' | 'prev',
    data: ProducerSiteItem
  ) => {
    if (currentStep === 1 && direction === 'next') {
      setInputForm(data);
      setStep(2);
      return;
    }
    if (currentStep === 2 && direction === 'next') {
      handleSubmit(data);
      return;
    }
    if (currentStep === 2 && direction === 'prev') {
      setInputForm(data);
      setStep(1);
      setFormHasError(false);
      return;
    }
  };

  return (
    <div className={styles.createBody}>
      <Form<ProducerSiteItem>
        onSubmit={(values: ProducerSiteItem) => {
          handleStep(step, 'next', values);
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initForm}
        validate={(values) => {
          return renderValidate(step, values);
        }}
      >
        {(props) => {
          return (
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>{renderStep(step, props)}</div>

              <div className={styles.submit}>
                {(step === 2 || step === 3) && (
                  <button
                    type="button"
                    className={`${styles.prevButton}`}
                    onClick={() => {
                      handleStep(step, 'prev', props.values);
                    }}
                  >
                    {t('site_management.modal.button_prev')}
                  </button>
                )}

                <button
                  className={styles.submitButton}
                  disabled={props.invalid || formHasError}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                  }}
                >
                  {renderButtonSubmit()}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdateProducerSiteForm;
