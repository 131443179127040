// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { FileImportIcon } from 'components/atoms/icons/FileImportIcon';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import TableLoading from 'components/atoms/TableLoading';
import { InputDataListField } from 'components/molecules/InputDataListField';
import { SelectField } from 'components/molecules/SelectField';
import DOMPurify from 'dompurify';
import { ChangeEvent } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import { DEFAULT_PAGE, operatorTypeList, PAGE_SIZE } from 'util/ConstantValues';
import { OperatorType } from 'util/Enums';
import styles from './AdminResourceManagementTable.module.scss';

export interface FilterObject {
  inputSearch?: string;
  operatorType: string;
}
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickEdit: (item: any) => void;
  onClickImport: () => void;
  onClickDownloadData: () => void;
  fetchResourceList: (
    values?: { inputSearch?: string; operatorType?: string },
    nextPage?: number,
    forceRefresh?: boolean
  ) => void;
  onChangePage: (page: number) => void;
  currentPage: number;
  total: number;
  listResourceManagement: ResourceManagementItem[];
  filterObject?: FilterObject;
  onChangeFilterObject: (filterObject: FilterObject) => void;
}
const AdminResourceManagementTable: React.FC<Props> = ({
  onClickEdit,
  onClickImport,
  onClickDownloadData,
  fetchResourceList,
  onChangePage,
  currentPage,
  total,
  listResourceManagement,
  filterObject,
  onChangeFilterObject,
}) => {
  const { t } = useTranslation();

  const operatorTypes = operatorTypeList.filter(
    (operator) => operator.value !== OperatorType.Admin
  );

  const loadMore = () => {
    fetchResourceList(filterObject, currentPage + 1, false);
  };

  return (
    <div className={styles.tableList}>
      <div className={styles.titleTable}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('common.page', {
                page: total,
              }) ?? ''
            ),
          }}
        />
      </div>
      <div className={styles.tool}>
        <div className={styles.search}>
          <Form
            onSubmit={(values) => {
              onChangeFilterObject({
                inputSearch: values.inputSearch,
                operatorType: values.operatorType,
              });
              fetchResourceList(values, DEFAULT_PAGE, true);
            }}
            initialValues={filterObject}
            mutators={{
              changeField: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
          >
            {(props) => {
              // const handleChange = () => {
              //   debounceFn(() => props.form.submit());
              // };

              const handleChangeOperatorType = () => {
                props.form.submit();
              };

              return (
                <form
                  onSubmit={props.handleSubmit}
                  className={styles.formInput}
                >
                  <div className={styles.inputFrame}>
                    <div className={styles.inputSearch}>
                      <InputDataListField
                        handleChange={(value) => {
                          onChangeFilterObject({
                            inputSearch: value,
                            operatorType: props.values.operatorType,
                          });
                        }}
                        placeholder={'検索'}
                        name="inputSearch"
                      />

                      <div
                        className={styles.iconSearch}
                        onClick={() => {
                          fetchResourceList(props.values, DEFAULT_PAGE, true);
                        }}
                      >
                        <IconSearch />
                      </div>
                    </div>

                    <div className={styles.selectOperator}>
                      <SelectField
                        name={'operatorType'}
                        options={operatorTypes}
                        onChange={handleChangeOperatorType}
                        value={props.values.operatorType}
                      />
                    </div>
                  </div>

                  <div className={styles.line} />
                </form>
              );
            }}
          </Form>
        </div>

        <div className={styles.customButton}>
          <div
            className={`${styles.button} ${styles.buttonImport}`}
            onClick={onClickImport}
          >
            <FileImportIcon />
            <span>データのインポート</span>
          </div>

          <div
            className={`${styles.button} ${styles.buttonDownLoad}`}
            onClick={onClickDownloadData}
          >
            <IconDownload />
            <span>データのダウンロード</span>
          </div>
        </div>
      </div>
      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.resourceHistoryTable}>
          <thead>
            <tr>
              <th className={styles.operatorName}>事業者名</th>
              <th className={styles.siteName}>拠点名</th>
              <th className={styles.resourceId}>登録ID</th>
              <th>日付</th>
              <th>カテゴリ</th>
              <th>品目</th>
              <th>重量</th>
              <th>グレード</th>
              <th>種別</th>
              <th className={styles.status}>スターテス</th>
              <th>配送元</th>
              <th>配送先</th>
              <th className={styles.action}>アクション</th>
            </tr>
          </thead>
          <tbody>
            {listResourceManagement && listResourceManagement.length > 0 ? (
              listResourceManagement.map((item: ResourceManagementItem, i) => {
                return (
                  <tr key={i}>
                    <td className={styles.operatorName}>
                      <p title={item.operator.name}>
                        {item.operator.name || '-'}
                      </p>
                    </td>
                    <td className={styles.siteName}>
                      <p title={item.site.name}>{item.site.name || '-'}</p>
                    </td>
                    <td className={styles.resourceId}>
                      <p title={item.id}>{item.id || '-'}</p>
                    </td>
                    <td>
                      <p>{getSlaFormatYearMonthDate(item.datetime) || '-'}</p>
                    </td>
                    <td>
                      <p title={item.category?.name}>
                        {(item.category && item.category.name) || '-'}
                      </p>
                    </td>
                    <td>
                      <p title={item.item}>{item.item || '-'}</p>
                    </td>
                    <td>
                      <p title={item.weight}>{item.weight || '-'}</p>
                    </td>
                    <td>
                      <p title={item.grade?.name}>{item.grade?.name || '-'}</p>
                    </td>
                    <td>
                      <p title={item.materialType?.name}>
                        {item.materialType?.name || '-'}
                      </p>
                    </td>
                    <td className={styles.status}>
                      <p title={item.status}>{item.status || '-'}</p>
                    </td>
                    <td>
                      <p title={item.deliveryFrom}>
                        {item.deliveryFrom || '-'}
                      </p>
                    </td>
                    <td>
                      <p title={item.deliveryTo}>{item.deliveryTo || '-'}</p>
                    </td>
                    <td className={styles.action}>
                      <div className={styles.contentAction}>
                        {item.editable && (
                          <div className={styles.actionEdit}>
                            <IconEdit
                              onClick={(e: ChangeEvent) => {
                                e.stopPropagation();
                                onClickEdit(item);
                              }}
                            />
                          </div>
                        )}

                        {/* <div className={styles.actionDelete}>
                          <IconDelete
                            onClick={(e: ChangeEvent) => {
                              e.stopPropagation();
                            }}
                          />
                        </div> */}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
          </tbody>
          {listResourceManagement && listResourceManagement.length > 0 && (
            <TableLoading
              onNext={() => {
                if (
                  listResourceManagement.length >= PAGE_SIZE &&
                  listResourceManagement.length < total
                ) {
                  onChangePage(currentPage + 1);
                  loadMore();
                }
              }}
            />
          )}
        </table>
      </div>
    </div>
  );
};

export default AdminResourceManagementTable;
