// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import styles from './InvoiceEditMailModal.module.scss';
import InvoiceEditMailForm, {
  InvoiceEditMailPut,
} from 'components/molecules/InvoiceEditMailModalForm/InvoiceEditMailForm';
import { getDetailInvoiceTemplate } from 'apis/invoice';

interface Props {
  onClose: () => void;
  currentSiteId: string;
  onSuccess: () => void;
}

const InvoiceEditMailModal: React.FC<Props> = ({
  onClose,
  currentSiteId,
  onSuccess,
}) => {
  const [initForm, setInitForm] = useState<InvoiceEditMailPut>();
  useEffect(() => {
    getDetailInvoiceTemplate({
      siteId: currentSiteId,
      callback: (data) => {
        setInitForm({
          title: data?.title || '',
          bodyTemplate: data?.body ?? '',
          emailInvoices:
            data?.emails && data?.emails.length > 0
              ? data?.emails.map((item) => {
                  return {
                    email: item,
                  };
                })
              : [{ email: '' }],
        });
      },
    });
  }, [currentSiteId]);

  return (
    <div className={styles.invoiceEditMailModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>メール内容編集</p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>
          <InvoiceEditMailForm
            currentSiteId={currentSiteId}
            initialValue={initForm}
            onSuccess={onSuccess}
          />
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceEditMailModal;
