// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { firstPage, Path, OFFLINE_USAGE_SUPPORT_ROUTES } from 'routes';
import {
  resetUserAsAdminSite,
  selectUser,
  UserState,
} from 'redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { OperatorType, RoleType, TenantAuthen } from 'util/Enums';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { IconMenu } from 'components/atoms/icons/IconMenu';
import styles from './Navbar.module.scss';
import { IconClose2 } from 'components/atoms/icons/IconClose2';
import { IconClipBoardList } from 'components/atoms/icons/IconClipBoardList';
import { IconLogOut } from 'components/atoms/icons/IconLogOut';
import { useTranslation } from 'react-i18next';
import { IconOutlineFile } from 'components/atoms/icons/IconOutlineFile';
import IconPool from 'components/atoms/icons/IconPool';
import IconSetting from 'components/atoms/icons/IconSetting';
import IconHistory from 'components/atoms/icons/IconHistory';
import IconListAccept from 'components/atoms/icons/IconListAccept';
import IconBox from 'components/atoms/icons/IconBox';
import { IconBarcodeV2 } from 'components/atoms/icons/IconBarcodeV2';
import { IconScan } from 'components/atoms/icons/IconScan';
import useOutsideClick from 'util/hooks/useClickOutside';
import { IconImport } from 'components/atoms/icons/IconImport';
import IconDashboard from 'components/atoms/icons/IconDashboard';
import ConfirmLogoutModal from 'components/molecules/ConfirmLogoutModal';
import IconShipping from 'components/atoms/icons/IconShipping';
import { fnLogout, mergeClasses } from 'util/commons';
import CheckPasswordAndConfirmLogoutModal from 'components/molecules/CheckPasswordAndConfirmLogoutModal';
import LanguageSelect from 'components/atoms/LanguageSelect';
import { IconScale } from 'components/atoms/icons/IconScale';
import IconCategory from 'components/atoms/icons/IconCategory';
import { toast } from 'react-toastify';
import { IconExport } from 'components/atoms/icons/IconExport';
import { IconUser } from 'components/atoms/icons/IconUser';
import { version } from '../../../../package.json';
import useNetworkConnection from 'util/hooks/useNetworkConnection';
import { OFFLINE_USAGE_SUPPORT_PERMISSIONS } from 'util/ConstantValues';

type Permission<T> = {
  accept?: T[];
  deny?: T[];
};

type NavItem = {
  link: string;
  label: string;
  icon?: React.ReactNode;
  childrenLinks?: string[];
  permissions?: {
    role?: Permission<RoleType>;
    operatorType?: Permission<OperatorType>;
    customCondition?: boolean;
  }[];
};

export function Navbar() {
  const user = useSelector(selectUser) as UserState;
  const history = useHistory();
  const dispatch = useDispatch();
  const path = history.location.pathname;
  const isActive = (routes: string) => {
    if (path === routes || path.includes(routes)) {
      return 'active';
    } else {
      return '';
    }
  };
  const isWebview = window.navigator.userAgent.includes('wv');
  const { t } = useTranslation();
  const [isShowNavBar, setIsShowNavBar] = useState(false);
  const [isConfirmLogout, setIsConfirmLogout] = useState(false);

  const isOnline = useNetworkConnection();

  const handleSignOut = () =>
    fnLogout(history, () => setIsConfirmLogout(false));

  const handleMenuIcon = () => {
    setIsShowNavBar(true);
  };

  const onClickOutside = () => {
    setIsShowNavBar(false);
  };

  const { ref } = useOutsideClick({ onClickOutside });

  const labelSliceToDisplay = (
    label: string,
    maxNumOfCharacterDisplay = 20
  ) => {
    if (!label) {
      return '';
    }
    return label.length > maxNumOfCharacterDisplay
      ? `${label.slice(0, maxNumOfCharacterDisplay)}...`
      : label;
  };

  const renderNavItem = (navItem: NavItem[]) =>
    navItem.map(({ link, icon, label, childrenLinks, permissions }) => {
      const permissionGranted =
        !permissions?.length ||
        permissions.some((permission) => {
          const { role, operatorType, customCondition = true } = permission;

          if (!customCondition) {
            return false;
          }

          if (role) {
            if (
              role.accept?.length &&
              (!user.role || !role.accept.includes(user.role))
            ) {
              return false;
            }

            if (
              role.deny?.length &&
              (!user.role || role.deny.includes(user.role))
            ) {
              return false;
            }
          }

          if (operatorType) {
            if (
              operatorType.accept?.length &&
              (!user.operatorType ||
                !operatorType.accept.includes(
                  user.operatorType as OperatorType
                ))
            ) {
              return false;
            }

            if (
              operatorType.deny?.length &&
              (!user.operatorType ||
                operatorType.deny.includes(user.operatorType as OperatorType))
            ) {
              return false;
            }
          }

          return true;
        });

      if (
        !permissionGranted ||
        (OFFLINE_USAGE_SUPPORT_PERMISSIONS.some(
          ({ role, operatorType }) =>
            user.role &&
            role.includes(user.role) &&
            user.operatorType &&
            operatorType.includes(user.operatorType as OperatorType)
        ) &&
          !isOnline &&
          !OFFLINE_USAGE_SUPPORT_ROUTES.includes(link))
      ) {
        return null;
      }

      return (
        <li
          className={mergeClasses({
            [styles.active]:
              isActive(link) || childrenLinks?.some((li) => isActive(li)),
          })}
        >
          <Link to={link}>
            {icon}
            <span>{label}</span>
          </Link>
        </li>
      );
    });

  const navItems = useMemo(
    (): NavItem[] => [
      // Collect
      {
        link: Path.collects,
        icon: <IconOutlineFile />,
        label: t('nav.collect'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Collects],
            },
          },
        ],
      },
      // Compression
      {
        link: Path.receives,
        icon: <IconListAccept />,
        label: t('nav.receives'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Compressions],
            },
          },
        ],
      },
      {
        link: Path.compressions,
        icon: <IconBox />,
        label: t('nav.compressions'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Compressions],
            },
          },
        ],
      },
      {
        link: Path.shippingRegistration,
        icon: <IconShipping />,
        label: t('nav.shipping_registration'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Compressions],
            },
          },
        ],
      },
      // Recycle
      {
        link: Path.recycle,
        icon: <IconListAccept />,
        label: t('nav.recycle'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Recycle],
            },
          },
        ],
      },
      {
        link: Path.recycleProductRegistration,
        icon: <IconOutlineFile />,
        label: t('nav.recycle_product_registration'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Recycle],
            },
          },
        ],
      },
      // Separates
      {
        link: Path.recycle,
        label: t('nav.recycle_separates'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Separates],
            },
          },
        ],
      },
      // Emission
      {
        link: Path.dashboard,
        icon: <IconDashboard />,
        label: t('dashboard.title'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Brand],
            },
            role: {
              accept: [RoleType.BrandOwner],
            },
          },
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminOperator, RoleType.AdminSite],
            },
          },
        ],
      },
      {
        link: Path.poolDashboard,
        icon: <IconDashboard />,
        label: t('dashboard.title'),
        permissions: [
          {
            role: {
              accept: [RoleType.AdminPool],
            },
          },
        ],
      },
      {
        link: Path.siteManagement,
        icon: <IconCategory />,
        label: t('nav.site_management'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminOperator],
            },
          },
        ],
      },
      {
        link: Path.tenantDashboard,
        icon: <IconDashboard />,
        label: t('dashboard.title'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminTenant],
            },
          },
        ],
      },
      {
        link: Path.tenantManagement,
        icon: <IconUser width={24} height={24} />,
        label: t('nav.tenant_management'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminSite],
            },
          },
        ],
      },
      {
        link: Path.importCSV,
        icon: <IconImport />,
        label: t('nav.importCSV'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              deny: [RoleType.AdminOperator, RoleType.AdminTenant],
            },
            customCondition: !isWebview,
          },
        ],
      },
      {
        link: Path.externalScaleConnectionsHistory,
        icon: <IconScale />,
        label: t('nav.external_scale_connections'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminSite, RoleType.UserNormal],
            },
          },
        ],
      },
      {
        link: Path.exportDataInvoice,
        icon: <IconExport />,
        label: t('nav.export_data_invoice'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminSite],
            },
          },
        ],
      },
      {
        link: user.isShowTenants ? Path.selectTenant : Path.emissionNew,
        icon: <IconPool />,
        label: t('nav.emission'),
        childrenLinks: [
          Path.emissionNew,
          Path.selectTenant,
          Path.emissionNewDetail,
        ],
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              deny: [RoleType.AdminOperator, RoleType.AdminTenant],
            },
          },
        ],
      },
      {
        link: Path.unCollects,
        icon: <IconClipBoardList />,
        label: t('nav.un_collect'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              deny: [RoleType.AdminOperator, RoleType.AdminTenant],
            },
          },
        ],
      },
      {
        link: Path.tareWeightSubtraction,
        icon: <IconSetting />,
        label: t('nav.tare_management'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              deny: [RoleType.AdminOperator, RoleType.AdminTenant],
            },
          },
        ],
      },

      // Histories menu is a common item
      {
        link: Path.histories,
        icon: <IconHistory />,
        label: t('nav.histories'),
        permissions: [
          {
            operatorType: {
              deny: [
                OperatorType.Admin,
                OperatorType.Brand,
                OperatorType.Emissions,
              ],
            },
            role: {
              deny: [RoleType.AdminPool],
            },
          },
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              deny: [RoleType.AdminOperator, RoleType.AdminTenant],
            },
          },
        ],
      },
      {
        link: Path.barcodes,
        icon: <IconBarcodeV2 />,
        label: t('barcodes.heading'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminSite],
            },
            customCondition: user.originalTenantAuthen === TenantAuthen.Barcode,
          },
        ],
      },
      {
        link: Path.scanICCard,
        icon: <IconScan />,
        label: t('nav.scan_ic_card'),
        permissions: [
          {
            operatorType: {
              accept: [OperatorType.Emissions],
            },
            role: {
              accept: [RoleType.AdminSite],
            },
            customCondition: user.originalTenantAuthen === TenantAuthen.ICCard,
          },
        ],
      },
    ],
    [isWebview, user, t]
  );

  return (
    <div className={styles.navBar}>
      <div className={styles.hamburgerMenu}>
        {isShowNavBar === false && (
          <div className={styles.iconMenu} onClick={handleMenuIcon}>
            <IconMenu />
          </div>
        )}
      </div>

      {isShowNavBar === true && (
        <div className={styles.menuContentAll}>
          <div className={styles.menuContents} ref={ref}>
            <div className={styles.menuLinks}>
              <div
                className={styles.iconClose}
                onClick={() => setIsShowNavBar(false)}
              >
                <IconClose2 />
              </div>

              <div className={styles.menuTitle}>
                <img src="/assets/images/logo.svg" alt="logo" />
              </div>
              <div className={styles.menuSubTitleContainer}>
                <h4 className={styles.menuSubtitle}>{user.name}</h4>
              </div>

              <div className={styles.siteNameAndLanguages}>
                <div className={styles.siteName} title={user.siteName}>
                  {labelSliceToDisplay(
                    user.role === RoleType.AdminOperator ||
                      user.role === RoleType.BrandOwner
                      ? user.operatorName
                      : user.role === RoleType.AdminSite ||
                        user.role === RoleType.UserNormal
                      ? user.siteName
                      : '',
                    20
                  )}
                </div>

                <div className={styles.languages}>
                  <LanguageSelect />
                </div>
              </div>
              <ul>
                {renderNavItem(navItems)}

                {/** ログアウト */}
                <li
                  className="logout"
                  onClick={() => {
                    if (user.temporaryAdminSite) {
                      dispatch(resetUserAsAdminSite());
                      const redirect = firstPage(user);
                      history.push(redirect);
                      toast.info(t('messages.M_temporary_admin_site_logout'));
                      return;
                    }
                    setIsConfirmLogout(true);
                  }}
                >
                  <div className={styles.logout}>
                    <IconLogOut />
                    <span>{t('nav.logout')}</span>
                  </div>
                </li>
                <li className={styles.version}>Ver {version}</li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isConfirmLogout &&
        (user.operatorType === OperatorType.Emissions &&
        user.role === RoleType.UserNormal ? (
          <CheckPasswordAndConfirmLogoutModal
            onClose={() => setIsConfirmLogout(false)}
            onSubmit={handleSignOut}
          />
        ) : (
          <ConfirmLogoutModal
            onClose={() => setIsConfirmLogout(false)}
            onSubmit={handleSignOut}
          />
        ))}
    </div>
  );
}
