// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
export const API_ENDPOINTS = {
  // Brand endpoints
  TOTAL_UNASSIGNED_BRANDS: '/brands/total-unassign-items',
  BRAND_ITEMS: '/brands/:brand_id/brand-items',
  SITE_BRAND_ITEMS: '/brands/:brand_id/site-brand-items',
  OPERATORS_BRAND_ITEMS: '/brands/:brand_id/operators-brand-items',
  BRAND_SITES: '/brands/:brand_id/sites',
  BRAND_DETAIL: '/brands/:brand_id',

  // Dashboard endpoints
  DASHBOARD_BRAND_ITEMS: '/dashboards/brand-items',
  DASHBOARD_BRAND_ITEMS_EXPORT: '/dashboards/brand-items/export',
  DASHBOARD_WASTE_UNITS: '/dashboards/waste-units',
  DASHBOARD_WASTE_UNITS_EXPORT: '/dashboards/waste-units/export',
  DASHBOARD_CO2_EMISSIONS: '/dashboards/co2-emissions',
  DASHBOARD_CO2_EMISSIONS_EXPORT: '/dashboards/co2-emissions/export',
  DASHBOARD_RATE_RECYCLE_STATICS: '/dashboards/rate-recycle-statistics',
  DASHBOARD_RATE_RECYCLE_STATICS_EXPORT:
    '/dashboards/rate-recycle-statistics/export',
  DASHBOARD_RATE_RECYCLE_METHOD_SITE: 'dashboards/rate-recycle-method/site',
  DASHBOARD_RATE_RECYCLE_METHOD_SITE_EXPORT:
    'dashboards/rate-recycle-method/site/export',
  DASHBOARD_RATE_RECYCLE_METHOD_TENANT: 'dashboards/rate-recycle-method/tenant',
  DASHBOARD_RATE_RECYCLE_METHOD_TENANT_EXPORT:
    'dashboards/rate-recycle-method/tenant/export',
  DASHBOARD_WASTE_SITES: '/dashboards/waste-sites',
  DASHBOARD_WASTE_SITES_EXPORT: '/dashboards/waste-sites/export',
  DASHBOARD_WASTE_TENANTS: '/dashboards/waste-tenants',
  DASHBOARD_WASTE_TENANTS_EXPORT: '/dashboards/waste-tenants/export',
  DASHBOARD_WASTE_WEIGHT: '/dashboards/waste-weight',
  DASHBOARD_WASTE_WEIGHT_EXPORT: '/dashboards/waste-weight/export',
  DASHBOARD_RATE_WASTE_SITES: '/dashboards/rate-waste-sites',
  DASHBOARD_RATE_WASTE_SITES_EXPORT: '/dashboards/rate-waste-sites/export',
  DASHBOARD_RATE_WASTE_TENANTS: '/dashboards/rate-waste-tenants',
  DASHBOARD_RATE_WASTE_TENANTS_EXPORT: '/dashboards/rate-waste-tenants/export',
  DASHBOARD_DATA_FILTER: '/dashboards/data-filter',
  DASHBOARD_RATE_RECYCLE_METHODS: '/dashboards/rate-recycle-methods',
  DASHBOARD_RATE_RECYCLE_METHODS_EXPORT:
    '/dashboards/rate-recycle-methods/export',
  DASHBOARD_PURCHASES_RECYCLE_CHAINS_MATERIAL_FLOW:
    'dashboards/purchases/recycle-chains/material-flow',
  DASHBOARD_PURCHASES_EMISSION_OPERATOR_SITE_TOTAL:
    '/dashboards/purchases/emission-operator-site-totals',
  DASHBOARDS_POOL_AVERAGE_EMISSION_STATISTICS:
    '/dashboards/pool/average-emission-statistics',
  DASHBOARDS_POOL_RESOURCES: '/dashboards/pool/resources',
  DASHBOARDS_POOL_WASTE_TREND: '/dashboards/pool/waste-trend',
  DASHBOARDS_POOL_RATIO_OF_STATIONS: '/dashboards/pool/ratio-of-stations',
  DASHBOARDS_POOL_RATIO_OF_WASTES: '/dashboards/pool/ratio-of-wastes',
  DASHBOARDS_POOL_RATIO_OF_TYPES: '/dashboards/pool/ratio-of-types',
  DASHBOARDS_POOL_RATIO_OF_MATERIALS: '/dashboards/pool/ratio-of-materials',
  DASHBOARDS_TENANTS_RATE_WASTE_UNITS: 'dashboards/tenants/rate-waste-units',
  DASHBOARDS_TENANTS_RATE_RECYCLE_METHODS:
    '/dashboards/tenants/rate-recycle-methods',
  DASHBOARDS_TENANTS_RATE_WASTE_COSTS: '/dashboards/tenants/rate-waste-costs',
  DASHBOARDS_TENANTS_WASTES: '/dashboards/tenants/wastes',
  DASHBOARDS_TENANTS_COMPARE_TOTAL_EMISSION_WEIGHT:
    '/dashboards/tenants/compare-total-emission-weight',
  DASHBOARDS_TENANTS_COMPARE_RECYCLE_RATE:
    '/dashboards/tenants/compare-recycle-rate',
  DASHBOARDS_TENANTS_RATE_CO2_WASTE_UNITS:
    '/dashboards/tenants/rate-co2-waste-units',

  // Offline site endpoints
  OFFLINE_SITE_DATA: '/offline/sites',
  SITE: '/sites',

  // Site api endpoints
  SITES: 'sites/:id',
  SITES_PURCHASES: '/sites/purchases?operatorId=:id',
  SITES_PURCHASES_UPDATE: '/sites/purchases/:id',
  SITE_INFO: 'sites/:id/info',
  SITES_EMISSIONS_SITE_STATIONS: '/sites/emissions/site-stations',
  RECYCLE: 'recycle',
  RECYCLE_INFO: 'recycle/info',
  SITES_PRODUCERS: '/sites/producers',
  SITES_PRODUCERS_UPDATE: '/sites/producers/:id',
  COMMON_MACHINE_TYPES: '/commons/machine-types',
  COMMON_QUALITY: '/commons/quality-inspection-methods',
  COMMON_PACKING_STYLES: 'commons/packing-styles',
  COMMON_PRODUCER_METHOD: '/commons/producer-methods',
  COMMON_GRADES: 'commons/grades',
  COMMON_GRADES_NAME: 'commons/grades/name',
  COMMON_MATERIAL_NAME: 'commons/material-names',
  COMMON_EXCHANGE_RATE_CURRENCIES: '/commons/exchange-rate-currencies',
  COMMONS_STATIONS: '/commons/stations',
  COMMONS_OPERATOR_POOLS: '/commons/operator-pools',
  COMMONS_SITE_COLLECTS: '/commons/site-collects',
  COMMONS_RECYCLE_METHODS: '/commons/recycle-methods',
  COMMONS_MATERIAL_NAMES: '/commons/material-names',
  COMMONS_WASTE_UNIT_NAMES: '/commons/waste-unit-names',
  COMMONS_TENANT_NAMES: 'commons/tenant/names',

  // User recycle
  RECYCLE_BOOKING_BY_QR: '/recycle/bookings/:qr',
  RECYCLE_REGIST_TEMP_RECEIVE: '/recycle/regist-temp-receive',
  RECYCLE_INPUT_RESOURCES: '/recycle/input-resources',

  // User Compression
  COMPRESSION_BOOKING_BY_QR: '/compressions/bookings/:qrCode',
  COMPRESSION_REGIST_TEMP_RECEIVE: '/compressions/regist-temp-receive',

  // User Collect
  COLLECT_BOOKING_BY_QR: '/collects/qr-scan/:qrCode',
  COLLECT_REGIST_TEMP_RECEIVE: '/collects/qr-scan/receive',

  // Emissions
  EMISSIONS: '/emissions',
  EMISSIONS_TERAOKA_IMPORT: '/emissions/import/teraoka',

  // Sites
  SITES_COLLECTS: 'sites/collects?operatorId=:id',
  SITES_COLLECTS_UPDATE: 'sites/collects/:id',
  COMMONS_EDI_CATEGORIES: '/commons/edi-categories',
  RECYCLE_CHAIN_STAGE_SITES:
    '/recycle-chains/stage-sites?stage=:stage&materialName=:materialName',
  WASTE_UNITS: '/waste-units',
  WASTE_UNITS_UPDATE: '/waste-units/:id',
  TRUCKS: 'trucks',
  TENANTS: 'tenants',
  WASTE_UNITS_TENANT: '/waste-units/tenant/:id',
  WASTE_UNITS_SITE: '/waste-units/site/:id',

  // Operator
  OPERATORS: '/operators',

  // User Purchase
  NEXT_STAGE_SITE: '/purchases/next-stage-sites',
  PURCHASE_DELIVERY: '/purchases/delivery',
  PURCHASE_GET_RESOURCES: '/purchases/resources',
  PURCHASE_GET_MATERIALNAMES: '/purchases/recycle-chains/material-names',

  // Purchase Dashboard
  DASHBOARDS_RECYCLE_CHAINS_RATE_WEIGHT:
    '/dashboards/recycle-chains/rate-weight',
  PURCHASE_PRODUCES: '/purchases/producers',
  PURCHASE_RATE_CO2: '/dashboards/recycle-chains/rate-co2-emissions',
  PURCHASE_RESOURCES_STATISTIC: ' /dashboards/purchases/resources-statistic',
  // Purchase Product Registration
  PURCHASES_RECEIVES: '/purchases/receives',
  PURCHASES_PRODUCTS_RECEIVES: '/purchases/products/receives',
  HISTORIES_PURCHASES_DELIVERED: '/work-histories/purchases/delivered',
  EDIT_HISTORIES_PURCHASES_DELIVERED: '/work-histories/purchases/delivered/:id',
  PURCHASES_PRODUCERS_INPUT_RESOURCES: '/purchases/producers/input-resources',

  // Purchase trace data
  PURCHASE_TRACES: '/purchases/traces',
  PURCHASES_TRACES_DETAIL: '/purchases/traces/:id',
  PURCHASES_CERTIFICATES_DOWNLOAD:
    '/purchases/certificates/download/:processingObjectId ',

  // Recycle chains
  RECYCLE_CHAINS: 'recycle-chains',
  DASHBOARDS_PURCHASES_RESOURCES_STATISTIC:
    '/dashboards/purchases/resources-statistic/:id',
  RECYCLE_CHAIN_DETAIL: 'recycle-chains/:id',
  WASTE_FUEL_UNITS: '/waste-fuel-units',

  // Purchase History
  WORK_HISTORIES_PURCHASES_PRODUCTS: '/work-histories/purchases/products',
  PURCHASES_PRODUCERS: '/work-histories/purchases/products/:id',

  // Purchase Resource Search
  WEIGHT_EMISSION_STATISTIC: '/dashboards/purchases/heatmap',
  DASHBOARDS_PURCHASES_MATERIAL_STATISTIC:
    '/dashboards/purchases/material-statistic',
  LOCATIONS: '/commons/provinces-districts',
  PURCHASE_WEIGHT_EMISSION_STATISTIC:
    '/dashboards/purchases/weight-emission-statistic',

  DASHBOARDS_PURCHASES_SITES_STATISTIC: '/dashboards/purchases/sites-statistic',

  //Resource management
  RESOURCES_PRODUCT: '/resources/products/:id',

  // Compression
  COMPRESSIONS_INPUT_RESOURCE: '/compressions/input-resources',

  // Teraoka import histories
  WORK_HISTORIES_EMISSION_TERAOKA_IMPORTS:
    '/work-histories/emission/teraoka-imports',
  DOWNLOAD_CSV_WORK_HISTORIES_EMISSION_TERAOKA_IMPORTS:
    '/work-histories/emission/teraoka-imports/:id',

  // Export Tenant Data
  TENANTS_INVOICES: '/tenants/invoices',

  //Tenant Management
  TENANTS_ADMIN_SITE: '/tenants/admin-site',
} as const;
