// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import TableLoading from 'components/atoms/TableLoading';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import { CreateUpdateCategoryFormInputType } from 'components/molecules/CreateUpdateCategoryInputForm';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import { Suggestion } from 'components/molecules/InputDataListField';
import DOMPurify from 'dompurify';
import _debounce from 'lodash/debounce';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { handleError } from 'util/errorHandler';
import CreateUpdateCategoryModal from '../CreateUpdateCategoryModal';
import styles from './index.module.scss';

export type FetchCategoryData = {
  categories: Category[];
  total: number;
  page: number;
  operatorName: string;
  siteName: string;
};
export type Category = {
  id: number;
  name: string;
  other: boolean;
  siteId: string;
  sortIndex?: number;
};

interface ModalState {
  isOpen: boolean;
  type: CreateUpdateCategoryFormInputType;
  itemId?: string;
  dataCategoryEdit?: Category;
}

interface Props {
  onSetBreadcrumb: (operatorName: string, siteName: string) => void;
}

const CustomizeListCategory: React.FC<Props> = ({ onSetBreadcrumb }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const api = createAxios();
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [categories, setCategories] = useState<Category[]>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState(0);
  const [deleteItems, setDeleteItems] = useState<Category>();
  const [search, setSearch] = useState('');
  const [isOpenCreate, setOpenCreate] = useState<ModalState>({
    isOpen: false,
    type: CreateUpdateCategoryFormInputType.add,
  });
  const [dataSuggestions, setDataSuggestions] = useState<Suggestion[]>([]);

  const { operatorId, siteId } = useParams<{
    operatorId: string;
    siteId: string;
  }>();

  const callAPIGetListCategorySuggestion = async () => {
    try {
      const response = await commonsApi.get('/commons/category-names');
      setDataSuggestions(response.data);
    } catch (error) {
      toast.error(t('messages.M_013'));
    }
  };

  const fetchCategories = async (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    const params = new URLSearchParams([['siteId', siteId]]);
    if (keyword) {
      params.append('keyword', keyword);
    }

    if (nextPage || !keyword) {
      params.append('page', String(nextPage || DEFAULT_PAGE));
      params.append('take', String(PAGE_SIZE));
    }

    const fetchedData = await api.get<FetchCategoryData>('/categories', {
      params,
    });

    if (keyword) {
      setCategories(fetchedData.data.categories);
    } else {
      setCategories(
        forceRefresh
          ? fetchedData.data.categories
          : [...categories, ...fetchedData.data.categories]
      );
    }
    setTotal(fetchedData.data.total);
    setCurrentPage(fetchedData.data.page);
    const { siteName, operatorName } = fetchedData.data;
    onSetBreadcrumb(operatorName, siteName);
    callAPIGetListCategorySuggestion();
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleClickAdd = () => {
    setOpenCreate({
      isOpen: true,
      type: CreateUpdateCategoryFormInputType.add,
    });
  };

  const handleClickEdit = (e: ChangeEvent, item: Category) => {
    setOpenCreate({
      isOpen: true,
      type: CreateUpdateCategoryFormInputType.update,
      itemId: String(item.id),
      dataCategoryEdit: item,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickDelete = (e: ChangeEvent, item: any) => {
    setDeleteItems(item);
  };

  const debounceFn = useCallback(_debounce(fetchCategories, 1000), []);

  const handleChange = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value.trim());
  };

  const handleCategoryDelete = async (id) => {
    api
      .delete('/categories/' + id)
      .then(function () {
        fetchCategories('', 1, true);
        toast.success(t('messages.M_012'));
      })
      .catch(function (e) {
        handleError(e);
      });
    setDeleteItems(undefined);
  };

  const handleSearch = useCallback(() => {
    search && fetchCategories(search);
  }, [search]);

  return (
    <div className={styles.customizeListCategory}>
      <div
        className="count-list"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t('common.page', {
              page: total,
            }) ?? ''
          ),
        }}
      />

      <div className={`search-area ${styles.searchArea}`}>
        <div className="search-input">
          <input
            placeholder={t('common.placeholder.search') || '検索'}
            value={search}
            onChange={handleChange}
          />

          <div className="icon" onClick={handleSearch}>
            <IconSearch />
          </div>
        </div>

        <div className="button" onClick={handleClickAdd}>
          <IconAddCircleOutline />
          <span>{t('common.button.add_category')}</span>
        </div>
      </div>
      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.customizeItemTable}>
          <thead>
            <tr>
              <th className={styles.name}>
                {t('category_management.thead_category')}
              </th>
              <th className={styles.action}>{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {categories.length ? (
              categories.map((item, i) => {
                return (
                  <tr
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `${Path.adminSiteManagement}/${operatorId}/${siteId}/${item.id}${window.location.search}`
                      );
                    }}
                  >
                    <td className={styles.name}>
                      <div className={styles.contentName} title={item.name}>
                        <p>{item.name}</p>
                      </div>
                    </td>
                    <td className={styles.action}>
                      <div className={styles.contentAction}>
                        <div
                          className={`${styles.actionEdit} ${
                            item.other && styles.disableButton
                          }`}
                        >
                          <IconEdit
                            onClick={(e: ChangeEvent) => {
                              e.stopPropagation();
                              !item.other && handleClickEdit(e, item);
                            }}
                          />
                        </div>
                        <div
                          className={`${styles.actionDelete} ${
                            item.other && styles.disableButton
                          }`}
                        >
                          <IconDelete
                            onClick={(e) => {
                              e.stopPropagation();
                              !item.other && handleClickDelete(e, item);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
            {categories && categories.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    categories.length >= PAGE_SIZE &&
                    categories.length < total
                  ) {
                    setCurrentPage(currentPage + 1);
                    fetchCategories(search, currentPage + 1);
                  }
                }}
              />
            )}
          </tbody>
        </table>

        {isOpenCreate.isOpen && (
          <CreateUpdateCategoryModal
            dataSuggestions={dataSuggestions}
            detailCategory={isOpenCreate.dataCategoryEdit}
            onSubmit={() => {
              fetchCategories('', DEFAULT_PAGE, true);
            }}
            onClose={() => {
              setOpenCreate({
                isOpen: false,
                type: CreateUpdateCategoryFormInputType.add,
                itemId: undefined,
              });
            }}
            type={isOpenCreate.type}
            itemId={isOpenCreate.itemId}
          />
        )}

        {deleteItems && (
          <DeleteItemModal
            item={deleteItems}
            onClose={() => setDeleteItems(undefined)}
            onHandleDelete={handleCategoryDelete}
          />
        )}
      </div>
    </div>
  );
};

export default CustomizeListCategory;
