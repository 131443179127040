// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { BottomField } from 'components/molecules/BottomField';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { UnCollectEmissions } from 'components/organisms/UnCollectEmissions';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { EmissionEditModal } from 'components/organisms/EmissionEditModal';
import {
  GetEmissionParams,
  TenantBase,
  UnCollectEmissionObj,
} from 'util/Types';
import { addition } from 'util/Calc';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CustomPagination from 'components/atoms/CustomPagination';
import { getEmissions } from 'apis/emissions/emissionApi';
import { DEFAULT_PAGE } from 'util/ConstantValues';
import { normalizeNumber } from 'util/commons';
import styles from './UnCollects.module.scss';
import { OptionItem } from 'components/molecules/SelectFieldMultipleChoise';
import { getCommonWasteUnits } from 'apis/waste_unit';
import { getCommonTenants } from 'apis/tenants';

const RECORDS_OF_PAGE = 20;

const UnCollects = () => {
  const api = createAxios();
  const [emissions, setEmissions] = useState<UnCollectEmissionObj>({
    page: 1,
    total: 0,
    data: [],
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [itemOptions, setItemOptions] = useState<OptionItem[]>([]);
  const [tenantOptions, setTenantOptions] = useState<OptionItem[]>([]);
  const [filter, setFilter] =
    useState<Omit<GetEmissionParams, 'page' | 'take' | 'keyword'>>();
  const pageCount = Math.ceil(emissions.total / RECORDS_OF_PAGE);
  const { t } = useTranslation();

  const onChange = (ids: string[]) => {
    setCheckedIds(ids);
  };

  const onSubmit = async () => {
    if (checkedIds.length > 0) {
      api
        .post('/collects/request', { emissionIds: checkedIds })
        .then(function () {
          toast.success(t('messages.M_086'));
          callApi({ page: getPageAfterRequest() });
          setCheckedIds([]);
        })
        .catch(function () {
          alert(t('un_collect.submit_collect_request_fail'));
        });
    } else {
      alert(t('un_collect.submit_collect_request_not_passed_validate'));
    }
  };

  const getPageAfterRequest = () => {
    const isLastPage = emissions.page === pageCount;
    const isSubmitAllPageItems = checkedIds.length === emissions.data.length;

    return isLastPage && isSubmitAllPageItems
      ? emissions.page - 1
      : emissions.page;
  };

  const callApi = (params?: {
    page?: number;
    filterValue?: GetEmissionParams;
  }) => {
    const { page, filterValue } = params ?? {};

    if (filterValue) {
      setFilter(filterValue);
    }

    getEmissions({
      params: {
        ...(filterValue ?? filter),
        take: RECORDS_OF_PAGE,
        page: page ?? emissions.page ?? DEFAULT_PAGE,
      },
      successCallback: (res) => {
        setEmissions(res);
      },
    });
  };

  useEffect(() => {
    getCommonWasteUnits({
      successCallback: (data) => {
        setItemOptions(
          data.map((item) => ({
            id: item.name,
            name: item.name,
          }))
        );
      },
    });

    getCommonTenants({
      successCallback: (data) => {
        const tenantNames = data.map((item) => item.name);
        const duplicatedNames = tenantNames.filter(
          (item, index) => tenantNames.indexOf(item) != index
        );

        setTenantOptions(
          data.map((tenant) => ({
            id: tenant.id,
            name: duplicatedNames.includes(tenant.name)
              ? formatTenantName(tenant)
              : tenant.name,
          }))
        );
      },
    });
  }, []);

  const formatTenantName = (tenant: TenantBase) => {
    if (!tenant.section) {
      return tenant.name;
    }

    const floorName = tenant.floor ? ` - ${tenant.floor.name}` : '';

    return `${tenant.name} (${tenant.section.name}${floorName})`;
  };

  useEffect(() => {
    const total = emissions.data
      .filter((emission) => checkedIds.includes(emission.id))
      .reduce(
        (prev, emission) => addition(prev, parseFloat(emission.weight)),
        0
      );
    setTotalWeight(total);
  }, [emissions.data, checkedIds, setTotalWeight]);

  useEffect(() => {
    callApi();
  }, []);

  const handlePageClick = (selectedItem: { selected: number }) => {
    callApi({ page: selectedItem.selected + 1 });
  };

  return (
    <PrimaryTemplate
      h1={t('un_collect.title')}
      className={styles.unCollectPage}
    >
      <div className={styles.unCollectHead}>
        <div
          className={styles.countList}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('common.page', {
                page: emissions.total || 0,
              }) ?? ''
            ),
          }}
        />

        <div className={styles.totalImport}>
          <div className={styles.total}>
            {t('un_collect.total_recovery')}
            <span>&nbsp;{normalizeNumber({ value: totalWeight })}kg</span>
          </div>
        </div>
      </div>

      <UnCollectEmissions
        emissions={emissions.data}
        itemOptions={itemOptions}
        tenantOptions={tenantOptions}
        filter={filter}
        onChange={onChange}
        callApi={callApi}
      />
      <BottomField>
        <div className={styles.bottom}>
          <div className={styles.bottomBtn}>
            <button
              className={styles.btnEditing}
              type="button"
              disabled={emissions.data.length === 0}
              onClick={() => {
                setIsEditModalOpen(true);
              }}
            >
              {t('un_collect.edit_data_collect')}
            </button>

            <button
              className={styles.btnRequest}
              type="button"
              disabled={checkedIds.length === 0}
              onClick={onSubmit}
            >
              {t('un_collect.submit_collect_request')}
            </button>
          </div>

          <CustomPagination
            pageCount={pageCount}
            onPageChange={handlePageClick}
            forcePage={emissions.page - 1}
          />
        </div>
      </BottomField>

      {isEditModalOpen && (
        <EmissionEditModal
          onClose={() => setIsEditModalOpen(false)}
          afterSubmit={() => {
            callApi();
          }}
          emissionData={emissions.data}
        />
      )}
    </PrimaryTemplate>
  );
};

export default UnCollects;
