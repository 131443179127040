// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
import EmissionChildSelect from 'components/molecules/EmissionChildSelect';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/slices/userSlice';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { ItemSize } from 'util/Enums';
import { CategoryItem } from 'util/Types';
import { handleError } from 'util/errorHandler';
import { useQuery } from 'util/queryParams';
import { EmissionInput } from '../../components/organisms/EmissionInput';
import styles from './EmissionChild.module.scss';

import EmissionConfirmLeavePageModal from 'components/molecules/EmissionConfirmLeavePageModal';
import { EmissionSelectCard } from 'components/molecules/EmissionSelectCard';
import EmissionSuccessModal from 'components/molecules/EmissionSuccessModal';
import ErrorModal from 'components/molecules/ErrorModal';
import { LoadingModal } from 'components/molecules/LoadingModal';
import SortEmissionsDragDropSection, {
  EmissionBySizeData,
  emissionBySizeDataFromEmissions,
} from 'components/organisms/SortEmissionsDragDropSection';
import DOMPurify from 'dompurify';
import { FetchTenantsRes } from 'pages/SelectTenant';
import {
  selectLargeWasteUnitByCategoryId,
  selectMediumSmallWasteUnitByCategoryId,
  selectWasteUnitsByCategoryId,
  setCategoryWasteUnit,
} from 'redux/slices/categoryWasteUnitSlice';
import { selectLoading } from 'redux/slices/loadingSlice';
import {
  selectForceReloadCategories,
  selectTenants,
  setTenants,
  subtractForceCategory,
} from 'redux/slices/siteInfoSlice';
import { DbManager } from 'util/DbManager';
import { RoleType } from 'util/Enums';
import { useCheckTenantIdQuery } from 'util/hooks/useCheckTenantIdQuery';
import { cacheImageUrls } from 'util/hooks/useInitOfflineSiteData';

type EmissionCategoryWasteUnitUpdate = {
  id: number;
  size: ItemSize;
  sortIndex: number;
  name: string;
};

const EmissionChild = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const user = useSelector(selectUser);
  const api = createAxios();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const tenantId = query.get('tenantId');
  const forceReloadCategories = useSelector(selectForceReloadCategories);
  const [inputEmission, setInputEmission] = useState<
    CategoryItem | undefined
  >();

  const listItemsEmission = useSelector(selectWasteUnitsByCategoryId(id));
  const mediumSmallItemsGroup = useSelector(
    selectMediumSmallWasteUnitByCategoryId(id)
  );
  const largeItemsGroup = useSelector(selectLargeWasteUnitByCategoryId(id));
  const tenants = useSelector(selectTenants);
  const { offlineInitialized } = useSelector(selectLoading);

  const [
    isShowSortEmissionsDragDropSection,
    setIsShowSortEmissionsDragDropSection,
  ] = useState<boolean>(false);
  const [emissionBySizeData, setEmissionBySizeData] =
    useState<EmissionBySizeData>(emissionBySizeDataFromEmissions([]));
  const [isModifiedEmissionsOrder, setIsModifiedEmissionsOrder] =
    useState<boolean>(false);
  const [isShowConfirmLeavePageModal, setIsShowConfirmLeavePageModal] =
    useState<boolean>(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [isShowSubstituteRegistModal, setIsShowSubstituteRegistModal] =
    useState<boolean>(false);

  useCheckTenantIdQuery();

  const callAPIListItems = async (id: string, forceRefresh?: boolean) => {
    if (!offlineInitialized) {
      return;
    }

    if (!forceRefresh || !navigator.onLine) {
      const cachedSites = await DbManager.sites.toArray();

      if (cachedSites.length) {
        const cachedCategory = await DbManager.categories
          .where('id')
          .equals(Number(id))
          .first();

        dispatch(
          setCategoryWasteUnit({
            categoryId: id,
            wasteUnit:
              cachedCategory?.wasteUnitsOneCategory?.map((item) => ({
                ...item,
                categoryId: id,
              })) ?? [],
          })
        );

        return;
      }
    }

    api
      .get(`/emissions/categories/${id}/waste-units`)
      .then(async (response) => {
        dispatch(
          setCategoryWasteUnit({
            categoryId: id,
            wasteUnit: response.data?.map((e: any) => ({
              ...e,
              categoryId: id,
            })),
          })
        );

        const imgUrls: string[] =
          response.data?.reduce((rs: string[], item: any) => {
            if (item?.imageUrl) {
              rs.push(item.imageUrl);
            }

            return rs;
          }, []) ?? [];

        await cacheImageUrls(imgUrls);
        await DbManager.categories.where('id').equals(Number(id)).modify({
          wasteUnitsOneCategory: response.data,
        });
      })
      .catch(() => {
        toast.error(t('messages.M_013'));
      });
  };

  const tenantName = tenants.find((tenant) => tenant.id === Number(tenantId));

  const callApiTenant = async (forceRefresh?: boolean) => {
    if (!offlineInitialized) {
      return;
    }

    if (!forceRefresh || !navigator.onLine) {
      const cachedSites = await DbManager.sites.toArray();

      if (cachedSites.length) {
        const cachedTenants = await DbManager.tenants.orderBy('name').toArray();
        dispatch(setTenants(cachedTenants));

        return;
      }
    }

    const { data } = await api.get<FetchTenantsRes>(
      `/tenants?siteId=${user.siteId}`
    );

    dispatch(setTenants(data.tenants));
    await DbManager.tenants.clear();
    await DbManager.tenants.bulkAdd(data.tenants);
  };

  const emissionCategoryWasteUnitUpdateManyApi = async (
    id: string,
    emissions: EmissionCategoryWasteUnitUpdate[],
    successCallback: () => void
  ) => {
    try {
      await api.patch(`/emissions/categories/${id}/waste-units`, {
        wasteUnits: emissions,
      });
      successCallback();
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    if (listItemsEmission.length === 0 && offlineInitialized) {
      callAPIListItems(id);
    }
  }, [id, offlineInitialized]);

  const handleChangeEmissionsOrder = (isModified: boolean) => {
    setIsModifiedEmissionsOrder(isModified);
  };

  const handleSubmitEmissionsOrder = (
    emissionBySizeData: EmissionBySizeData
  ) => {
    let newListItemsEmission: CategoryItem[] = [];
    let emissionCategoryWasteUnits: EmissionCategoryWasteUnitUpdate[] = [];
    const sortIndexBySize = {
      [ItemSize.LARGE]: 0,
      [ItemSize.MEDIUM]: 0,
      [ItemSize.SMALL]: 0,
    };

    [ItemSize.LARGE, ItemSize.MEDIUM, ItemSize.SMALL].forEach(
      (itemSize: ItemSize) => {
        newListItemsEmission = newListItemsEmission.concat(
          emissionBySizeData[itemSize].map((emission) => ({
            ...emission,
            size: itemSize,
          }))
        );
        emissionCategoryWasteUnits = emissionCategoryWasteUnits.concat(
          emissionBySizeData[itemSize].map((emission) => ({
            id: +emission.id,
            name: emission.name,
            size: itemSize,
            sortIndex: ++sortIndexBySize[itemSize],
          }))
        );
      }
    );

    emissionCategoryWasteUnitUpdateManyApi(
      id,
      emissionCategoryWasteUnits,
      () => {
        dispatch(
          setCategoryWasteUnit({
            categoryId: id,
            wasteUnit: newListItemsEmission.map((e) => ({
              ...e,
              categoryId: id,
            })),
          })
        );
        setIsModifiedEmissionsOrder(false);
        setIsShowSuccessModal(true);
      }
    );
  };

  const handleCloseConfirmLeavePageModal = () => {
    setIsShowConfirmLeavePageModal(false);
  };

  const handleSubmitConfirmLeavePageModal = () => {
    setIsShowConfirmLeavePageModal(false);
    setIsShowSortEmissionsDragDropSection(false);
    setIsModifiedEmissionsOrder(false);
  };

  const handleSubmitSuccessModal = () => {
    callAPIListItems(id, true);
    setIsShowSuccessModal(false);
    setIsShowSortEmissionsDragDropSection(false);
  };

  const prevFunction = () => {
    if (inputEmission) {
      setInputEmission(undefined);
    } else if (isShowSortEmissionsDragDropSection && isModifiedEmissionsOrder) {
      setIsShowConfirmLeavePageModal(true);
    } else if (
      isShowSortEmissionsDragDropSection &&
      !isModifiedEmissionsOrder
    ) {
      setIsShowSortEmissionsDragDropSection(false);
    } else {
      push(`${Path.emissionNew}${window.location.search}`);
    }
  };

  useEffect(() => {
    const onBeforeUnload = (event) => {
      if (isModifiedEmissionsOrder) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isModifiedEmissionsOrder]);

  useEffect(() => {
    if (forceReloadCategories?.includes(Number(id))) {
      callAPIListItems(id, true).then(() => {
        dispatch(subtractForceCategory(Number(id)));
      });
    }
  }, [tenantId, forceReloadCategories]);

  useEffect(() => {
    if (tenants.length === 0 && offlineInitialized) {
      callApiTenant();
    }
  }, [offlineInitialized]);

  if (!offlineInitialized) {
    return (
      <LoadingModal
        zIndex={100000}
        hasCash={true}
        isOpen={!offlineInitialized}
      />
    );
  }

  if (isShowSubstituteRegistModal) {
    return (
      <PrimaryTemplate hasLogo prevFunction={prevFunction}>
        <ErrorModal
          content={t('emission_new.substitute_regist_warning') || ''}
          onClose={() => {
            setIsShowSubstituteRegistModal(false);
          }}
        />
      </PrimaryTemplate>
    );
  }

  if (inputEmission) {
    return (
      <PrimaryTemplate hasLogo prevFunction={prevFunction}>
        <EmissionInput
          emissionType={inputEmission}
          afterSubmit={() => {
            setInputEmission(undefined);
          }}
        />
      </PrimaryTemplate>
    );
  }

  const renderEmissionCard = (item: CategoryItem) => {
    let onSelected = setInputEmission;
    if (item.substituteRegistRelations?.length) {
      onSelected = () => {
        setIsShowSubstituteRegistModal(true);
      };
    }

    return !item.imageUrl ? (
      <EmissionSelectCard key={item.id} item={item} onSelected={onSelected} />
    ) : (
      <EmissionChildSelect key={item.id} item={item} onClick={onSelected} />
    );
  };

  return (
    <PrimaryTemplate hasLogo prevFunction={prevFunction}>
      <div className={styles.emissionChild}>
        <div className={styles.tenantNameMobile}>{tenantName?.name || ''}</div>
        {isShowSortEmissionsDragDropSection ? (
          <div className={styles.emissionChildSortEmissionsDragDropSection}>
            <SortEmissionsDragDropSection
              emissionBySizeData={emissionBySizeData}
              onChange={handleChangeEmissionsOrder}
              onSubmit={handleSubmitEmissionsOrder}
            />

            {isShowConfirmLeavePageModal && (
              <EmissionConfirmLeavePageModal
                onClose={handleCloseConfirmLeavePageModal}
                onSubmit={handleSubmitConfirmLeavePageModal}
              />
            )}

            {isShowSuccessModal && (
              <EmissionSuccessModal onSubmit={handleSubmitSuccessModal} />
            )}
          </div>
        ) : (
          <>
            <div
              className={
                user.role === RoleType.UserNormal
                  ? styles.emissionChildHeaderNormal
                  : styles.emissionChildHeader
              }
            >
              <div
                className="count-list"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    t('common.page', {
                      page: listItemsEmission.length || 0,
                    }) ?? ''
                  ),
                }}
              />

              <div
                className={
                  user.role === RoleType.UserNormal
                    ? styles.emissionChildHeaderNormalTenantName
                    : styles.emissionChildHeaderTenantName
                }
              >
                {tenantName?.name || ''}
              </div>

              {user.role === RoleType.AdminSite && (
                <div className={styles.emissionChildHeaderSortButton}>
                  <button
                    onClick={() => {
                      setEmissionBySizeData(
                        emissionBySizeDataFromEmissions(listItemsEmission)
                      );
                      setIsShowSortEmissionsDragDropSection(true);
                    }}
                  >
                    {t('common.button.sort')}
                  </button>
                  <button
                    onClick={() => {
                      callApiTenant(true);
                      callAPIListItems(id, true);
                    }}
                  >
                    {t('common.button.reload')}
                  </button>
                </div>
              )}
            </div>

            <div className={styles.gridArea}>
              {largeItemsGroup.map(renderEmissionCard)}

              {mediumSmallItemsGroup.map(
                (group: CategoryItem[], index: number) => {
                  return (
                    <div className={styles.mediumItemsGroup} key={index}>
                      {group.map(renderEmissionCard)}
                    </div>
                  );
                }
              )}
            </div>
          </>
        )}
      </div>
    </PrimaryTemplate>
  );
};

export default EmissionChild;
