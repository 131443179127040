// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Bar } from 'react-chartjs-2';
import styles from './WasteChart.module.scss';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import DashboardDownloadModal from '../DashboardDownloadModal';
import React, { useMemo } from 'react';
import { createAxios } from 'ts/createAxios';
import { FilterObject } from 'pages/Dashboard';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { DATE_FORMAT } from 'util/ConstantValues';
import { RoleType } from 'util/Enums';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getFormatDate } from 'ts/formatDate';
import duration from 'dayjs/plugin/duration';
import { API_ENDPOINTS } from 'util/endPoints';
import { normalizeNumber } from 'util/commons';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';
import LoadingCoating from 'components/atoms/LoadingCoating';

dayjs.extend(duration);
interface WasteWeight {
  weight: string;
  date: string;
  day: number;
  month: number;
  year: number;
}
interface DownloadWasteCSVResponse {
  wastes: WasteWeight[];
  dateRange: string;
  wasteUnits: string[];
  industries: string[];
  tenants: string[];
  sites: string[];
  isYearData: boolean;
}

enum ActiveType {
  DAY = 'date',
  MONTH = 'month',
  YEAR = 'year',
}
interface Props {
  filterObject: FilterObject;
  isAdminOperatorSiteView?: boolean;
}
const WasteChart: React.FC<Props> = ({
  filterObject,
  isAdminOperatorSiteView,
}) => {
  const user = useSelector(selectUser);
  const [active, setActive] = React.useState(ActiveType.MONTH);
  const [isOpenDownload, setOpenDownload] = React.useState(false);
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [wasteWeights, setWasteWeights] = React.useState<WasteWeight[]>([]);
  const { t } = useTranslation();
  const [downloadData, setDownloadData] =
    React.useState<DownloadWasteCSVResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = React.useRef<any>();

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const brandItems = filterObject?.brandItems?.filter(
    (item) => item.id != null
  );

  const commonParams = (filter: FilterObject, active: ActiveType) => {
    return {
      typeDate: active,
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants
          ?.filter((item) => item.id != null)
          ?.map((item) => (item.id.toString() === '' ? null : item.id)) || [],
      tenantNames:
        filter?.tenants
          ?.filter((item) => item.id != null)
          ?.map((item) => item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  };

  const { triggerFetch: fetchDashboardWasteWeight, loading: dataFetching } =
    useCallbackLoading({
      callback: async (filter: FilterObject, active: ActiveType) => {
        const toDate = dayjs(filter.dates.to);
        const range = toDate.diff(filter.dates.from, 'milliseconds');
        const diff = dayjs.duration(range);

        return commonsApi
          .post<WasteWeight[]>(
            API_ENDPOINTS.DASHBOARD_WASTE_WEIGHT,
            commonParams(
              filter,
              active === ActiveType.DAY && diff.asYears() > 1
                ? ActiveType.MONTH
                : active
            )
          )
          .then((res) => {
            setWasteWeights(res.data);
          });
      },
    });

  React.useEffect(() => {
    fetchDashboardWasteWeight(filterObject, active);
  }, [filterObject, active]);

  const labels = wasteWeights.map((i) => {
    if (active === ActiveType.YEAR) {
      return String(i.year);
    }
    if (active === ActiveType.MONTH) {
      return `${i.year}/${i.month}`;
    }
    return String(`${i.month}/${i.day}`);
  });

  const { triggerFetch: handleDownloadCSV, loading: csvDownloading } =
    useCallbackLoading({
      callback: (filter: FilterObject, active: ActiveType) =>
        commonsApi
          .post<DownloadWasteCSVResponse>(
            API_ENDPOINTS.DASHBOARD_WASTE_WEIGHT_EXPORT,
            commonParams(filter, active)
          )
          .then((res) => {
            setDownloadData(res.data);
            setTimeout(() => {
              downloadCSVRef.current.link.click();
            }, 500);
          }),
    });

  const isDisableFilterByYear = React.useMemo(() => {
    const date1 = dayjs(filterObject.dates.to);
    const range = date1.diff(filterObject.dates.from, 'milliseconds');
    const diff = dayjs.duration(range);
    return diff.asYears() <= 1;
  }, [filterObject]);

  const isDisableFilterByDay = React.useMemo(() => {
    const date1 = dayjs(filterObject.dates.to);
    const range = date1.diff(filterObject.dates.from, 'milliseconds');
    const diff = dayjs.duration(range);
    return diff.asYears() > 1;
  }, [filterObject]);

  React.useEffect(() => {
    if (
      (active === ActiveType.DAY && isDisableFilterByDay) ||
      (active === ActiveType.YEAR && isDisableFilterByYear)
    ) {
      setActive(ActiveType.MONTH);
    }
  }, [active, isDisableFilterByDay]);

  const title = t('dashboard.trend_amount_of_waste') || '廃棄量の推移';
  const data = {
    labels,
    datasets: [
      {
        maxBarThickness: 100,
        label: title,
        data: wasteWeights.map((i) => {
          return i.weight;
        }),
        backgroundColor: '#86EA5C',
        borderRadius: 4,
        width: 20,
        datalabels: {
          display: false,
        },
      },
    ],
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartRef = React.useRef<any>(null);
  const options = (isDownload?: boolean) => {
    return {
      indexAxis: 'x',
      plugins: {
        title: {
          display: true,
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (Item) => {
              return (
                Item.label + ': ' + normalizeNumber({ value: Item.raw }) + ' kg'
              );
            },
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            color: isDownload ? 'black' : '#CFCFCF',
          },
        },
        y: {
          stacked: true,
          grid: {
            color: '#3C3C3C',
          },
          max: wasteWeights?.every((item) => !item.weight) ? 10 : undefined,
          ticks: {
            color: isDownload ? 'black' : '#CFCFCF',
            stepSize: wasteWeights?.every((item) => !item.weight)
              ? 1
              : undefined,
          },
          title: {
            text: t('dashboard.trend_total_weight'),
            display: true,
            color: isDownload ? 'black' : '#CFCFCF',
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  };

  const plugins = (isDownload?: boolean) => {
    return [
      {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = isDownload ? 'white' : '#2C2C2C';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
  };

  return (
    <div className={styles.wasteChart}>
      <LoadingCoating
        show={dataFetching || csvDownloading}
        styles={{ borderRadius: 12 }}
      />

      <div className={styles.heading}>
        <p>{title}</p>
        <div className={styles.actions}>
          <div className={styles.filter}>
            <button
              disabled={isDisableFilterByYear}
              className={`${styles.filterButton} ${
                active === ActiveType.YEAR ? styles.filterButtonSelected : ''
              }`}
              onClick={() => {
                setActive(ActiveType.YEAR);
              }}
            >
              {t('dashboard.year')}
            </button>
            <button
              className={`${styles.filterButton} ${
                active === ActiveType.MONTH ? styles.filterButtonSelected : ''
              }`}
              onClick={() => {
                setActive(ActiveType.MONTH);
              }}
            >
              {t('dashboard.month')}
            </button>
            <button
              disabled={isDisableFilterByDay}
              className={`${styles.filterButton} ${
                active === ActiveType.DAY ? styles.filterButtonSelected : ''
              }`}
              onClick={() => {
                setActive(ActiveType.DAY);
              }}
            >
              {t('dashboard.day')}
            </button>
          </div>
          <div
            className={styles.buttonDownload}
            onClick={() => {
              setOpenDownload(true);
            }}
          >
            <IconDownload />
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <Bar options={options()} data={data} plugins={plugins()} />
        <div className={styles.chartHidden}>
          <Bar
            ref={chartRef}
            options={options(true)}
            data={data}
            plugins={plugins(true)}
          />
        </div>
        {isOpenDownload && (
          <DashboardDownloadModal
            onClose={() => {
              setOpenDownload(false);
            }}
            handleDownload={(data) => {
              if (data.isImage && chartRef.current) {
                const link = document.createElement('a');
                link.download = `${title} ${dayjs().format(
                  DATE_FORMAT.slaYMDHm
                )}.png`;
                link.href = chartRef.current.toBase64Image('image/png', 1);
                link.click();
              }
              if (data.isCsv) {
                handleDownloadCSV(filterObject, active);
              }
              setOpenDownload(false);
            }}
          />
        )}
      </div>
      <CSVLink
        className="hidden"
        filename={
          t('dashboard.trend_of_waste.file_csv_name', {
            date_time: dayjs().format(DATE_FORMAT.slaYMDHm),
          }) + '.csv'
        }
        data={[
          //header
          [
            active === ActiveType.DAY
              ? t('dashboard.trend_of_waste.date')
              : active === ActiveType.MONTH
              ? t('dashboard.trend_of_waste.month')
              : t('dashboard.trend_of_waste.year'),
            t('dashboard.trend_of_waste.waste_volume') + '(kg)',
          ],
          downloadData?.dateRange
            ? [
                t('dashboard.recycling_rate_csv.date_range'),
                downloadData?.dateRange || '',
              ]
            : [],
          downloadData?.sites && adminOperatorView
            ? [
                t('dashboard.recycling_rate_csv.site_name'),
                downloadData?.sites?.join(', ') || '',
              ]
            : [],
          downloadData?.industries && adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.business_type'),
                downloadData?.industries?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.tenant_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && user.role === RoleType.BrandOwner
            ? [
                t('dashboard.recycling_rate_csv.store_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.wasteUnits
            ? [
                t('dashboard.recycling_rate_csv.item'),
                downloadData?.wasteUnits?.join(', ') || '',
              ]
            : [],
          ...(downloadData?.wastes
            .sort((a, b) => {
              return dayjs(b.date).valueOf() - dayjs(a.date).valueOf();
            })
            .map((item) => {
              return [
                active === ActiveType.DAY
                  ? getFormatDate(item.date, DATE_FORMAT.full_date)
                  : active === ActiveType.MONTH
                  ? `${item.year}/${item.month}`
                  : `${item.year}`,
                normalizeNumber({ value: item.weight, toFixed: false }),
              ];
            }) || []),
        ].filter((data) => data.length)}
        ref={downloadCSVRef}
      >
        {t('download.volume_reduction_registration')}
      </CSVLink>
    </div>
  );
};
export default WasteChart;
