// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import DOMPurify from 'dompurify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConfirmLogoutModal.module.scss';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmLogoutModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.confirmLogoutModal}>
      <Modal>
        <div className={styles.confirmLogoutModalContent}>
          <div
            className={styles.confirmLogoutModalContentIconClose}
            onClick={onClose}
          >
            <IconClose width={16} height={16} />
          </div>

          <h3 className={styles.confirmLogoutModalContentTitle}>
            {t('modal_confirm_logout.heading')}
          </h3>

          <p
            className={styles.confirmLogoutModalContentDescription}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                t('modal_confirm_logout.content_confirm') ?? ''
              ),
            }}
          />
        </div>

        <div className={styles.confirmLogoutModalFooter}>
          <button
            type="submit"
            className={styles.confirmLogoutModalFooterSubmitButton}
            onClick={onSubmit}
          >
            {t('modal_confirm_logout.button')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmLogoutModal;
