// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ModalType, PrintLabel } from 'util/Enums';
import { CheckBoxCardV2 } from '../CheckBoxCardV2';
import { Label } from 'components/atoms/Label';
import { SelectField } from '../SelectField';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import dayjs from 'dayjs';
import CustomRadioGroup from '../CustomRadioGroup';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import {
  dateOptions,
  originalInvoieOptions,
  EmissionSiteItem,
  WorkingDays,
  tenantAuthenOptions,
  printLabelOptions,
} from 'util/siteManagementTypes';
import {
  isValidTime,
  validateEmailAddressNotRequired,
  validateNumberField,
  validatePhoneNumber,
  validateSelectField,
  validateSpecialCharactersWithJapanese,
  validateStringField,
  validateLimitNumber,
  validateEmailAddress,
} from 'util/validator';
import { REGEX_OBJECT } from 'util/ConstantValues';
import TimeInputField from '../TimeInputField';

interface Props {
  initForm: EmissionSiteItem;
  type: ModalType;
  onSubmit: (createUpdateSiteDateForm: EmissionSiteItem) => void;
  onPrevStep: (createUpdateSiteDateForm: EmissionSiteItem) => void;
}

const baseEmailItem = {
  value: '',
  id: dayjs().valueOf(),
};
const CreateUpdateSiteDateForm: React.FC<Props> = ({
  initForm,
  type,
  onSubmit,
  onPrevStep,
}) => {
  const { t } = useTranslation();
  const [initFormInput, setInitFormInput] = useState<
    EmissionSiteItem | { [key: string]: string }
  >(initForm);

  useEffect(() => {
    const newInputForm = {
      ...initForm,
      ...(!initForm.emails?.length ? { emails: [baseEmailItem] } : {}),
      ...(!initForm.workingDay?.length ? { workingDay: [] } : {}),
    };
    if (initForm?.emails?.length > 0 && type === ModalType.update) {
      initForm.emails.forEach((email) => {
        newInputForm[`email_id_${email.id}`] = email.value;
      });
    }
    setInitFormInput(newInputForm);
  }, [initForm]);
  return (
    <div className={styles.createBody}>
      {initFormInput && (
        <Form<EmissionSiteItem>
          onSubmit={(values: EmissionSiteItem) => onSubmit(values)}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={initFormInput || {}}
          validate={(values) => {
            return {
              staff: validateSpecialCharactersWithJapanese(
                t('site_management.modal.field_staff'),
                100,
                values.staff
              ),
              staffTel: validatePhoneNumber(
                t('site_management.modal.field_staff_tel'),
                15,
                values.staffTel
              ),
              billingAddress: validateStringField(
                t('site_management.modal.field_billing_address'),
                200,
                values.billingAddress
              ),
              systemUsageFee: validateNumberField(
                t('site_management.modal.field_system_usage_fee'),
                100,
                values.systemUsageFee
              ),
              paymentDate: validateSelectField(
                t('site_management.modal.field_payment_date'),
                values.paymentDate
              ),
              invoiceDate: validateSelectField(
                t('site_management.modal.field_invoice_date'),
                values.invoiceDate
              ),
              dueDate: validateSelectField(
                t('site_management.modal.field_end_date'),
                values.dueDate
              ),
              dataSyncTime: validateLimitNumber(
                Number(values.dataSyncTime),
                120
              ),
            };
          }}
        >
          {(props) => {
            const handleChangeWorkingDay = (e) => {
              const id = e.target.id;
              const workingDays = props.values.workingDay;
              props.form.mutators.setValue(
                'workingDay',
                workingDays.includes(+id)
                  ? workingDays.filter((day) => day !== +id)
                  : workingDays.concat(+id)
              );
            };
            const handleAddEmail = () => {
              props.form.mutators.setValue(
                'emails',
                props.values.emails.concat({
                  value: '',
                  id: dayjs().valueOf(),
                })
              );
            };
            const handleChangeEmail = (value: string, id: number) => {
              props.form.mutators.setValue(
                'emails',
                props.values.emails.map((email) =>
                  email.id === id ? { ...email, value } : email
                )
              );
            };

            const handleDeleteEmail = (id: number) => {
              props.form.mutators.setValue(
                'emails',
                props.values.emails.filter((email) => email.id !== id)
              );
            };
            const handleChangeOriginalInvoice = (value: string | number) => {
              props.form.mutators.setValue(
                'originalInvoice',
                value === 'true' ? true : false
              );
            };
            const handleChangeTenantAuthen = (value: string | number) => {
              props.form.mutators.setValue('tenantAuthen', value);
            };
            return (
              <form noValidate className={styles.formInput}>
                <div className={styles.inputFrame}>
                  <Label
                    className={styles.workingDayFrameHeading}
                    text={
                      t('site_management.modal.field_working_day') || '営業日'
                    }
                    tag={'div'}
                  />
                  <div className={styles.workingDayFrame}>
                    {WorkingDays.map((day) => {
                      return (
                        <div
                          className={styles.workingDayFrameItem}
                          key={day.value}
                        >
                          <CheckBoxCardV2
                            id={`${day.value}`}
                            onChange={handleChangeWorkingDay}
                            checked={props.values.workingDay?.includes(
                              day.value
                            )}
                          >
                            <span className={styles.workingDayFrameItemLabel}>
                              {day.label}
                            </span>
                          </CheckBoxCardV2>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.timeFrame}>
                    <TimeInputField
                      label={
                        t('site_management.modal.field_start_time1') ||
                        '開店時間1'
                      }
                      name="startTime"
                      value={props.values.startTime}
                      onChangeTime={(time) => {
                        props.form.mutators.setValue('startTime', time);
                      }}
                      validate={(value) => {
                        return isValidTime(
                          t('site_management.modal.field_start_time1') ||
                            '開店時間1',
                          value
                        );
                      }}
                    />
                    <TimeInputField
                      label={
                        t('site_management.modal.field_end_time1') ||
                        '閉店時間1'
                      }
                      name="endTime"
                      value={props.values.endTime}
                      onChangeTime={(time) => {
                        props.form.mutators.setValue('endTime', time);
                      }}
                      validate={(value) => {
                        return isValidTime(
                          t('site_management.modal.field_end_time1') ||
                            '閉店時間1',
                          value
                        );
                      }}
                    />
                    <TimeInputField
                      label={
                        t('site_management.modal.field_start_time2') ||
                        '開店時間2'
                      }
                      name="startTime2"
                      value={props.values.startTime2}
                      onChangeTime={(time) => {
                        props.form.mutators.setValue('startTime2', time);
                      }}
                      validate={(value) => {
                        return isValidTime(
                          t('site_management.modal.field_start_time2') ||
                            '開店時間2',
                          value
                        );
                      }}
                    />
                    <TimeInputField
                      label={
                        t('site_management.modal.field_end_time2') ||
                        '閉店時間2'
                      }
                      name="endTime2"
                      value={props.values.endTime2}
                      onChangeTime={(time) => {
                        props.form.mutators.setValue('endTime2', time);
                      }}
                      validate={(value) => {
                        return isValidTime(
                          t('site_management.modal.field_end_time2') ||
                            '閉店時間2',
                          value
                        );
                      }}
                    />
                    <InputDataListField
                      label={t('site_management.modal.field_staff')}
                      placeholder={t('site_management.modal.field_staff')}
                      maxlength={100}
                      name="staff"
                      required
                    />
                    <InputDataListField
                      label={t('site_management.modal.field_staff_tel')}
                      placeholder={t('site_management.modal.field_staff_tel')}
                      maxlength={15}
                      name="staffTel"
                      required
                      validRegex={REGEX_OBJECT.number}
                    />
                    <InputDataListField
                      label={t('site_management.modal.field_staff_email')}
                      placeholder={t('site_management.modal.field_staff_email')}
                      name="staffEmail"
                      required
                      validate={validateEmailAddress}
                    />
                  </div>
                  <Label
                    className={styles.paymentHeading}
                    text={
                      t('site_management.modal.payment_heading') || '請求方法'
                    }
                    tag={'div'}
                  />
                  <Label
                    className={styles.emailHeading}
                    text={
                      t('site_management.modal.field_email') ||
                      '請求書送付先メールアドレス'
                    }
                    tag={'div'}
                  />
                  <div>
                    {props.values.emails?.map((email, index) => {
                      return (
                        <div key={email.id} className={`${styles.emailFrame}`}>
                          <div className={styles.inputWrap}>
                            <InputDataListField
                              placeholder={t(
                                'site_management.modal.field_email'
                              )}
                              name={`email_id_${email.id.toString()}`}
                              validate={validateEmailAddressNotRequired}
                              handleChange={(value) =>
                                handleChangeEmail(value, email.id)
                              }
                              subFix={
                                props.values.emails.length > 1 ? (
                                  <IconDelete
                                    onClick={() => handleDeleteEmail(email.id)}
                                  />
                                ) : undefined
                              }
                            />
                          </div>
                          <div>
                            {index === 0 && (
                              <div
                                className={`${styles.buttonAdd} ${
                                  props.values.emails.length >= 3
                                    ? styles.buttonAddDisable
                                    : ''
                                }`}
                                onClick={() =>
                                  props.values.emails.length < 3 &&
                                  handleAddEmail()
                                }
                              >
                                <IconAddCircleOutline />
                                <span>
                                  {t('site_management.modal.button_add_email')}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <InputDataListField
                    label={t('site_management.modal.field_billing_address')}
                    placeholder={t(
                      'site_management.modal.field_billing_address'
                    )}
                    maxlength={200}
                    name="billingAddress"
                    required
                  />
                  <div className={styles.selectFrame}>
                    <SelectField
                      label={t('site_management.modal.field_end_date')}
                      name="dueDate"
                      placeholder={t('site_management.modal.field_end_date')}
                      required
                      options={dateOptions}
                    />
                    <SelectField
                      label={t('site_management.modal.field_invoice_date')}
                      name="invoiceDate"
                      placeholder={t(
                        'site_management.modal.field_invoice_date'
                      )}
                      required
                      options={dateOptions}
                    />
                    <SelectField
                      label={t('site_management.modal.field_payment_date')}
                      name="paymentDate"
                      placeholder={t(
                        'site_management.modal.field_payment_date'
                      )}
                      required
                      options={dateOptions}
                    />
                    <InputDataListField
                      label={t('site_management.modal.field_system_usage_fee')}
                      placeholder={t(
                        'site_management.modal.field_system_usage_fee'
                      )}
                      name="systemUsageFee"
                      required
                      validRegex={REGEX_OBJECT.number}
                      maxlength={100}
                      subFix={
                        <span className={styles.systemUsageFeeUnit}>円</span>
                      }
                    />
                    <InputDataListField
                      label={t('site_management.modal.field_data_sync_time')}
                      placeholder={t(
                        'site_management.modal.field_data_sync_time'
                      )}
                      name="dataSyncTime"
                      validRegex={REGEX_OBJECT.number}
                      maxlength={3}
                      subFix={
                        <span className={styles.systemUsageFeeUnit}>分</span>
                      }
                      handleChange={(value) => {
                        props.form.mutators.setValue('dataSyncTime', value);
                      }}
                    />
                  </div>
                  <div>
                    <Label
                      text="ラベル種類"
                      required
                      description={
                        props.values.printLabel === PrintLabel.QRCode ? (
                          <>
                            QRコードあると選択したら、品目代理登録がOFFになります。
                            <br />
                            QRコードなしに切り替えるまで変更できません。
                          </>
                        ) : (
                          ''
                        )
                      }
                    />
                    <CustomRadioGroup
                      name="printLabel"
                      defaultChecked={
                        props.values.printLabel ?? PrintLabel.NoneQRCode
                      }
                      options={printLabelOptions}
                      onchange={(value) =>
                        props.form.mutators.setValue('printLabel', value)
                      }
                    />
                  </div>
                  <div className={styles.selectFrame}>
                    <div>
                      <Label
                        className={styles.workingDayFrameHeading}
                        text={
                          t('site_management.modal.field_original_invoice') ||
                          '請求書原本郵送の有無'
                        }
                        required
                        tag={'div'}
                      />
                      <CustomRadioGroup
                        name="originalInvoice"
                        defaultChecked={
                          props.values.originalInvoice ? 'true' : 'false'
                        }
                        options={originalInvoieOptions}
                        onchange={handleChangeOriginalInvoice}
                      />
                    </div>
                    <div>
                      <Label
                        className={styles.workingDayFrameHeading}
                        text={'テナント認証'}
                        tag={'div'}
                      />
                      <CustomRadioGroup
                        name="tenantAuthen"
                        defaultChecked={props.values.tenantAuthen}
                        options={tenantAuthenOptions}
                        onchange={handleChangeTenantAuthen}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.submit}>
                  <button
                    className={`${styles.prevButton}`}
                    onClick={() => onPrevStep(props.values)}
                    type="button"
                  >
                    {t('site_management.modal.button_prev')}
                  </button>
                  <button
                    className={`${styles.submitButton} ${
                      props.invalid ? styles.disableButton : ''
                    }`}
                    disabled={props.invalid}
                    type="button"
                    onClick={props.handleSubmit}
                  >
                    {type === ModalType.add
                      ? t('site_management.modal_create_site.button_create')
                      : t('site_management.modal_update_site.button_update')}
                  </button>
                </div>

                {type === ModalType.add ? (
                  <div className={styles.submitOptionFrame}>
                    <div
                      className={styles.submitOptionFrameItem}
                      key="create-default-waste-units"
                    >
                      <CheckBoxCardV2
                        id={'create-default-waste-units'}
                        checked={!!props.values.createDefaultWasteUnit}
                        onChange={() => {
                          props.form.mutators.setValue(
                            'createDefaultWasteUnit',
                            !props.values.createDefaultWasteUnit
                          );
                        }}
                      >
                        <span className={styles.submitOptionFrameItemLable}>
                          拠点作成時にデフォルト品目を追加する
                        </span>
                      </CheckBoxCardV2>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </form>
            );
          }}
        </Form>
      )}
    </div>
  );
};

export default CreateUpdateSiteDateForm;
