// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import { IconFixedItem } from 'components/atoms/icons/IconFixedItem';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import DOMPurify from 'dompurify';
import { CategoryItem, ItemType } from 'util/Types';
import styles from './ConfirmModal.module.scss';
import { mergeClasses } from 'util/commons';

export type ConfirmModalProps = {
  id?: string;
  title: string;
  isOpen?: boolean;
  emissionType?: CategoryItem;
  confirmContents?: {
    label: string;
    contents: string;
  }[];
  alert?: string;
  closeEditModal: () => void;
  onClick: () => void;
  submitLabel?: string;
  submitAndFinishLabel?: string;
  onClickSubmitAndFinish?: () => void;
  fullBottomWidth?: boolean;
};

export const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    title,
    submitLabel,
    isOpen,
    emissionType,
    confirmContents,
    alert,
    closeEditModal,
    onClick,
    submitAndFinishLabel,
    onClickSubmitAndFinish,
    fullBottomWidth,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <section className={styles.confirmModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <div className={styles.title}>{title}</div>
            <div className={styles.closeIcon} onClick={closeEditModal}>
              <IconClose />
            </div>
          </div>

          <div className={styles.body}>
            {alert && (
              <div
                className={`${styles.alertInfo} ${styles.errors}`}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(alert) }}
              ></div>
            )}

            {emissionType && (
              <div className={styles.emissionBox}>
                {emissionType?.imageUrl && (
                  <img
                    className={styles.emissionImage}
                    src={`${process.env.REACT_APP_CLOUDFRONT}${emissionType.imageUrl}`}
                  />
                )}

                {emissionType?.imageUrl &&
                  emissionType?.type === ItemType.FIX && (
                    <div className={`${styles.type} ${styles.typeFixed}`}>
                      <div className={`${styles.emissionType}`}>
                        <IconFixedItem />
                      </div>
                    </div>
                  )}
              </div>
            )}

            <div className={styles.confirmInfo}>
              {confirmContents?.map((item, i) => {
                return (
                  item.label &&
                  item.contents && (
                    <div className={styles.confirmInfoRow} key={i}>
                      <div className={styles.confirmInfoLabel}>
                        {item.label}
                      </div>
                      <div className={styles.confirmInfoContents}>
                        {item.contents}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
            <div
              className={mergeClasses('modal-bottom-field', {
                [styles.fullWidth]: fullBottomWidth,
              })}
            >
              {submitAndFinishLabel && (
                <PrimaryBtnField
                  className={styles.finishButton}
                  text={submitAndFinishLabel}
                  onClick={onClickSubmitAndFinish}
                  type="button"
                />
              )}

              {submitLabel && (
                <PrimaryBtnField
                  className={styles.submitButton}
                  text={submitLabel}
                  onClick={onClick}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};
