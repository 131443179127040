// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Bar } from 'react-chartjs-2';
import styles from './ChartWasteAmountArea.module.scss';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { useEffect, useMemo, useRef, useState } from 'react';
import DashboardDownloadModal from '../DashboardDownloadModal';
import { useTranslation } from 'react-i18next';
import PreviewChartModal from '../PreviewChartModal';
import { createAxios } from 'ts/createAxios';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { RoleType } from 'util/Enums';
import { initDatasetColors } from 'util/dashboardTypes';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'util/ConstantValues';
import { API_ENDPOINTS } from 'util/endPoints';
import { normalizeNumber } from 'util/commons';
import { FilterObject } from 'pages/Dashboard';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';
import LoadingCoating from 'components/atoms/LoadingCoating';

type WasteAreaResponse = {
  id: string;
  name: string;
  isOther?: boolean;
  englishName?: string;
  tenantFullName?: string;
  wasteUnits: {
    name: string;
    weight: number;
  }[];
}[];
type WasteCSVResponse = {
  dateRange: string;
  wasteUnits: string[];
  industries: string[];
  tenants: string[];
  sites: string[];
  wasteSites: {
    id: string;
    name: string;
    englishName?: string;
    wasteUnits: { name: string; weight: number }[];
  }[];
  wasteTenants: {
    id: string;
    name: string;
    englishName?: string;
    tenantFullName?: string;
    wasteUnits: { name: string; weight: number }[];
  }[];
};
const ChartWasteAmountArea = ({
  filter,
  isAdminOperatorSiteView,
}: {
  filter: FilterObject;
  isAdminOperatorSiteView?: boolean;
}) => {
  const [isOpenDownload, setOpenDownload] = useState(false);
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [wasteArea, setWasteArea] = useState<WasteAreaResponse>();
  const user = useSelector(selectUser);
  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const commonParams = useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants?.map((item) =>
          item.id.toString() === '' ? null : item.id
        ) || [],
      tenantNames:
        filter?.tenants?.map((item) => item?.tenantFullName || item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);
  const [downloadData, setDownloadData] = useState<WasteCSVResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = useRef<any>();

  const convertWasteAreaData =
    wasteArea?.map((data) => {
      const sortWasteUnits = data.wasteUnits?.sort(
        (a, b) => b.weight - a.weight
      );
      const otherWasteUnits = sortWasteUnits.slice(6, sortWasteUnits.length);

      const otherWasteUnit = otherWasteUnits.reduce(
        (total, item) => total + item.weight,
        0
      );

      const newOtherWasteUnit = {
        name: t('dashboard.other'),
        weight: otherWasteUnit,
      };

      const baseWasteUnits = sortWasteUnits.slice(0, 6);

      const convertWasteUnits = baseWasteUnits.concat(newOtherWasteUnit);

      return { ...data, wasteUnits: convertWasteUnits };
    }) || [];

  const { triggerFetch: handleDownloadCSV, loading: csvDownloading } =
    useCallbackLoading({
      callback: () =>
        commonsApi
          .post(
            adminOperatorView
              ? API_ENDPOINTS.DASHBOARD_WASTE_SITES_EXPORT
              : API_ENDPOINTS.DASHBOARD_WASTE_TENANTS_EXPORT,
            commonParams
          )
          .then((res) => {
            setDownloadData(res.data);
            setTimeout(() => {
              downloadCSVRef.current.link.click();
            }, 500);
          }),
    });

  const { triggerFetch: fetchData, loading: dataFetching } = useCallbackLoading(
    {
      callback: (params: typeof commonParams) =>
        commonsApi
          .post<WasteAreaResponse>(
            adminOperatorView
              ? API_ENDPOINTS.DASHBOARD_WASTE_SITES
              : API_ENDPOINTS.DASHBOARD_WASTE_TENANTS,
            params
          )
          .then((res) => {
            const wasteAreaSize = res.data.length;

            setWasteArea(
              wasteAreaSize >= 4
                ? res.data
                : [
                    ...new Array(4 - wasteAreaSize).fill({
                      id: '',
                      name: '',
                      wasteUnits: [],
                      ...(user.role === RoleType.BrandOwner
                        ? { tenantFullName: '' }
                        : {}),
                    }),
                    ...res.data,
                  ]
            );
          }),
    }
  );

  useEffect(() => {
    fetchData(commonParams);
  }, [commonParams]);

  const data = {
    labels: wasteArea?.map((data) =>
      data.isOther
        ? adminOperatorView
          ? t('dashboard.other_sites')
          : adminSiteView
          ? t('dashboard.other_tenants')
          : t('dashboard.other_stores')
        : (data?.tenantFullName || data.name).length > 20
        ? (data?.tenantFullName || data.name).substring(0, 20) + '...'
        : i18n.language === 'ja'
        ? data?.tenantFullName || data.name
        : data?.englishName || data.name || data?.tenantFullName
    ),
    datasets: [
      ...initDatasetColors.map((item, index) => ({
        maxBarThickness: 100,
        backgroundColor: item,
        data:
          wasteArea?.map((data) => {
            const sortWasteUnits = data.wasteUnits?.sort(
              (a, b) => b.weight - a.weight
            );
            const otherWasteUnits = sortWasteUnits.slice(
              6,
              sortWasteUnits.length
            );

            const otherWasteUnit = otherWasteUnits.reduce(
              (total, item) => total + item.weight,
              0
            );

            const newOtherWasteUnit = {
              name: t('dashboard.other'),
              weight: otherWasteUnit,
            };

            const baseWasteUnits = sortWasteUnits.slice(0, 6);

            const convertWasteUnits = baseWasteUnits.concat(newOtherWasteUnit);

            return String(convertWasteUnits[index]?.weight || 0);
          }) || [],
        datalabels: {
          display: false,
        },
      })),
    ],
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartRef = useRef<any>();
  const options = {
    indexAxis: 'y' as const,
    layout: {
      padding: 24,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            const data = convertWasteAreaData[tooltipItem[0].dataIndex];
            const title = data.isOther
              ? adminOperatorView
                ? t('dashboard.other_sites')
                : adminSiteView
                ? t('dashboard.other_tenants')
                : t('dashboard.other_stores')
              : i18n.language === 'ja'
              ? data?.tenantFullName || data.name
              : data?.englishName || data?.tenantFullName || data.name;
            return title;
          },
          label: (tooltipItem) =>
            (convertWasteAreaData
              ? convertWasteAreaData[tooltipItem.dataIndex]?.wasteUnits[
                  tooltipItem.datasetIndex
                ]?.name
              : '') + `: ${normalizeNumber({ value: tooltipItem.raw })} kg`,
        },
      },
    },
    elements: {
      bar: {
        borderSkipped: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          color: '#3C3C3C',
        },
        max: wasteArea?.every((item) => item.wasteUnits.every((u) => !u.weight))
          ? 10
          : undefined,
        ticks: {
          stepSize: wasteArea?.every((item) =>
            item.wasteUnits.every((u) => !u.weight)
          )
            ? 1
            : undefined,
          color: '#CFCFCF',
          callback: function (value) {
            return normalizeNumber({ value }) + ' kg';
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#CFCFCF',
          crossAlign: 'far' as const,
          font: {
            size: 10,
          },
          autoSkip: false,
        },
      },
    },
  };
  const plugins = (isPreview?: boolean) => {
    return [
      {
        id: 'customCanvasBackgroundColor',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeDraw: (chart, args, options) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = isPreview ? '#24252a' : '#2C2C2C';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
  };

  return (
    <div className={styles.RecyclingChartContainer}>
      <LoadingCoating
        show={csvDownloading || dataFetching}
        styles={{ borderRadius: 12 }}
      />

      <div className={styles.heading}>
        <p>
          {t(
            adminSiteView
              ? 'dashboard.waste_amount_chart_tenant'
              : adminOperatorView
              ? 'dashboard.waste_amount_chart'
              : 'dashboard.waste_amount_chart_store'
          )}
        </p>
        <div
          className={styles.buttonDownload}
          onClick={() => {
            setOpenDownload(true);
          }}
        >
          <IconDownload />
        </div>
      </div>
      <Bar
        onClick={() => setOpen(true)}
        options={options}
        data={data}
        plugins={plugins()}
      />
      {isOpen && (
        <PreviewChartModal
          title={
            t(
              adminSiteView
                ? 'dashboard.waste_amount_chart_tenant'
                : adminOperatorView
                ? 'dashboard.waste_amount_chart'
                : 'dashboard.waste_amount_chart_store'
            ) || ''
          }
          children={
            <Bar options={options} data={data} plugins={plugins(true)} />
          }
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {isOpenDownload && (
        <DashboardDownloadModal
          onClose={() => {
            setOpenDownload(false);
          }}
          handleDownload={(data) => {
            if (data.isImage && chartRef.current) {
              const link = document.createElement('a');
              link.download = `${t(
                adminSiteView
                  ? 'dashboard.waste_amount_chart_tenant'
                  : adminOperatorView
                  ? 'dashboard.waste_amount_chart'
                  : 'dashboard.waste_amount_chart_store'
              )} ${dayjs().format(DATE_FORMAT.slaYMDHm)}.png`;
              link.href = chartRef.current.toBase64Image('image/png', 1);
              link.click();
            }
            if (data.isCsv && handleDownloadCSV) {
              handleDownloadCSV();
            }
            setOpenDownload(false);
          }}
        />
      )}
      <div className={styles.chartHidden}>
        <Bar
          className={styles.chartHidden}
          ref={chartRef}
          options={{
            indexAxis: 'y',
            layout: {
              padding: 24,
            },
            plugins: {
              legend: {
                display: false,
                position: 'right',
                labels: {
                  color: '#000000',
                  font: { size: 18 },
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                  padding: 16,
                },
              },
            },
            elements: {
              bar: {
                borderSkipped: true,
              },
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
                grid: {
                  color: '#9EA0A2',
                },
                max: wasteArea?.every((item) =>
                  item.wasteUnits.every((u) => !u.weight)
                )
                  ? 10
                  : undefined,
                ticks: {
                  color: 'black',
                  stepSize: wasteArea?.every((item) =>
                    item.wasteUnits.every((u) => !u.weight)
                  )
                    ? 1
                    : undefined,
                  font: {
                    size: 18,
                  },
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  callback: function (value, index, values) {
                    return normalizeNumber({ value }) + ' kg';
                  },
                },
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  crossAlign: 'far' as const,
                  color: 'black',
                  font: {
                    size: 24,
                  },
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              id: 'customCanvasBackgroundColor',
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
              },
            },
          ]}
        />
      </div>
      <CSVLink
        className="hidden"
        filename={
          t(
            adminSiteView
              ? 'dashboard.waste_amount_chart_tenant'
              : adminOperatorView
              ? 'dashboard.waste_amount_chart'
              : 'dashboard.waste_amount_chart_store'
          ) +
          ` ${dayjs().format(DATE_FORMAT.slaYMDHm)}` +
          '.csv'
        }
        data={[
          [
            adminOperatorView
              ? t('dashboard.co2_chart_csv.site_name')
              : adminSiteView
              ? t('dashboard.co2_chart_csv.tenant_name')
              : t('dashboard.co2_chart_csv.store_name'),
            t('dashboard.co2_chart_csv.item'),
            t('dashboard.rate_recycle_method_chart.waste_volume') + '(kg)',
          ],
          downloadData?.dateRange
            ? [
                t('dashboard.co2_chart_csv.date_range'),
                downloadData?.dateRange || '',
              ]
            : [],
          downloadData?.sites && adminOperatorView
            ? [
                t('dashboard.co2_chart_csv.site_name'),
                downloadData?.sites?.join(', ') || '',
              ]
            : [],
          downloadData?.industries && adminSiteView
            ? [
                t('dashboard.co2_chart_csv.business_type'),
                downloadData?.industries?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && adminSiteView
            ? [
                t('dashboard.co2_chart_csv.tenant_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && user.role === RoleType.BrandOwner
            ? [
                t('dashboard.co2_chart_csv.store_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.wasteUnits
            ? [
                t('dashboard.co2_chart_csv.item'),
                downloadData?.wasteUnits?.join(', ') || '',
              ]
            : [],
          ...(
            downloadData?.[
              adminOperatorView ? 'wasteSites' : 'wasteTenants'
            ]?.map((wasteGroup) => {
              return wasteGroup.wasteUnits
                .sort((a, b) => {
                  return b.weight - a.weight;
                })
                .map((item) => [
                  i18n.language === 'ja'
                    ? wasteGroup?.tenantFullName || wasteGroup.name
                    : wasteGroup?.englishName ||
                      wasteGroup?.tenantFullName ||
                      wasteGroup.name,
                  item.name,
                  normalizeNumber({ value: item.weight, toFixed: false }),
                ]);
            }) || []
          ).flat(),
        ].filter((data) => data.length)}
        ref={downloadCSVRef}
      ></CSVLink>
    </div>
  );
};
export default ChartWasteAmountArea;
