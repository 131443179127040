// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

const AUTHEN_LOCK = 'authenLockV2';

export const useAuthenLockTime = () => {
  const getSavedLocks = (): Record<string, string> => {
    const savedLock = sessionStorage.getItem(AUTHEN_LOCK);

    try {
      return savedLock ? JSON.parse(savedLock) : {};
    } catch (_) {
      return {};
    }
  };

  const [lock, setLock] = useState<Record<string, string>>(getSavedLocks());
  const [userId, setUserId] = useState<string>();
  const [timeRemain, setTimeRemain] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timer>();

  const updateLock = (userId: string, lockEndTime: string) => {
    setLock((prev) => ({
      ...prev,
      [userId]: lockEndTime,
    }));
  };

  const resetLock = () => {
    if (!userId) {
      return;
    }

    setTimeRemain(0);
    clearInterval(timerRef.current);

    const savedLocks = getSavedLocks();
    delete savedLocks[userId || ''];

    if (Object.keys(savedLocks).length === 0) {
      sessionStorage.removeItem(AUTHEN_LOCK);
      return;
    }

    sessionStorage.setItem(AUTHEN_LOCK, JSON.stringify(savedLocks));
  };

  useEffect(() => {
    if (
      !userId ||
      !dayjs(lock[userId]).isValid() ||
      dayjs(lock[userId]).diff(dayjs(), 'seconds') < 0
    ) {
      resetLock();
      return;
    }

    sessionStorage.setItem(AUTHEN_LOCK, JSON.stringify(lock));
    clearInterval(timerRef.current);
    const timeDiff = dayjs(lock[userId]).diff(dayjs(), 'seconds');
    setTimeRemain(Math.floor(timeDiff));

    timerRef.current = setInterval(() => {
      setTimeRemain((prev) => {
        const newVal = prev - 1;

        if (newVal > 0) {
          return newVal;
        }

        resetLock();
        return 0;
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [lock, resetLock, userId]);

  return {
    updateLock,
    setCheckLockUserId: setUserId,
    timeRemain,
    countdown:
      timeRemain > 0
        ? dayjs.duration(timeRemain, 'seconds').format('mm:ss')
        : undefined,
  };
};
