// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import InputField from 'components/atoms/InputField';
import IconAddCircleOutline20 from 'components/atoms/icons/IconAddCircleOutline20';
import IconTrash from 'components/atoms/icons/IconTrash';
import { InputDataListField } from 'components/molecules/InputDataListField';
import arrayMutators from 'final-form-arrays';
import { ModalSiteState } from 'pages/AdminSiteListManagement/AdminSiteListManagement';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import { mergeClasses } from 'util/commons';
import { PurchaseSiteItem } from 'util/siteManagementTypes';
import {
  validateId,
  validateRequiredField,
  validateStringField,
} from 'util/validator';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

interface Props {
  createUpdateModal: ModalSiteState;
  onCreatePurchaseSite: (createForm: PurchaseSiteItem) => void;
  onUpdatePurchaseSite: (updateForm: PurchaseSiteItem) => void;
  inputForm: PurchaseSiteItem;
}

const CreateUpdatePurchaseForm: React.FC<Props> = ({
  createUpdateModal,
  onCreatePurchaseSite,
  onUpdatePurchaseSite,
  inputForm,
}) => {
  const { t } = useTranslation();

  const handleCreateUpdatePurchase = (formData: PurchaseSiteItem) => {
    if (createUpdateModal.type === ModalType.add) {
      onCreatePurchaseSite(formData);
    } else {
      onUpdatePurchaseSite(formData);
    }
  };

  const validate = (values: PurchaseSiteItem) => {
    const errors = {};
    if (
      !values.comparisonOfProcurementIntensity ||
      values.comparisonOfProcurementIntensity.length === 0
    ) {
      errors['comparisonOfProcurementIntensity'] = [
        'At least one item is required',
      ];
    } else {
      const itemsArrayErrors: Record<string, string>[] = [];
      values.comparisonOfProcurementIntensity.forEach((item, index) => {
        const itemErrors: Record<string, string> = {};
        if (!item || !item.procuredMaterialToCompare) {
          itemErrors.procuredMaterialToCompare = t(
            'site_management.modal.comparison_error',
            {
              field: t('site_management.modal.field_type_materials_to_compare'),
            }
          );
          itemsArrayErrors[index] = itemErrors;
        }

        if (!item || !item.intensityCo2OfProcuredMaterialToCompare) {
          itemErrors.intensityCo2OfProcuredMaterialToCompare = t(
            'site_management.modal.comparison_error',
            {
              field: t('site_management.modal.CO2_intensity_of_materials'),
            }
          );
          itemsArrayErrors[index] = itemErrors;
        }
      });
      if (itemsArrayErrors.length) {
        errors['comparisonOfProcurementIntensity'] = itemsArrayErrors;
      }
    }
    return {
      ...errors,
      id: validateId(t('site_management.modal.field_id'), 20, values.id),
      name: validateStringField(
        t('site_management.modal.field_name'),
        100,
        values.name
      ),
      province: validateStringField(
        t('site_management.modal.field_province'),
        100,
        values.province
      ),
      district: validateStringField(
        t('site_management.modal.field_district'),
        100,
        values.district
      ),
      procurementUnitPrice: validateRequiredField(
        values.procurementUnitPrice,
        t('site_management.modal.target_procurement_price') || ''
      ),
      prevYearInputAmount: validateRequiredField(
        values.prevYearInputAmount,
        t('site_management.modal.previous_year_input_amount') || ''
      ),
      prevYearScope1: validateRequiredField(
        values.prevYearScope1,
        t('site_management.modal.field_prev_year_scope1') || ''
      ),
      prevYearScope2: validateRequiredField(
        values.prevYearScope2,
        t('site_management.modal.field_prev_year_scope2') || ''
      ),
    };
  };

  return (
    <div className={styles.createBody}>
      <Form
        initialValues={inputForm}
        onSubmit={(values: PurchaseSiteItem) => {
          handleCreateUpdatePurchase(values);
        }}
        validate={validate}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }}
      >
        {(props) => {
          return (
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>
                <div className={styles.textFrame}>
                  <InputDataListField
                    name={'id'}
                    label={t('site_management.modal.field_id')}
                    placeholder={t('site_management.modal.field_id')}
                    required
                    maxlength={20}
                    disabled={createUpdateModal.type === ModalType.update}
                  />

                  <InputDataListField
                    name={'name'}
                    label={t('site_management.modal.field_name')}
                    placeholder={t('site_management.modal.field_name')}
                    required
                    maxlength={100}
                  />

                  <InputDataListField
                    name={'province'}
                    label={t('site_management.modal.field_province')}
                    placeholder={t('site_management.modal.field_province')}
                    required
                    maxlength={100}
                  />

                  <InputDataListField
                    name={'district'}
                    label={t('site_management.modal.field_district')}
                    placeholder={t('site_management.modal.field_district')}
                    required
                    maxlength={100}
                  />

                  {/* change to form array */}
                  <div
                    className={mergeClasses(styles.comparison, styles.label)}
                  >
                    <span>
                      {t(
                        'site_management.modal.comparison_of_procurement_intensity'
                      )}
                    </span>
                  </div>

                  <FieldArray name="comparisonOfProcurementIntensity">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <>
                          <div key={index} className={styles.comparison}>
                            <InputField
                              name={`${name}.procuredMaterialToCompare`}
                              label={t(
                                'site_management.modal.field_type_materials_to_compare'
                              )}
                              placeholder={
                                t(
                                  'site_management.modal.field_type_materials_to_compare'
                                ) || ''
                              }
                              suffix={
                                props.values.comparisonOfProcurementIntensity
                                  .length !== 1 && (
                                  <IconTrash
                                    onClick={() => {
                                      Promise.resolve(
                                        fields.remove(index)
                                      ).then(() => {
                                        // add 1 blank field
                                        if (fields.length === 1) {
                                          props.form.mutators.push(
                                            'comparisonOfProcurementIntensity',
                                            {
                                              procuredMaterialToCompare: '',
                                              intensityCo2OfProcuredMaterialToCompare:
                                                '',
                                            }
                                          );
                                        }
                                      });
                                    }}
                                  />
                                )
                              }
                              maxLength={100}
                              arrayIndex={index + 1}
                              required
                              darkMode
                            />
                            <InputField
                              name={`${name}.intensityCo2OfProcuredMaterialToCompare`}
                              label={t(
                                'site_management.modal.CO2_intensity_of_materials'
                              )}
                              placeholder={
                                t(
                                  'site_management.modal.CO2_intensity_of_materials'
                                ) || ''
                              }
                              arrayIndex={index + 1}
                              regex={REGEX_OBJECT.tenDigitsWith2Decimal}
                              onBlur={(e) =>
                                props.form.mutators.setValue(
                                  `comparisonOfProcurementIntensity[${index}].intensityCo2OfProcuredMaterialToCompare`,
                                  !_isEmpty(e.target.value)
                                    ? parseFloat(e.target.value).toString()
                                    : e.target.value
                                )
                              }
                              required
                              darkMode
                            />
                          </div>
                        </>
                      ))
                    }
                  </FieldArray>
                  {(!props.values.comparisonOfProcurementIntensity ||
                    props.values.comparisonOfProcurementIntensity.length <
                      4) && (
                    <div className={styles.comparison}>
                      <div className={styles.addContainer}>
                        <div
                          onClick={() => {
                            props.form.mutators.push(
                              'comparisonOfProcurementIntensity',
                              {
                                procuredMaterialToCompare: '',
                                intensityCo2OfProcuredMaterialToCompare: '',
                              }
                            );
                          }}
                          className={styles.addButton}
                        >
                          <IconAddCircleOutline20 />
                          {t('site_management.modal.add')}
                        </div>
                      </div>
                    </div>
                  )}

                  <InputDataListField
                    name={'procurementUnitPrice'}
                    label={t('site_management.modal.target_procurement_price')}
                    placeholder={t(
                      'site_management.modal.target_procurement_price'
                    )}
                    maxlength={50}
                    required
                    validRegex={REGEX_OBJECT.nonNegativeNumber}
                  />

                  <InputDataListField
                    name={'prevYearInputAmount'}
                    label={
                      t('site_management.modal.previous_year_input_amount') ||
                      ''
                    }
                    placeholder={
                      t('site_management.modal.previous_year_input_amount') ||
                      ''
                    }
                    validRegex={REGEX_OBJECT.decimalNumberWithDot5}
                    endExtraText="kg"
                    required
                  />

                  <InputDataListField
                    name={'prevYearScope1'}
                    label={t('site_management.modal.field_prev_year_scope1')}
                    placeholder={t(
                      'site_management.modal.field_prev_year_scope1'
                    )}
                    validRegex={REGEX_OBJECT.decimalNumberWithDot10}
                    endExtraText="kg-CO2"
                    required
                  />

                  <InputDataListField
                    name={'prevYearScope2'}
                    label={t('site_management.modal.field_prev_year_scope2')}
                    placeholder={t(
                      'site_management.modal.field_prev_year_scope2'
                    )}
                    validRegex={REGEX_OBJECT.decimalNumberWithDot10}
                    endExtraText="kg-CO2"
                    required
                  />
                </div>
              </div>

              <div className={styles.submit}>
                <button
                  className={styles.submitButton}
                  disabled={props.invalid}
                  type="button"
                  onClick={props.handleSubmit}
                >
                  {createUpdateModal.type === ModalType.add
                    ? t('operator_management.button_create_operator')
                    : t('operator_management.button_update_operator')}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateUpdatePurchaseForm;
