// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

interface Props {
  onClick?: VoidFunction;
}
export const IconSeeMore = ({ onClick }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#525252"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        id="more_horiz_2"
        d="M6.23042 14C5.81794 14 5.46482 13.8531 5.17107 13.5593C4.87734 13.2656 4.73047 12.9125 4.73047 12.5C4.73047 12.0875 4.87734 11.7344 5.17107 11.4406C5.46482 11.1469 5.81794 11 6.23042 11C6.64292 11 6.99604 11.1469 7.28977 11.4406C7.58352 11.7344 7.73039 12.0875 7.73039 12.5C7.73039 12.9125 7.58352 13.2656 7.28977 13.5593C6.99604 13.8531 6.64292 14 6.23042 14ZM11.9996 14C11.5872 14 11.234 13.8531 10.9403 13.5593C10.6465 13.2656 10.4997 12.9125 10.4997 12.5C10.4997 12.0875 10.6465 11.7344 10.9403 11.4406C11.234 11.1469 11.5872 11 11.9996 11C12.4121 11 12.7652 11.1469 13.059 11.4406C13.3527 11.7344 13.4996 12.0875 13.4996 12.5C13.4996 12.9125 13.3527 13.2656 13.059 13.5593C12.7652 13.8531 12.4121 14 11.9996 14ZM17.7689 14C17.3564 14 17.0033 13.8531 16.7095 13.5593C16.4158 13.2656 16.2689 12.9125 16.2689 12.5C16.2689 12.0875 16.4158 11.7344 16.7095 11.4406C17.0033 11.1469 17.3564 11 17.7689 11C18.1814 11 18.5345 11.1469 18.8282 11.4406C19.122 11.7344 19.2688 12.0875 19.2688 12.5C19.2688 12.9125 19.122 13.2656 18.8282 13.5593C18.5345 13.8531 18.1814 14 17.7689 14Z"
        stroke="#86EA5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
