// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { CsvDownloadButton } from 'components/atoms/CsvDownloadButton';
import { CalendarField } from 'components/molecules/CalendarField';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAxios } from 'ts/createAxios';
import { getSlaYMDhm } from 'ts/formatDate';
import './WeightNotices.scss';

type WeightNotice = {
  id: string;
  datetime: string;
  areaId: string;
  areaName: string;
  userId: string;
  userName: string;
  description: string;
  type: string;
  collect: string;
  note: string;
};

const WeightNotices = () => {
  const api = createAxios();
  const [weightNotices, setWeightNotices] = useState<WeightNotice[]>([]);
  const { t } = useTranslation();

  const callApi = async (date: string | null) => {
    let params = '';
    if (date) {
      params = '?targetDate=' + date;
    }

    api.get('/notices' + params).then((res) => {
      setWeightNotices(res.data);
    });
  };
  useEffect(() => {
    callApi(null);
  }, []);

  return (
    <AdminTemplate
      h1={t('common.sidebar_notification')}
      active="通知リスト"
      breadcrumb={[{ label: t('common.sidebar_notification') }]}
      className="weight-notices-page"
    >
      <div
        className={'countList'}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t('common.page', {
              page: weightNotices.length || 0,
            }) ?? ''
          ),
        }}
      />
      <div className="search-area">
        <CalendarField
          maxDate={new Date()}
          onChange={(value) => {
            callApi(value);
          }}
        />
        <CsvDownloadButton
          fileName={'通知リスト'}
          headers={[
            t('notification.thead_datetime'),
            t('notification.thead_thead_person'),
            t('notification.thead_place'),
            t('notification.thead_registerd_content'),
            t('notification.thead_notification_content'),
            t('notification.thead_repair_status'),
            t('notification.thead_remarks'),
          ]}
          fetchData={weightNotices.map((item) => {
            return [
              getSlaYMDhm(item.datetime),
              item.userId + '(' + item.userName + ')',
              item.areaName,
              item.description,
              item.type,
              item.collect,
              item.note,
            ];
          })}
        />
      </div>

      <div className="scroll-wrapper">
        <table id="notices-table">
          <thead>
            <tr>
              <th className="c--date">{t('notification.thead_datetime')}</th>
              <th className="c--staff">
                {t('notification.thead_thead_person')}
              </th>
              <th className="c--area">{t('notification.thead_place')}</th>
              <th className="c--description">
                {t('notification.thead_registerd_content')}
              </th>
              <th className="c--type">
                {t('notification.thead_notification_content')}
              </th>
              <th className="c--collect">{t('notification.thead_collect')}</th>
              <th className="c--note">{t('notification.thead_remarks')}</th>
            </tr>
          </thead>
          <tbody>
            {weightNotices.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="c--date">
                    {getSlaYMDhm(item.datetime) || '-'}
                  </td>
                  <td className="left c--staff">
                    {item.userId + '(' + item.userName + ')' || '-'}
                  </td>
                  <td className="left c--area">{item.areaName || '-'}</td>
                  <td className="left c--description">
                    {item.description || '-'}
                  </td>
                  <td className="left c--type">{item.type || '-'}</td>
                  <td className="left c--collect">{item.collect || '-'}</td>
                  <td className="left c--note">{item.note}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </AdminTemplate>
  );
};

export default WeightNotices;
