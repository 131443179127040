// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
/* eslint-disable @typescript-eslint/no-explicit-any */
export class Csv {
  private data: Record<string, any>[];

  private columnDelimiter = ',';

  private lineDelimiter = '\n';

  private delimiterRegex = /[,\n\r\t]/g;

  private keys: string[] = [];

  private headers: string[] = [];

  private headNote = '';

  constructor(params: {
    data: Record<string, any>[];
    headers?: (string | [string, string])[];
    withHeaders?: boolean;
    headNote?: string;
  }) {
    const { data, headers, headNote } = params;

    this.data = data;

    if (headers?.length) {
      headers.forEach((header) => {
        if (Array.isArray(header)) {
          this.keys.push(header[0]);
          this.headers.push(header[1]);
        } else {
          this.keys.push(header);
          this.headers.push(header);
        }
      });
    } else if (data.length) {
      this.keys = Object.keys(this.data[0]);
      this.headers = [...this.keys];
    }

    if (headNote) {
      this.headNote = headNote;
    }
  }

  convertDataWithQuote(data: any) {
    if (typeof data === 'string' && data.match(this.delimiterRegex)) {
      return `"${data.replace(/"/g, '""')}"`;
    }

    return data;
  }

  getCsvData() {
    if (!this.data.length) {
      return [];
    }

    return this.data.reduce<string[]>((result, item) => {
      const itemData = this.keys
        .map((key) => this.convertDataWithQuote(item[key]))
        .join(this.columnDelimiter);
      result.push(itemData);

      return result;
    }, []);
  }

  generateCsv(withHeaders = true) {
    const csvData = this.getCsvData();

    if (withHeaders) {
      const headers = this.headers
        .map((header) => this.convertDataWithQuote(header))
        .join(this.columnDelimiter);

      csvData.unshift(headers);
    }

    const csvString = csvData.join(this.lineDelimiter);

    if (this.headNote) {
      return `${this.headNote}${this.lineDelimiter.repeat(2)}${csvString}`;
    }

    return csvString;
  }

  downLoadCsv(params: {
    filename: string;
    withHeaders?: boolean;
    charset?: string;
  }) {
    const { filename, withHeaders = true, charset = 'utf-8' } = params;

    const downloadEl = document.createElement('a');

    const csvData = this.generateCsv(withHeaders);

    downloadEl.href = `data:text/csv;charset=${charset},${encodeURIComponent(
      csvData
    )}`;
    downloadEl.download = `${filename}.csv`;
    downloadEl.click();

    downloadEl.remove();
  }
}

export default Csv;
