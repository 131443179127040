// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { TenantStatisticFilter } from 'components/organisms/TenantStatistics';
import i18n from 'i18n';
import { createAxios } from 'ts/createAxios';
import { DbManager } from 'util/DbManager';
import { DateTypeRange, TenantAuthen } from 'util/Enums';
import {
  BrandItem,
  DashboardsTenantsCompareRecycle,
  DashboardsTenantsCompareTotalEmissionWeight,
  DashboardsTenantsRateCO2WasteUnits,
  DashboardsTenantsRateRecycleMethods,
  DashboardsTenantsRateWasteCosts,
  DashboardsTenantsRateWasteUnits,
  DashboardsTenantsWastes,
  FilterInvoiceData,
  FloorItem,
  InputTenantItem,
  InvoiceData,
  SectionItem,
  TenantBase,
  TenantForm,
  TenantSiteForm,
} from 'util/Types';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import { Path } from 'routes';
import { History } from 'history';

export type FetchSectionData = {
  page: number;
  total: number;
  sections: SectionItem[];
  siteName: string;
  operatorName: string;
};
export const fetchSections = async (
  params: { siteId: string; keyword?: string },
  callback?: (data: FetchSectionData) => void
) => {
  const api = createAxios();
  const { data } = await api.get<FetchSectionData>(`/sections`, { params });
  data && callback && callback(data);
};

export type FetchFloorData = {
  floors: FloorItem[];
};

export type FetchBrandData = {
  brands: BrandItem[];
  total: number;
  page: number;
};
export const fetchFloors = async (
  params: { siteId: string },
  callback?: (data: FetchFloorData) => void
) => {
  const api = createAxios();
  const { data } = await api.get<FetchFloorData>(`/floors`, { params });
  data && callback && callback(data);
};

export type FetchBrandParams = {
  page?: number;
  take?: number;
  keyword?: string;
};
export const fetchBrands = async ({
  params,
  callback,
}: {
  params?: FetchBrandParams;
  callback?: (data: FetchBrandData) => void;
}) => {
  const api = createAxios();
  const { data } = await api.get<FetchBrandData>(`/brands`, { params });
  data && callback && callback(data);
};

export const createTenant = async (
  params: TenantForm,
  callback?: (data: { data: string }) => void
) => {
  try {
    const api = createAxios();
    const data = await api.post(`/tenants`, {
      siteId: params.siteId,
      name: params.name.trim(),
      ...(params.sectionId ? { sectionId: Number(params.sectionId) } : {}),
      ...(params.sectionId && params.floorId
        ? { floorId: Number(params.floorId) }
        : {}),
      ...(params.brandId ? { brandId: params.brandId } : {}),
      industry: params.industry.trim(),
      inputTenantItems: params.inputTenantItems.map((item) => {
        return {
          wasteUnitId: Number(item.id),
          price: item.price ? Number(item.price) : 0,
          itemName: item.name,
        };
      }),
      tenantNumber: params.tenantNumber?.trim() ?? '',
      contactName: params.contactName?.trim() ?? '',
      contactEmail: params.contactEmail?.trim() ?? '',
      isDashboardEnabled: params.isDashboardEnabled ?? false,
    });
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateTenant = async (
  params: TenantForm,
  tenantId: number,
  callback?: (data: { data: string }) => void
) => {
  try {
    const api = createAxios();
    const data = await api.put(`/tenants/${tenantId}`, {
      name: params.name.trim(),
      sectionId: params.sectionId ? Number(params.sectionId) : null,
      floorId:
        params.sectionId && params.floorId ? Number(params.floorId) : null,
      brandId: params.brandId ? params.brandId : null,
      industry: params.industry.trim(),
      inputTenantItems: params.inputTenantItems.map((item) => {
        return {
          wasteUnitId: Number(item.id),
          price: item.price ? Number(item.price) : 0,
          itemName: item.name,
        };
      }),
      tenantNumber: params.tenantNumber?.trim() ?? '',
      contactName: params.contactName?.trim() ?? '',
      contactEmail: params.contactEmail?.trim() ?? '',
      isDashboardEnabled: params.isDashboardEnabled ?? false,
    });
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const createTenantAdminSite = async (
  params: TenantSiteForm,
  callback?: (data: { data: string }) => void
) => {
  try {
    const api = createAxios();
    const icCards = params.icCards.map((item) => item.card).filter(Boolean);

    const data = await api.post(API_ENDPOINTS.TENANTS_ADMIN_SITE, {
      name: params.name.trim(),
      ...(params.sectionId && { sectionId: Number(params.sectionId) }),
      ...(params.sectionId &&
        params.floorId && { floorId: Number(params.floorId) }),
      industry: params.industry.trim(),
      tenantNumber: params.tenantNumber?.trim() ?? '',
      contactName: params.contactName?.trim() ?? '',
      contactEmail: params.contactEmail?.trim() ?? '',
      barcode: params.barcode?.trim() ?? '',
      icCards,
    });
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateTenantAdminSite = async (
  params: TenantSiteForm,
  tenantId: number,
  callback?: (data: { data: string }) => void
) => {
  try {
    const api = createAxios();
    const icCards = params.icCards.map((item) => item.card).filter(Boolean);

    const data = await api.put(
      `${API_ENDPOINTS.TENANTS_ADMIN_SITE}/${tenantId}`,
      {
        name: params.name.trim(),
        sectionId: params.sectionId ? Number(params.sectionId) : null,
        floorId:
          params.sectionId && params.floorId ? Number(params.floorId) : null,
        industry: params.industry.trim(),
        tenantNumber: params.tenantNumber?.trim() ?? '',
        contactName: params.contactName?.trim() ?? '',
        contactEmail: params.contactEmail?.trim() ?? '',
        barcode: params.barcode?.trim() ?? '',
        ...(icCards.length > 0 && { icCards }),
      }
    );
    data && callback && callback(data);
  } catch (error) {
    handleError(error);
  }
};

export const fetchTenantId = async (
  params: { authenMethod: TenantAuthen; code: string },
  callback?: (data: { id: string; name: string }) => Promise<void>
) => {
  try {
    const cachedSites = await DbManager.sites.toArray();

    let data:
      | {
          id: string;
          name: string;
        }
      | undefined = undefined;

    if (cachedSites.length) {
      const cachedTenants = await DbManager.tenants.toArray();

      const tenantData = cachedTenants.find((tenant) => {
        if (params.authenMethod === TenantAuthen.Barcode) {
          return tenant.barcode === params.code;
        }

        if (params.authenMethod === TenantAuthen.ICCard) {
          return tenant.icCards?.some(({ card }) => card === params.code);
        }

        return false;
      });

      if (tenantData) {
        data = {
          id: tenantData.id.toString(),
          name: tenantData.name,
        };
      } else if (!navigator.onLine) {
        throw {
          response: {
            data: {
              message:
                params.authenMethod === TenantAuthen.ICCard
                  ? i18n.t('select_tenant.iccard.message_error')
                  : i18n.t('select_tenant.badcode.message_error'),
            },
          },
        };
      }
    }

    if (!data) {
      const res = await createAxios().post<{ id: string; name: string }>(
        '/emissions/tenant/authen',
        {
          authenMethod: params.authenMethod,
          code: params.code,
        }
      );

      data = res.data;
    }

    if (data) {
      await callback?.(data);

      if (params.authenMethod === TenantAuthen.ICCard) {
        window && window.verifyICCardSuccess(true);
      }
    }
  } catch (error) {
    if (params.authenMethod === TenantAuthen.ICCard) {
      window && window.verifyICCardSuccess(false);
    }
    handleError(error);
  }
};
export interface TenantIndustry {
  industry: string;
}
export const fetchCommonTenantIndustries = async (
  callback?: (data: TenantIndustry[]) => void
) => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { data } = await commonsApi.get<TenantIndustry[]>(
    `/commons/tenants/industry`
  );
  data && callback && callback(data);
};

// using when create tenant
export const getWasteUnitsSite = async (
  id: string,
  successCallback?: (data: InputTenantItem[]) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get<InputTenantItem[]>(
      getApiPath(API_ENDPOINTS.WASTE_UNITS_SITE, {
        id,
      })
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

// using when edit tenant
export const getWasteUnitsTenant = async (
  id: string,
  successCallback?: (data: InputTenantItem[]) => void
) => {
  const api = createAxios();

  try {
    const { data } = await api.get<InputTenantItem[]>(
      getApiPath(API_ENDPOINTS.WASTE_UNITS_TENANT, {
        id,
      })
    );
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

// Export Tenant Data
export const getTenantsInvoices = async (p: {
  params: FilterInvoiceData;
  successCallback?: (data: InvoiceData) => void;
  failedCallback?: () => void;
}) => {
  const { params, successCallback, failedCallback } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<InvoiceData>(
      getApiPath(API_ENDPOINTS.TENANTS_INVOICES),
      {
        params,
      }
    );
    data && successCallback?.(data);
  } catch (error) {
    failedCallback?.();
    handleError(error);
  }
};

// Tenant Dashboards
const handleErrorForTenantStatistic = (error: unknown, history: History) =>
  handleError(error, false, { 403: () => history.replace(Path.pageNotFound) });

export type TenantStatisticGetParams = TenantStatisticFilter & {
  tenantId?: string;
};

export const getDashboardsTenantsRateWasteUnits = async (p: {
  params: TenantStatisticGetParams;
  successCallback?: (data: DashboardsTenantsRateWasteUnits) => void;
  history: History;
}) => {
  const { params, successCallback, history } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<DashboardsTenantsRateWasteUnits>(
      API_ENDPOINTS.DASHBOARDS_TENANTS_RATE_WASTE_UNITS,
      {
        params,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    handleErrorForTenantStatistic(error, history);
  }
};

export const getDashboardsTenantsRateRecycleMethods = async (p: {
  params: TenantStatisticGetParams;
  successCallback?: (data: DashboardsTenantsRateRecycleMethods) => void;
  history: History;
}) => {
  const { params, successCallback, history } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<DashboardsTenantsRateRecycleMethods>(
      API_ENDPOINTS.DASHBOARDS_TENANTS_RATE_RECYCLE_METHODS,
      {
        params,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    handleErrorForTenantStatistic(error, history);
  }
};

export const getDashboardsTenantsRateWasteCosts = async (p: {
  params: TenantStatisticGetParams;
  successCallback?: (data: DashboardsTenantsRateWasteCosts) => void;
  history: History;
}) => {
  const { params, successCallback, history } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<DashboardsTenantsRateWasteCosts>(
      API_ENDPOINTS.DASHBOARDS_TENANTS_RATE_WASTE_COSTS,
      {
        params,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    handleErrorForTenantStatistic(error, history);
  }
};

export const getDashboardsTenantsWastes = async (p: {
  params: {
    tenantId?: string;
    typeDateRange?: DateTypeRange;
  };
  successCallback?: (data: DashboardsTenantsWastes[]) => void;
  history: History;
}) => {
  const { params, successCallback, history } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<DashboardsTenantsWastes[]>(
      API_ENDPOINTS.DASHBOARDS_TENANTS_WASTES,
      {
        params,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    handleErrorForTenantStatistic(error, history);
  }
};

export const getDashboardsTenantsCompareTotalEmissionWeight = async (p: {
  params: {
    tenantId?: string;
  };
  successCallback?: (data: DashboardsTenantsCompareTotalEmissionWeight) => void;
  failedCallback?: () => void;
  history: History;
}) => {
  const { params, successCallback, failedCallback, history } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<DashboardsTenantsCompareTotalEmissionWeight>(
      API_ENDPOINTS.DASHBOARDS_TENANTS_COMPARE_TOTAL_EMISSION_WEIGHT,
      {
        params,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    failedCallback?.();
    handleErrorForTenantStatistic(error, history);
  }
};

export const getDashboardsTenantsCompareRecycleRate = async (p: {
  params: {
    tenantId?: string;
  };
  successCallback?: (data: DashboardsTenantsCompareRecycle) => void;
  failedCallback?: () => void;
  history: History;
}) => {
  const { params, successCallback, failedCallback, history } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<DashboardsTenantsCompareRecycle>(
      API_ENDPOINTS.DASHBOARDS_TENANTS_COMPARE_RECYCLE_RATE,
      {
        params,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    failedCallback?.();
    handleErrorForTenantStatistic(error, history);
  }
};

export const getDashboardsTenantsRateCO2WasteUnits = async (p: {
  params: TenantStatisticGetParams;
  successCallback?: (data: DashboardsTenantsRateCO2WasteUnits) => void;
  failedCallback?: () => void;
  history: History;
}) => {
  const { params, successCallback, failedCallback, history } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<DashboardsTenantsRateCO2WasteUnits>(
      API_ENDPOINTS.DASHBOARDS_TENANTS_RATE_CO2_WASTE_UNITS,
      {
        params,
      }
    );

    data && successCallback?.(data);
  } catch (error) {
    failedCallback?.();
    handleErrorForTenantStatistic(error, history);
  }
};

export const deleteTenant = async (
  tenantId: number,
  successCallback?: (data: { data: string }) => void
) => {
  try {
    const api = createAxios();
    const data = await api.delete(`/${API_ENDPOINTS.TENANTS}/${tenantId}`);
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const getCommonTenants = async (params?: {
  successCallback?: (data: TenantBase[]) => void;
}) => {
  const { successCallback } = params ?? {};

  try {
    const { data } = await createAxios().get<TenantBase[]>(
      API_ENDPOINTS.COMMONS_TENANT_NAMES
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};
