// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import DonutChart from 'components/molecules/DonutChart';
import dayjs from 'dayjs';
import { FilterObject } from 'pages/Dashboard';
import React, { useEffect, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { createAxios } from 'ts/createAxios';
import { DATE_FORMAT } from 'util/ConstantValues';
import { RoleType } from 'util/Enums';
import { normalizeNumber } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import styles from './SiteWasteRateChart.module.scss';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';

interface SiteWaste {
  rate: number;
  name: string;
  weight: number;
  id?: number;
  englishName?: string;
  tenantFullName?: string;
}

interface SiteWasteResponse {
  rateWasteSites?: SiteWaste[];
  rateWasteTenants?: SiteWaste[];
  dateRange?: string;
  wasteUnits?: string[];
  industries?: string[];
  tenants?: string[];
  sites?: string[];
}

interface Props {
  filter: FilterObject;
  isAdminOperatorSiteView?: boolean;
}

const SiteWasteRateChart: React.FC<Props> = ({
  filter,
  isAdminOperatorSiteView,
}) => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  const [data, setData] = React.useState<SiteWaste[]>([]);
  const [downloadData, setDownloadData] = React.useState<SiteWasteResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = React.useRef<any>();

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const commonParams = React.useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants?.map((item) =>
          item.id.toString() === '' ? null : item.id
        ) || [],
      tenantNames:
        filter?.tenants?.map((item) => item?.tenantFullName || item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);

  const { triggerFetch: fetchData, loading: dataFetching } = useCallbackLoading(
    {
      callback: (p: typeof commonParams) =>
        commonsApi
          .post<SiteWaste[]>(
            adminOperatorView
              ? API_ENDPOINTS.DASHBOARD_RATE_WASTE_SITES
              : API_ENDPOINTS.DASHBOARD_RATE_WASTE_TENANTS,
            p
          )
          .then((res) => {
            setData(res.data);
          }),
    }
  );

  useEffect(() => {
    fetchData(commonParams);
  }, [commonParams]);

  const { triggerFetch: handleDownloadCSV, loading: csvDownloading } =
    useCallbackLoading({
      callback: () =>
        commonsApi
          .post<SiteWasteResponse>(
            adminOperatorView
              ? API_ENDPOINTS.DASHBOARD_RATE_WASTE_SITES_EXPORT
              : API_ENDPOINTS.DASHBOARD_RATE_WASTE_TENANTS_EXPORT,
            commonParams
          )
          .then((res) => {
            setDownloadData(res.data);
            setTimeout(() => {
              downloadCSVRef.current.link.click();
            }, 500);
          }),
    });

  const otherRate = data
    .slice(6, data.length)
    .reduce((total, file) => total + Number(file.rate), 0);

  const dataLabels = data.slice(0, 6) || [];

  const csvData = [
    // header
    [
      t('dashboard.site_waste_chart.site_name'),
      t('dashboard.site_waste_chart.waste_volume') + '(kg)',
      t('dashboard.site_waste_chart.rate') + '(%)',
    ],
    downloadData?.dateRange
      ? [
          t('dashboard.recycling_rate_csv.date_range'),
          downloadData?.dateRange || '',
        ]
      : [],
    downloadData?.sites && adminOperatorView
      ? [
          t('dashboard.recycling_rate_csv.site_name'),
          downloadData?.sites?.join(', ') || '',
        ]
      : [],
    downloadData?.industries && adminSiteView
      ? [
          t('dashboard.recycling_rate_csv.business_type'),
          downloadData?.industries?.join(', ') || '',
        ]
      : [],
    downloadData?.tenants && adminSiteView
      ? [
          t('dashboard.recycling_rate_csv.tenant_name'),
          downloadData?.tenants?.join(', ') || '',
        ]
      : [],
    downloadData?.tenants && user.role === RoleType.BrandOwner
      ? [
          t('dashboard.recycling_rate_csv.store_name'),
          downloadData?.tenants?.join(', ') || '',
        ]
      : [],
    downloadData?.wasteUnits
      ? [
          t('dashboard.recycling_rate_csv.item'),
          downloadData?.wasteUnits?.join(', ') || '',
        ]
      : [],
    ...((
      downloadData?.rateWasteSites ||
      downloadData?.rateWasteTenants ||
      []
    )?.map((item) => {
      return [
        i18n.language === 'ja'
          ? item?.tenantFullName || item.name
          : item.englishName || item?.tenantFullName || item.name,
        normalizeNumber({ value: item.weight, toFixed: false }),
        Number(item.rate).toFixed(2),
      ];
    }) || []),
  ].filter((data) => data.length);

  return (
    <div className={styles.site}>
      <DonutChart
        title={
          t(
            adminOperatorView
              ? 'dashboard.site_waste_chart.name'
              : adminSiteView
              ? 'dashboard.site_waste_chart.name_tenant'
              : 'dashboard.site_waste_chart.name_store'
          ) || '拠点割合'
        }
        data={[
          ...dataLabels.map((i) => {
            return {
              label:
                i18n.language === 'ja'
                  ? i?.tenantFullName || i.name
                  : i.englishName || i?.tenantFullName || i.name,
              value: Number(Number(i.rate).toFixed(2)),
            };
          }),
          ...(data && data.length > 6
            ? [
                {
                  label: t('dashboard.rate_recycle_method_chart.other'),
                  value: Number(otherRate?.toFixed(2)) || 0,
                },
              ]
            : []),
        ]}
        handleDownloadCSV={handleDownloadCSV}
        emptyMessage={t('dashboard.empty_chart_data') || ''}
        loading={dataFetching || csvDownloading}
      />

      <CSVLink
        className="hidden"
        filename={
          t(
            adminOperatorView
              ? 'dashboard.site_waste_chart.file_csv_name'
              : adminSiteView
              ? 'dashboard.site_waste_chart.file_csv_name_tenant'
              : 'dashboard.site_waste_chart.file_csv_name_store',
            {
              date_time: dayjs().format(DATE_FORMAT.slaYMDHm),
            }
          ) + '.csv'
        }
        data={csvData}
        ref={downloadCSVRef}
      >
        {t('download.volume_reduction_registration')}
      </CSVLink>
    </div>
  );
};

export default SiteWasteRateChart;
