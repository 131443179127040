// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './CompressionDeliveryContent.module.scss';

type Props = {
  contents: string;
};

export const CompressionDeliveryContent = (props: Props) => {
  return (
    <div className={styles.content}>
      <div>：</div>
      <div>{props.contents}</div>
    </div>
  );
};
