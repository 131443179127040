// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { TFunction } from 'i18next';
import { CollectHistory } from './Types';

/**
 * @returns CSV data of deliveries weight and count grouped by tenant
 */
export const getCollectHistoryCSVDataGroupedByTenant = (
  data: CollectHistory,
  datetimeString: string,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const result = [
    [t('histories.label_csv_export_datetime') || '出力日時', datetimeString],
    [
      t('histories.label_csv_export_waste_unit') || '出力対象品目',
      data.wasteUnitName,
    ],
    [
      t('histories.label_csv_tenant_name') || 'テナント名',
      (t('common_history_field.weight') || '重量') + ' (kg)',
      t('histories.label_csv_register_count') || '登録回数',
    ],
  ];

  const byTenantArray = Object.values(data.byTenant).sort(
    (a, b) => Number(a.weight) - Number(b.weight)
  );

  // if there is no relation to tenant data, add a row with empty tenant name that shows the total weight and count
  if (byTenantArray.length === 0) {
    result.push(['', data.weight, data.targetIds.length.toString()]);
    result.push([
      t('common.total'),
      data.weight,
      data.targetIds.length.toString(),
    ]);
    return result;
  }

  let weightSum = 0;
  let countSum = 0;
  for (const tenantData of byTenantArray) {
    result.push([
      tenantData.name,
      tenantData.weight,
      tenantData.count.toString(),
    ]);
    weightSum += Number(tenantData.weight);
    countSum += tenantData.count;
  }

  result.push([t('common.total'), weightSum.toString(), countSum.toString()]);
  return result;
};
