// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import DOMPurify from 'dompurify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

const EmissionConfirmLeavePageModal: React.FC<Props> = ({
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.emissionConfirmLeavePageModal}>
      <Modal>
        <div className={styles.emissionConfirmLeavePageModalContent}>
          <div
            className={styles.emissionConfirmLeavePageModalContentIconClose}
            onClick={onClose}
          >
            <IconClose width={24} height={24} />
          </div>

          <h3 className={styles.emissionConfirmLeavePageModalContentTitle}>
            {t('common.confirm')}
          </h3>

          <p
            className={styles.emissionConfirmLeavePageModalContentDescription}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t('common.confirm_leave_page') ?? ''),
            }}
          />
        </div>

        <div className={styles.emissionConfirmLeavePageModalFooter}>
          <button
            type="submit"
            className={styles.emissionConfirmLeavePageModalFooterSubmitButton}
            onClick={() => onSubmit()}
          >
            {t('common.button.leave_page')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EmissionConfirmLeavePageModal;
