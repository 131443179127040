// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './Login.scss';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { LoginTemplate } from 'components/templates/LoginTemplate';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setUser, resetUser } from 'redux/slices/userSlice';
import {
  setIsCheckingSignin,
  setIsSignin,
  setPathAfterSignin,
  resetPath,
} from 'redux/slices/signinSlice';
import { resetHistory } from 'redux/slices/historySlice';

import { firstPage } from 'routes';
import { useTranslation } from 'react-i18next';
import { TextInputFieldLogin } from 'components/molecules/TextInputFieldLogin';
import { validateRequiredField } from 'util/validator';
import { IconLanguage } from 'components/atoms/icons/IconLanguage';
import { SelectField } from 'components/molecules/SelectField';
import {
  LanguageTypeForm,
  LanguageValue,
  languageTypeData,
} from 'util/ConstantValues';
import i18n from 'i18n';
import { resetSiteInfo } from 'redux/slices/siteInfoSlice';
import { resetCategoryWasteUnit } from 'redux/slices/categoryWasteUnitSlice';
import { DbManager } from 'util/DbManager';
import CookiesAuth from 'util/CookiesAuth';
import { useAuthenLockTime } from 'util/hooks/useAuthenLockTime';

type LoginInput = {
  operatorId: string;
  userId: string;
  password: string;
};

const Login = () => {
  const api = createAxios('login');
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean>(false);
  const langLocalStorage = localStorage.getItem('i18nextLng') as LanguageValue;

  useEffect(() => {
    // アイテムをリセット
    localStorage.removeItem('scale-login');
    CookiesAuth.logout();
    DbManager.userInfo.clear();
    window.nativeUpdateUser(null);

    dispatch(setIsCheckingSignin(false));
    dispatch(setIsSignin(false));
    dispatch(resetSiteInfo());
    dispatch(resetCategoryWasteUnit());
    dispatch(resetUser());
    dispatch(resetHistory());
    dispatch(resetPath());
  }, []);

  const [selectLanguage, setSelectLanguage] = useState<LanguageTypeForm>({
    language: LanguageValue.Japanese,
  });

  useEffect(() => {
    if (langLocalStorage) {
      setSelectLanguage({ language: langLocalStorage });
    } else {
      i18n.changeLanguage(LanguageValue.Japanese);
    }
  }, []);

  const { updateLock, setCheckLockUserId, countdown } = useAuthenLockTime();

  return (
    <LoginTemplate>
      <div className="logo">
        <img src="/assets/images/logo.svg" alt="logo" />
      </div>
      <Form<LoginInput>
        onSubmit={(values) => {
          dispatch(setIsCheckingSignin(true));
          setIsError(false);

          api
            .post(
              '/auth/login',
              {
                userId: values.userId,
                password: values.password,
                operatorId: values.operatorId,
              },
              { method: 'post' }
            )
            .then(function (res) {
              if (res.status == 401) {
                setIsError(true);
                return;
              } else {
                setIsError(false);
                // クッキー設定
                if (res.data.IdToken && res.data.RefreshToken) {
                  CookiesAuth.login({
                    accessToken: res.data.IdToken,
                    refreshToken: res.data.RefreshToken,
                  });
                }

                // クッキー設定後にもう一度axiosのインスタンスを生成する
                const myAccountApi = createAxios('login');
                // ユーザ情報取得を呼ぶ
                myAccountApi.get('/auth/my-account').then((res) => {
                  if (res.status == 401) {
                    setIsError(true);
                    return;
                  } else {
                    const redirect = firstPage(res.data);

                    DbManager.updateUserInfo(res.data);
                    window.nativeUpdateUser({
                      operatorType: res.data.operatorType,
                      role: res.data.role,
                    });

                    dispatch(setIsSignin(true));
                    dispatch(setUser(res.data));
                    dispatch(setPathAfterSignin(redirect));

                    // 初回画面に遷移する
                    history.push(redirect);
                  }
                });
              }
            })
            .catch(function (err) {
              if (err?.response?.data?.endTimeLock) {
                updateLock(values.userId, err.response.data.endTimeLock);

                return;
              }

              setIsError(true);
            });
          dispatch(setIsCheckingSignin(false));
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, invalid, values }) => {
          setCheckLockUserId(values.userId);

          if (countdown) {
            setIsError(false);
          }

          return (
            <form onSubmit={handleSubmit}>
              <div className="form-wraper">
                <TextInputFieldLogin
                  validate={(id) => {
                    return validateRequiredField(
                      id,
                      t('login.business_id') || '事業者ID'
                    )
                      ? (t) =>
                          validateRequiredField(
                            id,
                            t('login.business_id') || '事業者ID'
                          )
                      : undefined;
                  }}
                  label={t('login.business_id') || '事業者ID'}
                  name={'operatorId'}
                  icon="id"
                  placeholder={t('login.business_id') || '事業者ID'}
                />
                <TextInputFieldLogin
                  validate={(id) => {
                    return validateRequiredField(
                      id,
                      t('login.user_id') || 'ユーザーID'
                    )
                      ? (t) =>
                          validateRequiredField(
                            id,
                            t('login.user_id') || 'ユーザーID'
                          )
                      : undefined;
                  }}
                  label={t('login.user_id') || 'ユーザーID'}
                  name={'userId'}
                  icon="user"
                  placeholder={t('login.user_id') || 'ユーザーID'}
                />
                <TextInputFieldLogin
                  label={t('login.password') || 'パスワード'}
                  name={'password'}
                  type={'password'}
                  icon="password"
                  placeholder={t('login.password') || 'パスワード'}
                  validate={(password) => {
                    return validateRequiredField(
                      password,
                      t('login.password') || ''
                    )
                      ? (t) =>
                          validateRequiredField(
                            password,
                            t('login.password') || ''
                          )
                      : undefined;
                  }}
                />

                {isError && (
                  <div className="errors">
                    {t('login.input_error')}
                    <br />
                    {t('login.message_error')}
                  </div>
                )}

                {countdown && (
                  <div className="errors">
                    {t('login.failed_too_many_times')}
                  </div>
                )}

                <button
                  disabled={invalid || !!countdown}
                  type="submit"
                  onClick={handleSubmit}
                  style={{ margin: '16px 0 32px 0' }}
                >
                  {countdown || t('login.button')}
                </button>
                <p>{t('login.notice_1')}</p>
                {selectLanguage.language === LanguageValue.Japanese ? (
                  <p>
                    <a href="mailto:info@recotech.co.jp" target="_blank">
                      info@recotech.co.jp
                    </a>
                    {t('login.notice_2')}
                  </p>
                ) : (
                  <p>
                    {t('login.notice_2')}
                    <a href="mailto:info@recotech.co.jp" target="_blank">
                      info@recotech.co.jp.
                    </a>
                  </p>
                )}
              </div>
            </form>
          );
        }}
      </Form>
      <div className="login-language">
        <Form<LanguageTypeForm>
          onSubmit={() => {}}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={selectLanguage}
        >
          {(props) => {
            const changeLanguage = (value) => {
              value === LanguageValue.Japanese
                ? i18n.changeLanguage(LanguageValue.Japanese)
                : i18n.changeLanguage(LanguageValue.English);
              props.form.mutators.setValue('language', value);
              setSelectLanguage({ language: value });
            };

            return (
              <form onSubmit={props.handleSubmit}>
                <div className="login-selectLanguage">
                  <div className="icon-language">
                    <IconLanguage />
                  </div>

                  <SelectField
                    name="language"
                    placeholder="Language"
                    options={languageTypeData}
                    value={props.values.language}
                    onChange={changeLanguage}
                    isDarkmode
                    isLanguage
                  />
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </LoginTemplate>
  );
};

export default Login;
