// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectTenants, setTenants } from 'redux/slices/siteInfoSlice';
import { selectUser } from 'redux/slices/userSlice';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { DbManager } from 'util/DbManager';
import { ModalType, TenantAuthen } from 'util/Enums';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';
import useGetDevicePlatform from 'util/hooks/useGetDevicePlatform';
import styles from './TenantManagement.module.scss';
import {
  BarcodeInput,
  FloorItem,
  Option,
  SectionItem,
  TenantSiteForm,
  TenantUpdateParams,
} from 'util/Types';
import { mergeClasses } from 'util/commons';
import { selectLoading } from 'redux/slices/loadingSlice';
import TableFilterMultiSelect from 'components/molecules/TableFilterMultiSelect';
import { IconEditIC } from 'components/atoms/icons/IconEditIC';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconPlusCircle } from 'components/atoms/icons/IconPlusCircle';
import {
  TenantIndustry,
  deleteTenant,
  fetchCommonTenantIndustries,
  fetchFloors,
  fetchSections,
  createTenantAdminSite,
  updateTenantAdminSite,
} from 'apis/tenants';
import CreateUpdateTenantSiteModal from 'components/organisms/CreateUpdateTenantSiteModal/CreateUpdateTenantSiteModal';
import DeleteItemDarkModal from 'components/molecules/DeleteItemDarkModal';
import { Tenant } from 'components/molecules/SelectTenantCard';
import { toast } from 'react-toastify';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import BarcodeDetailModal from 'components/molecules/BarcodeDetailModal';
import { FormRenderProps } from 'react-final-form';

export interface TenantCreateEditModalType {
  isOpen: boolean;
  type: ModalType;
  tenantEditing?: Tenant;
}

const TenantManagement: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tenants = useSelector(selectTenants);
  const user = useSelector(selectUser);
  const history = useHistory();
  const device = useGetDevicePlatform();
  const { offlineInitialized } = useSelector(selectLoading);
  const [displayTenants, setDisplayTenants] = useState<Tenant[]>(tenants);
  const [createUpdateModal, setCreateUpdateModal] =
    useState<TenantCreateEditModalType>({
      isOpen: false,
      type: ModalType.add,
    });
  const [deleteItem, setDeleteItem] = useState<Tenant>();
  const { siteId } = useParams<{ siteId: string }>();
  const [sectionList, setSectionList] = useState<SectionItem[]>([]);
  const [floorList, setFloorList] = useState<FloorItem[]>([]);
  const [tenantIndustries, setTenantIndustries] = useState<TenantIndustry[]>(
    []
  );
  const [isShowGenBarcode, setIsShowGenBarcode] = useState(false);
  const [genBarcode, setGenBarcode] = useState<BarcodeInput>();
  const [tenantEditing, setTenantEditing] = useState<Tenant>();
  const [filter, setFilter] = useState<{
    sectionNames?: string[];
    floorNames?: string[];
    keyword?: string;
  }>({});

  const { sectionOptions, floorOptions } = useMemo(() => {
    const sections = new Map<string, Option>();
    const floors = new Map<string, Option>();
    for (const { section, floor } of tenants) {
      if (section) {
        sections.set(section.name, {
          label: section.name,
          value: section.name,
        });
      }

      if (floor) {
        floors.set(floor.name, {
          label: floor.name,
          value: floor.name,
        });
      }
    }

    const sectionOptions = !sections.size
      ? []
      : [
          ...Array.from(sections.values()),
          {
            label: t('tenant_management.no_section'),
            value: '',
          },
        ];
    const floorOptions = !floors.size
      ? []
      : [
          ...Array.from(floors.values()),
          {
            label: t('tenant_management.no_floor'),
            value: '',
          },
        ];

    return {
      sectionOptions,
      floorOptions,
    };
  }, [t, tenants]);

  const fetchTenants = () =>
    createAxios()
      .get(API_ENDPOINTS.TENANTS, {
        params: {
          siteId: user.siteId,
        },
      })
      .then(async (response) => {
        dispatch(setTenants(response.data.tenants));
        setDisplayTenants(response.data.tenants);

        if (offlineInitialized) {
          await DbManager.tenants.clear();
          await DbManager.tenants.bulkAdd(response.data.tenants);
        }
      })
      .catch((error) => handleError(error));

  useEffect(() => {
    fetchTenants();
  }, []);

  useEffect(() => {
    const dataSubset = tenants.filter(
      ({ section, floor, name, contactName, contactEmail }) => {
        const searchKeyword = filter.keyword?.trim().toLowerCase();

        return (
          (!filter.sectionNames?.length ||
            filter.sectionNames.includes(section ? section.name : '')) &&
          (!filter.floorNames?.length ||
            filter.floorNames.includes(floor ? floor.name : '')) &&
          (!searchKeyword ||
            [name, contactEmail, contactName, section?.name, floor?.name].some(
              (e) => e?.toLowerCase().includes(searchKeyword)
            ))
        );
      }
    );

    setDisplayTenants(dataSubset);
  }, [filter, tenants]);

  const fetchListTenantIndustries = () => {
    fetchCommonTenantIndustries((data) => {
      setTenantIndustries(data);
    });
  };

  useEffect(() => {
    fetchSections({ siteId }, (data) => {
      setSectionList(data.sections || []);
    });
    fetchFloors({ siteId }, (data) => {
      setFloorList(data.floors || []);
    });
    fetchListTenantIndustries();
  }, []);

  const handleGoToTenantDashboard = (tenantId: number) => {
    if (device === 'mobile') {
      history.push(`${Path.tenantDashboard}/${tenantId}`);
    } else {
      window.open(`${Path.tenantDashboard}/${tenantId}`, '_blank');
    }
  };

  const handleCreateUpdateTenant = (
    form: TenantSiteForm,
    tenantId?: number
  ) => {
    tenantId
      ? updateTenantAdminSite(form, tenantId, (response) => {
          toast.success(response.data);
          fetchTenants();
          fetchListTenantIndustries();
          setTenantFormModal(false, ModalType.update);
        })
      : createTenantAdminSite(form, (response) => {
          toast.success(response.data);
          fetchTenants();
          fetchListTenantIndustries();
          setTenantFormModal(false, ModalType.add);
        });
  };

  const setTenantFormModal = (open: boolean, type: ModalType) => {
    setCreateUpdateModal({
      isOpen: open,
      type: type,
    });
  };

  const handleDeleteTenant = (tenantId?: number) => {
    deleteTenant(Number(tenantId), (response) => {
      toast.success(response.data);
      fetchTenants();
      fetchListTenantIndustries();
      setDeleteItem(undefined);
    });
  };

  const hanldeGenerateBarcode = (props: FormRenderProps<TenantSiteForm>) => {
    setIsShowGenBarcode(true);

    const tenantId = tenantEditing?.id.toString().padStart(6, '0');
    const barcodeId = '222222' + tenantId;
    setGenBarcode({ barcode: barcodeId });
    props.form.mutators.setValue('barcode', barcodeId);
    toast.success(t('tenant.barcode_generated'));
  };

  const renderTenantAuthenColumn = (item: TenantUpdateParams) => {
    switch (user.originalTenantAuthen) {
      case TenantAuthen.Barcode:
        return (
          <td>
            <p>{item.barcode}</p>
          </td>
        );

      case TenantAuthen.ICCard:
        return (
          <td className={styles.icCardStyles}>
            {item.icCards?.map(({ card }) => (
              <tr className={styles.icCardItem}>
                <td>
                  <p>{card}</p>
                </td>
              </tr>
            ))}
          </td>
        );

      default:
        return null;
    }
  };

  return (
    <PrimaryTemplate h1={t('nav.tenant_management')}>
      <div className={styles.tenantManagement}>
        <div className={styles.title}>
          <div
            className={styles.count}
            dangerouslySetInnerHTML={{
              __html:
                t('common.page', {
                  page: tenants.length,
                }) ?? '',
            }}
          />

          <div className={styles.actionWrapper}>
            <div className={styles.searchBox}>
              <IconSearch />
              <input
                placeholder={t('common.placeholder.search') || ''}
                value={filter.keyword}
                onChange={({ target: { value } }) =>
                  setFilter((prev) => ({
                    ...prev,
                    keyword: value,
                  }))
                }
              />
            </div>

            <button
              className={styles.addButton}
              onClick={() => setTenantFormModal(true, ModalType.add)}
            >
              <div className={styles.iconAdd}>
                <IconPlusCircle />
              </div>
              <span>{t('common.button.add_tenant')}</span>
            </button>
          </div>
        </div>

        <table
          className={mergeClasses(styles.tenantTable, {
            [styles.tenantExpandedTable]:
              user.originalTenantAuthen !== TenantAuthen.None,
          })}
        >
          <thead>
            <tr>
              <th>{t('tenant_management.tenant')}</th>
              <th className={styles.SmallCol}>
                <TableFilterMultiSelect
                  label={t('tenant_management.section')}
                  options={sectionOptions}
                  values={filter?.sectionNames}
                  onChange={(value) =>
                    setFilter((prev) => ({ ...prev, sectionNames: value }))
                  }
                  disabled={!sectionOptions.length}
                />
              </th>
              <th className={styles.SmallCol}>
                <TableFilterMultiSelect
                  label={t('tenant_management.floor')}
                  options={floorOptions}
                  values={filter?.floorNames}
                  onChange={(value) =>
                    setFilter((prev) => ({ ...prev, floorNames: value }))
                  }
                  disabled={!floorOptions.length}
                />
              </th>
              {user.originalTenantAuthen !== TenantAuthen.None && (
                <th>
                  {user.originalTenantAuthen === TenantAuthen.ICCard
                    ? t('tenant_management.IC_card_no')
                    : t('tenant_management.barcode_no')}
                </th>
              )}
              <th>{t('tenant_management.contact_name')}</th>
              <th>{t('tenant_management.contact_mail')}</th>
              <th className={styles.SmallCol}>
                {t('tenant_management.report')}
              </th>
              <th colSpan={2} className={styles.actionField}>
                {t('tare_management.label_action')}
              </th>
            </tr>
          </thead>

          <tbody>
            {displayTenants.length ? (
              displayTenants.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p>{item.name}</p>
                    </td>
                    <td className={styles.SmallCol}>
                      <p>{item.section?.name}</p>
                    </td>
                    <td className={styles.SmallCol}>
                      <p>{item.floor?.name}</p>
                    </td>
                    {renderTenantAuthenColumn(item)}
                    <td>
                      <p>{item.contactName}</p>
                    </td>
                    <td>
                      <p>{item.contactEmail}</p>
                    </td>
                    <td
                      className={mergeClasses(styles.action, styles.SmallCol)}
                    >
                      {item.isDashboardEnabled && (
                        <button
                          className={styles.btnDashboard}
                          type="button"
                          onClick={() => handleGoToTenantDashboard(item.id)}
                        >
                          {t('tenant_management.view')}
                        </button>
                      )}
                    </td>
                    <td className={styles.actionField1}>
                      <div className={styles.contentAction}>
                        <div
                          className={styles.actionEdit}
                          onClick={() => {
                            setCreateUpdateModal({
                              isOpen: true,
                              type: ModalType.update,
                              tenantEditing: item,
                            });
                            setTenantEditing(item);
                            setGenBarcode({ barcode: '' });
                          }}
                        >
                          <IconEditIC />
                        </div>
                      </div>
                    </td>
                    <td className={styles.actionField1}>
                      <div className={styles.contentAction}>
                        <div
                          className={styles.actionDelete}
                          onClick={() => {
                            setDeleteItem(item);
                          }}
                        >
                          <IconDelete />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={9} className={styles.noData}>
                  {t('messages.M_008')}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {createUpdateModal.isOpen && (
          <CreateUpdateTenantSiteModal
            onClose={() => setTenantFormModal(false, ModalType.add)}
            floorList={floorList}
            sectionList={sectionList}
            createUpdateModal={createUpdateModal}
            onSubmit={handleCreateUpdateTenant}
            tenantIndustries={tenantIndustries}
            hanldeGenerateBarcode={hanldeGenerateBarcode}
          />
        )}

        {deleteItem && (
          <DeleteItemDarkModal
            deletingId={deleteItem.id}
            deletingName={deleteItem.name}
            onClose={() => setDeleteItem(undefined)}
            onHandleDelete={handleDeleteTenant}
          />
        )}

        {isShowGenBarcode && (
          <BarcodeDetailModal
            barcode={genBarcode}
            itemName={tenantEditing?.name}
            onClose={() => {
              setIsShowGenBarcode(false);
            }}
            darkMode
          />
        )}
      </div>
    </PrimaryTemplate>
  );
};

export default TenantManagement;
