import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import { mergeClasses } from 'util/commons';
import styles from './SelectTenant.module.scss';
import SelectTenantCard, {
  Tenant,
} from 'components/molecules/SelectTenantCard';
import SelectSectionCard, {
  Section,
} from 'components/molecules/SelectSectionCard';
import { useHistory } from 'react-router';
import { Path } from 'routes';
import { useTranslation } from 'react-i18next';
import SelectFloorCard, { Floor } from 'components/molecules/SelectFloorCard';

type Props = {
  sections: Section[];
  oSections: Section[];
  tenants: Tenant[];
  oTenants: Tenant[];
  selectedSection?: Section;
  onSelectSection: (section: Section) => void;
  selectedFloor?: Floor;
  onSelectFloor: (floor: Floor) => void;
  hasLogo?: boolean;
  showActions?: boolean;
  onSelectTenant: (tenant: Tenant) => void;
  onClickReload?: (force: boolean) => void;
  prev?: () => void;
};

export const SelectTenant = ({
  sections,
  oSections,
  tenants,
  oTenants,
  selectedSection,
  onSelectSection,
  selectedFloor,
  onSelectFloor,
  hasLogo = true,
  showActions = true,
  onSelectTenant,
  onClickReload,
  prev,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <PrimaryTemplate
      hasLogo={hasLogo}
      prevFunction={prev}
      showActions={showActions}
    >
      <div className={styles.container}>
        {showActions && (
          <div className={mergeClasses(styles.skip, styles.skipRight)}>
            <button
              onClick={() => {
                history.push(Path.emissionNew);
              }}
            >
              {t('common.button.sort_category')}
            </button>
            <button
              onClick={() => {
                if (onClickReload) {
                  onClickReload(true);
                }
              }}
            >
              {t('common.button.reload')}
            </button>
          </div>
        )}
        <div className={styles.body}>
          <p className={styles.title}>{t('select_tenant.title')}</p>
          {sections.length > 0 && (
            <>
              <div className={styles.sectionList}>
                {sections?.map((i) => {
                  return (
                    <SelectSectionCard
                      key={i.id}
                      section={i}
                      isSelected={i.id === selectedSection?.id}
                      onSelectSection={onSelectSection}
                    />
                  );
                })}
              </div>

              {selectedSection?.floors &&
              selectedSection?.floors?.length > 0 ? (
                <div className={styles.floorList}>
                  {selectedSection?.floors?.map((i) => {
                    return (
                      <SelectFloorCard
                        key={i.id}
                        floor={i}
                        isSelected={i.id === selectedFloor?.id}
                        onSelectFloor={onSelectFloor}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className={styles.tenant}>
                  <div className={styles.tenantContainer}>
                    {tenants
                      .filter(
                        (item) =>
                          item.section &&
                          item.section.id === selectedSection?.id
                      )
                      .map((i) => {
                        return (
                          <SelectTenantCard
                            onSelectTenant={onSelectTenant}
                            tenant={i}
                            key={i.id}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              <div className={styles.tenant}>
                <div className={styles.tenantContainer}>
                  {selectedFloor?.tenants?.map((i) => {
                    return (
                      <SelectTenantCard
                        onSelectTenant={onSelectTenant}
                        tenant={i}
                        key={i.id}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {sections.length === 0 && oSections.length > 0 && (
            <>
              <div className={styles.sectionList}>
                {oSections?.map((i) => {
                  return (
                    <SelectSectionCard
                      key={i.id}
                      section={i}
                      isSelected={i.id === selectedSection?.id}
                      onSelectSection={onSelectSection}
                    />
                  );
                })}
              </div>
              <div className={styles.tenant}>
                <div className={styles.tenantContainer}>
                  {selectedSection?.tenants?.map((i) => {
                    return (
                      <SelectTenantCard
                        onSelectTenant={onSelectTenant}
                        tenant={i}
                        key={i.id}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {sections.length === 0 && oSections.length === 0 && (
            <div className={styles.tenant}>
              <div className={styles.tenantContainer}>
                {oTenants?.map((i) => {
                  return (
                    <SelectTenantCard
                      onSelectTenant={onSelectTenant}
                      tenant={i}
                      key={i.id}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </PrimaryTemplate>
  );
};
