// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { LoadingModal } from 'components/molecules/LoadingModal';
import { Floor } from 'components/molecules/SelectFloorCard';
import { Section } from 'components/molecules/SelectSectionCard';
import { Tenant } from 'components/molecules/SelectTenantCard';
import TennantAuthenPage from 'components/organisms/TennantAuthen';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLoading } from 'redux/slices/loadingSlice';
import {
  isTenantInfoLoaded,
  selectSectionsWithNoTenant,
  selectSectionsWithTenants,
  selectTenantsWithNoSection,
  selectTenantsWithSections,
  setFloors,
  setForceReloadSection,
  setSections,
  setTenants,
} from 'redux/slices/siteInfoSlice';
import { selectUser } from 'redux/slices/userSlice';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { DbManager } from 'util/DbManager';
import { RoleType, TenantAuthen } from 'util/Enums';
import { handleError } from 'util/errorHandler';
import { SelectTenant } from 'components/organisms/SelectTenant';

type FetchSectionsRes = {
  sections: Section[];
  operatorName: string;
  siteName: string;
};
export type FetchTenantsRes = {
  tenants: Tenant[];
  operatorName: string;
  siteName: string;
};
type FetchFloorsRes = {
  floors: Floor[];
  operatorName: string;
  siteName: string;
};
const SelectSection = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(isTenantInfoLoaded);
  const [selectedSection, setSelectedSection] = React.useState<Section>();
  const [selectedFloor, setSelectedFloor] = React.useState<Floor>();
  const api = createAxios();
  const user = useSelector(selectUser);
  const history = useHistory();
  const { t } = useTranslation();
  const { offlineInitialized } = useSelector(selectLoading);

  React.useEffect(() => {
    if (selectedSection) {
      setSelectedFloor(
        selectedSection.floors ? selectedSection.floors[0] : undefined
      );
    }
  }, [selectedSection]);

  const fetchData = async (force = false) => {
    if (user && !user.isShowTenants) {
      history.push(Path.emissionNew);
      return;
    }

    // リロードボタン押下時または、サイト情報がロードされていない場合
    if (force || !isLoaded) {
      if (!offlineInitialized) {
        return;
      }

      if (!force || !navigator.onLine) {
        const cachedSites = await DbManager.sites.toArray();

        if (cachedSites.length) {
          const [cachedSections, cachedTenants, cachedFloors] =
            await Promise.all([
              DbManager.sections.orderBy('name').toArray(),
              DbManager.tenants.orderBy('name').toArray(),
              DbManager.floors.orderBy('name').toArray(),
            ]);

          dispatch(setSections(cachedSections));
          dispatch(setTenants(cachedTenants));
          dispatch(setFloors(cachedFloors));

          return;
        }
      }

      Promise.all([
        api.get<FetchSectionsRes>(`/sections?siteId=${user.siteId}`),
        api.get<FetchFloorsRes>(`/floors?siteId=${user.siteId}`),
        api.get<FetchTenantsRes>(`/tenants?siteId=${user.siteId}`),
      ])
        .then(async (response) => {
          dispatch(setSections(response[0].data.sections));
          dispatch(setTenants(response[2].data.tenants));
          dispatch(setFloors(response[1].data.floors));
          dispatch(setForceReloadSection(true));

          await Promise.all([
            DbManager.sections.clear(),
            DbManager.tenants.clear(),
            DbManager.floors.clear(),
          ]);

          await Promise.all([
            DbManager.sections.bulkAdd(response[0].data.sections),
            DbManager.tenants.bulkAdd(response[2].data.tenants),
            DbManager.floors.bulkAdd(response[1].data.floors),
          ]);
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };
  React.useEffect(() => {
    if (user && offlineInitialized) {
      fetchData();
    }
  }, [user, offlineInitialized]);

  const sectionsWithTenants = useSelector(selectSectionsWithTenants) || [];
  const sectionsWithNoTenants = useSelector(selectSectionsWithNoTenant) || [];

  React.useEffect(() => {
    if (sectionsWithTenants[0]) {
      setSelectedSection(sectionsWithTenants[0]);
    }
    if (sectionsWithNoTenants[0] && !sectionsWithTenants[0]) {
      setSelectedSection(sectionsWithNoTenants[0]);
    }
  }, [isLoaded]);

  const sections = useSelector(selectSectionsWithTenants);
  const oSections = useSelector(selectSectionsWithNoTenant);

  const tenants = useSelector(selectTenantsWithSections);
  const oTenants = useSelector(selectTenantsWithNoSection);

  if (!offlineInitialized) {
    return (
      <LoadingModal
        zIndex={100000}
        hasCash={true}
        isOpen={!offlineInitialized}
      />
    );
  }

  if (
    user.tenantAuthen &&
    user.tenantAuthen !== TenantAuthen.None &&
    !(
      user.role === RoleType.AdminSite &&
      user.tenantAuthen === TenantAuthen.ICCard
    ) &&
    !user.temporaryAdminSite
  ) {
    return (
      <PrimaryTemplate hasLogo>
        <TennantAuthenPage />
      </PrimaryTemplate>
    );
  }

  return (
    <SelectTenant
      sections={sections}
      oSections={oSections}
      tenants={tenants}
      oTenants={oTenants}
      selectedSection={selectedSection}
      onSelectSection={setSelectedSection}
      selectedFloor={selectedFloor}
      onSelectFloor={setSelectedFloor}
      onClickReload={() => {
        fetchData(true);
      }}
      onSelectTenant={(tenant) => {
        history.push(`${Path.emissionNew}?tenantId=${tenant.id}`);
      }}
    ></SelectTenant>
  );
};
export default SelectSection;
