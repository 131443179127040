// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './RecycleCompleteEditModal.module.scss';
import { Modal } from 'components/atoms/Modal';
import { useEffect, useState, useMemo } from 'react';
import 'react-calendar/dist/Calendar.css';
import { RecycleCompletedEditCard } from 'components/molecules/RecycleCompletedEditCard';
import { Form } from 'react-final-form';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import { createAxios } from 'ts/createAxios';
import { RecycleCompleteData } from 'util/Types';
import { requiredHalfWidthDecimalNumber } from 'util/validator';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'components/atoms/icons/IconClose';
import WeightLimitExceededModal from '../WeightLimitExceededModal';
import { bigNumberCompare } from 'util/commons';

export type DownloadModalProps = {
  id?: string;
  isOpen: boolean;
  closeEditModal: () => void;
  afterSubmit: () => void;
  recycleCompleteData: RecycleCompleteData[];
};

type Options = {
  label: string;
  value: string | number;
};

export const RecycleCompleteEditModal = (props: DownloadModalProps) => {
  const api = createAxios();
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const [fetchData, setFetchData] = useState<RecycleCompleteData[]>(
    props.recycleCompleteData
  );
  const [overloadModal, setOverloadModal] = useState<boolean>(false);
  const [updateRecords, setUpdateRecords] = useState([]);

  const openOverload = (records) => {
    setUpdateRecords(records);
    setOverloadModal(true);
  };

  const closeOverload = () => setOverloadModal(false);
  const [grades, setGrades] = useState<Options[]>([]);
  const [plasticTypes, setPlasticTypes] = useState<Options[]>([]);

  const initialData = useMemo(() => {
    return fetchData.map((m) => {
      return {
        ...m,
        quantity: m.weight,
      };
    });
  }, [fetchData]);

  const getInputUnit = (wasteUnit) => {
    if (wasteUnit && wasteUnit['inputUnit']) {
      return wasteUnit['inputUnit'];
    }
    return 'kg';
  };

  const callApi = async () => {
    // 事業者に紐づくプラスチックタイプ及び種別を取得する
    commonsApi.get('/commons/grades').then((res) => {
      setGrades(
        res.data.map((v: { id: number; name: string }) => {
          return {
            label: v.name,
            value: v.id,
          };
        })
      );
    });
    commonsApi.get('/commons/material-types').then((res) => {
      setPlasticTypes(
        res.data.map((v: { id: number; name: string }) => {
          return {
            label: v.name,
            value: v.id,
          };
        })
      );
    });
  };

  useEffect(() => {
    if (props.recycleCompleteData !== fetchData) {
      setFetchData(props.recycleCompleteData);
    }
  }, [props.recycleCompleteData]);

  useEffect(() => {
    callApi();
  }, []);

  const onSubmit = (values) => {
    const records = values.inputs.reduce((rs, data, index) => {
      const originalData = props.recycleCompleteData[index];

      if (
        data.quantity !== originalData.weight ||
        +fetchData[index].gradeId !== +originalData.gradeId ||
        +fetchData[index].materialTypeId !== +originalData.materialTypeId
      ) {
        return [
          ...rs,
          {
            recycleId: data.id,
            weight: data.quantity,
            gradeId: +fetchData[index].gradeId,
            materialTypeId: +fetchData[index].materialTypeId,
            totalInputWeight: fetchData[index].totalInputWeight,
          },
        ];
      }

      return rs;
    }, []);

    if (!records.length) {
      props.afterSubmit();
      props.closeEditModal();
      toast.success(t('messages.M_011'));

      return;
    }

    const isOverload = records.some((rec) =>
      bigNumberCompare(rec.weight, rec.totalInputWeight)
    );

    isOverload ? openOverload(records) : onUpdate(records);
  };

  const handleExceededSubmit = () => {
    onUpdate(updateRecords).then(() => closeOverload());
  };

  const onUpdate = async (records) =>
    api
      .put('/recycle', { recycle: records })
      .then(() => {
        props.afterSubmit();
        toast.success(t('messages.M_011'));
        setUpdateRecords([]);
        props.closeEditModal();
      })
      .catch(() => {
        toast.error(t('recycle.signup_messages.failed'));
      });

  return (
    <>
      <section
        className={`${styles.downloadModal} ${
          props.isOpen ? styles.open : styles.close
        }`}
      >
        <Modal>
          <div className={styles.modalContent}>
            <div className={styles.customTitle}>
              <p className={styles.descriptionTitle}>
                {t('common.button.edit_product_data')}
              </p>
              <div className={styles.top}>
                <IconClose onClick={props.closeEditModal} />
              </div>
            </div>
          </div>
          <div className={styles.body}>
            <Form
              mutators={{
                ...arrayMutators,
              }}
              initialValues={{
                inputs: initialData,
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, valid }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={styles.bodyContent}>
                      <FieldArray name="inputs">
                        {({ fields }) =>
                          fields.map((name, i) => (
                            <RecycleCompletedEditCard
                              key={i}
                              date={fetchData[i].datetime}
                              label={fetchData[i].description}
                              grade={fetchData[i].gradeId}
                              totalInputWeight={fetchData[i].totalInputWeight}
                              options={grades}
                              recycleId={fetchData[i].id}
                              unit={getInputUnit(fetchData[i].description)}
                              name={`${name}.quantity`}
                              validate={(value) =>
                                requiredHalfWidthDecimalNumber(
                                  value,
                                  t('common.weight')
                                )
                              }
                              onChange={(e) => {
                                setFetchData((prev) =>
                                  prev.map((val, index) =>
                                    index === i
                                      ? { ...val, weight: e.target.value }
                                      : val
                                  )
                                );
                              }}
                              onChangeSelectList={(e) => {
                                setFetchData(
                                  fetchData.map((item) =>
                                    item.id === fetchData[i].id
                                      ? { ...item, gradeId: +e }
                                      : item
                                  )
                                );
                              }}
                              value={initialData[i].quantity}
                              plasticType={fetchData[i].materialTypeId}
                              plasticTypeOptions={plasticTypes}
                              onChangePlasticTypeSelectList={(e) => {
                                setFetchData(
                                  fetchData.map((item) =>
                                    item.id === fetchData[i].id
                                      ? {
                                          ...item,
                                          materialTypeId: +e,
                                        }
                                      : item
                                  )
                                );
                              }}
                              isOverload={bigNumberCompare(
                                fetchData[i].weight,
                                fetchData[i].totalInputWeight
                              )}
                            />
                          ))
                        }
                      </FieldArray>
                    </div>
                    <div className={'modal-bottom-field'}>
                      <PrimaryBtnField
                        className={styles.submitButton}
                        text={t('common.button.reflect_the_correction')}
                        disabled={!valid}
                      />
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </Modal>
      </section>
      <WeightLimitExceededModal
        open={overloadModal}
        onSubmit={handleExceededSubmit}
        onClose={closeOverload}
        isDarkmode
      />
    </>
  );
};
