// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { InputDataListField } from '../InputDataListField';
import styles from './index.module.scss';
import { ResourceManagementItem } from 'util/adminResourceManagementTypes';
import { SelectField, SelectOption } from '../SelectField';
import {
  validateInputWeight,
  validateSelectField,
  validateStringField,
} from 'util/validator';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';
import { ItemType } from 'util/Types';
import { REGEX_OBJECT } from 'util/ConstantValues';

interface Props {
  initForm?: ResourceManagementItem;
  onSubmit: (resourceForm: ResourceManagementItem) => void;
  categories: SelectOption[];
}

const EditEmissionResourceForm: React.FC<Props> = ({
  initForm,
  onSubmit,
  categories,
}) => {
  const api = createAxios();
  const [wasteUnitIds, setWasteUnitIds] = useState<SelectOption[]>([]);

  const getWasteUnitIdByCategory = async (categoryId: number) => {
    try {
      const response = await api.get(`/waste-units?categoryId=${categoryId}`);
      setWasteUnitIds(
        response.data.wasteUnits.map((item) => {
          return {
            label: item.name,
            value: item.id,
            type: item.type,
          };
        })
      );
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    if (initForm?.categoryId) {
      getWasteUnitIdByCategory(Number(initForm.categoryId));
    }
  }, [initForm]);

  return (
    <div className={styles.createBody}>
      <Form<ResourceManagementItem>
        onSubmit={onSubmit}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initForm}
        validate={(values) => {
          return {
            datetime: validateStringField('日付', 100, values.datetime),
            categoryId: validateSelectField('カテゴリ', values.categoryId),
            wasteUnitId: validateSelectField('品目', values.wasteUnitId),
            weight: validateInputWeight('重量', 10, Number(values.weight)),
          };
        }}
      >
        {(props) => {
          const isOptionalWasteUnit =
            wasteUnitIds.find(
              (i) => Number(i.value) === Number(props.values.wasteUnitId)
            )?.type === ItemType.OPTIONAL;

          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <InputDataListField
                  label={'事業者名'}
                  placeholder={'事業者名'}
                  name="operatorName"
                  disabled
                />
                <InputDataListField
                  label={'拠点'}
                  placeholder={'拠点'}
                  name="siteName"
                  disabled
                />
                <InputDataListField
                  label={'日付'}
                  placeholder={'日付'}
                  name="datetime"
                  type="datetime-local"
                  required
                />
                <SelectField
                  label={'カテゴリ'}
                  placeholder={'カテゴリ'}
                  name="categoryId"
                  onChange={(value) => {
                    props.form.mutators.setValue('categoryId', value);
                    getWasteUnitIdByCategory(Number(value));
                    props.form.mutators.setValue('weight', '');
                    props.form.mutators.setValue('wasteUnitId', '');
                  }}
                  options={
                    initForm &&
                    initForm.category &&
                    !categories.find((i) => i.value === initForm.category.id)
                      ? [
                          ...categories,
                          {
                            label: initForm.category.name,
                            value: initForm.category.id,
                          },
                        ]
                      : categories
                  }
                  required
                />
                <SelectField
                  required
                  label={'品目'}
                  placeholder={'品目'}
                  name="wasteUnitId"
                  onChange={() => {
                    props.form.mutators.setValue('weight', '');
                  }}
                  options={wasteUnitIds || []}
                />
                <InputDataListField
                  required
                  label={'重量・数量'}
                  placeholder={'重量・数量'}
                  name="weight"
                  validRegex={
                    isOptionalWasteUnit
                      ? REGEX_OBJECT.fiveDigitsWith2Decimal
                      : REGEX_OBJECT.numberWithDot2Decimal
                  }
                  maxlength={10}
                  subFix={
                    isOptionalWasteUnit ? (
                      <span className={styles.weightUnit}>kg</span>
                    ) : (
                      <span></span>
                    )
                  }
                />
              </div>
              <div className={styles.submit}>
                <button
                  className={`${styles.submitButton} ${
                    props.invalid ? styles.disableButton : ''
                  }`}
                  disabled={props.invalid}
                  type="submit"
                >
                  {'保存する'}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
export default EditEmissionResourceForm;
