// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import Portal from 'components/atoms/Portal';
import { IconDropDown } from 'components/atoms/icons/IconDropDown';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { getFormatDate } from 'ts/formatDate';
import { mergeClasses } from 'util/commons';
import useOutsideClick from 'util/hooks/useClickOutside';
import { useGetBoundingClientRect } from 'util/hooks/useGetBoundingClientRect';
import './Calendar.scss';
import styles from './index.module.scss';
import TableFilterExpandedValue from 'components/atoms/TableFilterExpandedValue';
import { DefaultTFuncReturn } from 'i18next';
import { toast } from 'react-toastify';

type TableFilterCalendarProps = {
  label?: string | DefaultTFuncReturn;
  value?: [string, string];
  onChange?: (value: [string, string] | undefined) => void;
  allowClear?: boolean;
  disabled?: boolean;
  expanded?:
    | boolean
    | {
        placeholder?: string | DefaultTFuncReturn;
        icon?: React.ReactNode;
      };
  centered?: boolean;
  fullWidth?: boolean;
  calendarWrapperStyle?: React.CSSProperties;
  containerClassName?: string;
  portalPositionPoint?: (
    ref: HTMLDivElement | null
  ) => HTMLDivElement | null | undefined;
  chosenDatesLimit?: {
    limit: number;
    required?: boolean;
  };
  endDateRequired?: boolean;
};

const TableFilterCalendar: React.FC<TableFilterCalendarProps> = ({
  label,
  value,
  onChange,
  allowClear,
  disabled,
  expanded,
  centered,
  fullWidth,
  calendarWrapperStyle,
  containerClassName,
  portalPositionPoint,
  chosenDatesLimit,
  endDateRequired,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [lastSelected, setLastSelected] = useState<string>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [displayValue, setDisplayValue] = useState(value);

  const handleChange = (value: [string, string] | undefined) => {
    setDisplayValue(value);

    if (!endDateRequired || (value?.[0] && value?.[1]) || !value) {
      onChange?.(value);
    }
  };

  useEffect(() => {
    setDisplayValue(value);
  }, [open]);

  const { ref: dropdownRef } = useOutsideClick({
    onClickOutside: () => {
      setOpen(false);
    },
    exceptRefs: [containerRef],
  });

  const { domRect } = useGetBoundingClientRect(
    {
      el: portalPositionPoint
        ? portalPositionPoint(containerRef.current)
        : containerRef.current?.parentElement,
    },
    [open]
  );

  useEffect(() => {
    if (!open) {
      setLastSelected(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (chosenDatesLimit?.required && !open) {
      if (displayValue?.[0] && !displayValue[1]) {
        const endDate = dayjs(displayValue[0]).add(
          chosenDatesLimit.limit,
          'days'
        );

        handleChange?.([
          displayValue[0],
          endDate.isAfter(dayjs(), 'D')
            ? dayjs().format('YYYY-MM-DD')
            : endDate.format('YYYY-MM-DD'),
        ]);
      } else if (displayValue?.[1] && !displayValue?.[0]) {
        handleChange?.([
          dayjs(displayValue[1])
            .subtract(chosenDatesLimit.limit, 'days')
            .format('YYYY-MM-DD'),
          displayValue[1],
        ]);
      }
    }
  }, [open, chosenDatesLimit]);

  if (disabled) {
    return <>{label}</>;
  }

  const changeDate = (selectedDate: [string, string]) => {
    if (chosenDatesLimit && lastSelected) {
      const [startDate, endDate] = selectedDate;

      if (startDate && endDate) {
        const diff = dayjs(endDate).diff(startDate, 'days');

        if (diff > chosenDatesLimit.limit) {
          toast.info(
            t('common.alert.date_limit_exceeded', {
              value: chosenDatesLimit.limit,
            })
          );

          if (dayjs(startDate).isSame(dayjs(lastSelected), 'days')) {
            handleChange?.([
              startDate,
              dayjs(startDate)
                .add(chosenDatesLimit.limit, 'days')
                .format('YYYY-MM-DD'),
            ]);
          } else {
            handleChange?.([
              dayjs(endDate)
                .subtract(chosenDatesLimit.limit, 'days')
                .format('YYYY-MM-DD'),
              endDate,
            ]);
          }
          setOpen(false);

          return;
        }
      }
    }

    if (!selectedDate[1]) {
      setLastSelected(selectedDate[0]);
    } else {
      setLastSelected(selectedDate[1]);
    }

    handleChange?.(selectedDate);
    if (selectedDate[0] && selectedDate[1]) {
      setOpen(false);
    }
  };

  const renderActionButton = () => {
    if (expanded) {
      return (
        <TableFilterExpandedValue
          value={`${
            displayValue?.[0]
              ? getFormatDate(displayValue[0], 'YYYY/MM/DD')
              : ''
          } - ${
            displayValue?.[1]
              ? getFormatDate(displayValue[1], 'YYYY/MM/DD')
              : ''
          }`}
          valueCondition={Boolean(
            displayValue?.length && displayValue.some(Boolean)
          )}
          clearCondition={Boolean(
            displayValue?.length && displayValue.some(Boolean)
          )}
          allowClear={allowClear}
          onChange={handleChange}
          {...(expanded !== true && { ...expanded })}
        />
      );
    }

    return <IconDropDown color={value ? '#86EA5C' : '#B7B7B7'} />;
  };

  return (
    <div ref={containerRef}>
      <div
        onClick={() => setOpen(!open)}
        className={mergeClasses(styles.labelContainer, containerClassName, {
          [styles.labelExpanded]: expanded,
        })}
      >
        {label && <span className={styles.label}>{label}</span>}
        {renderActionButton()}
      </div>
      {open && domRect && (
        <Portal>
          <div
            ref={dropdownRef}
            className={mergeClasses(styles.dropdown, 'calendar-tablet', {
              [styles.dropdownCentered]: centered,
            })}
            style={{
              top: domRect.top + domRect.height + 4,
              left: domRect.left,
              ...calendarWrapperStyle,
              ...(fullWidth && { width: domRect.width }),
            }}
          >
            <Calendar
              value={[
                displayValue?.[0] ? dayjs(displayValue[0]) : null,
                displayValue?.[1] ? dayjs(displayValue[1]) : null,
              ]}
              calendarType="US"
              locale={localStorage.getItem('i18nextLng') || 'ja'}
              prev2Label={null}
              next2Label={null}
              prevLabel="◀"
              nextLabel="▶"
              maxDate={new Date()}
              formatDay={(_, date: string) => getFormatDate(date, 'D')}
              onChange={changeDate}
              className={mergeClasses('react-calendar-tablet', {
                'full-width': fullWidth,
              })}
              allowPartialRange
              selectRange
            />
            {!expanded && allowClear && Boolean(displayValue?.length) && (
              <div
                onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleChange?.(undefined);
                }}
                className={styles.clear}
              >
                {t('common.button.clear')}
              </div>
            )}
          </div>
        </Portal>
      )}
    </div>
  );
};

TableFilterCalendar.defaultProps = {
  allowClear: true,
};

export default TableFilterCalendar;
