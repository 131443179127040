// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { FieldValidator } from 'final-form';
import i18n from 'i18n';
import { MAX_CSV_FILE_SIZE, REGEX_OBJECT } from './ConstantValues';
import { ItemType } from './Types';
import { objectFetch } from './commons';
import dayjs, { Dayjs, UnitTypeLongPlural } from 'dayjs';

// *-----------------* Common *-----------------*

export const requiredHalfWidthDecimalNumber = (
  value: string,
  field: string
) => {
  if (!value || value.length === 0)
    return i18n.t('messages.old_empty') || '必須です';
  const regexp = /^\d*\.?\d*$/;
  if (!regexp.test(value)) {
    return (
      i18n.t('messages.old_half_width_only') || '半角数字で入力してください'
    );
  }
  if (!REGEX_OBJECT.numberWithDot2Decimal.test(value)) {
    return i18n.t('messages.M_009', { value: field }) || '';
  }
  return undefined;
};

export const validateMaxLength = (
  value: string,
  maxLength: number,
  fieldName: string
) => {
  if (value && value.length > maxLength)
    return i18n.t('messages.M_002', {
      fieldName: fieldName,
      maxLength: maxLength,
    });
  return undefined;
};

export const validateLimitNumber = (value: number, maxNumber: number) => {
  if (value > maxNumber) {
    return `最大で${maxNumber}を入力してください。`;
  }

  return undefined;
};

export const validateMinLength = (value: string, minLength: number) => {
  if (value && value.length < minLength)
    return `${minLength}文字以上で入力してください。`;
  return undefined;
};

export const validateInputEmissionNumber = (value: string, type?: ItemType) => {
  if (!value) {
    return i18n.t('messages.M_007_1');
  }
  const regexp =
    type === ItemType.FIX
      ? REGEX_OBJECT.number
      : REGEX_OBJECT.fiveDigitsWith2Decimal;
  if (!regexp.test(value) || !value) {
    return i18n.t('messages.M_042');
  }
  return undefined;
};

// Only A-z, a-z,  and number
export const validateId = (field: string, maxLength: number, value: string) => {
  if (!value?.trim()) {
    return i18n.t('messages.M_007', { field: field });
  }
  if (!REGEX_OBJECT.numberStringOnly.test(value)) {
    return i18n.t('messages.M_034', { field: field });
  }
  if (value) {
    return validateMaxLength(value.trim(), maxLength, field);
  }
  return undefined;
};

export const validateStringField = (
  field: string,
  maxLength: number,
  value?: string
) => {
  if (!value?.trim()) {
    return i18n.t('messages.M_007', { field: field });
  }
  if (value) {
    return validateMaxLength(value.trim(), maxLength, field);
  }
  return undefined;
};

export const validateNumberField = (
  field: string,
  maxLength: number,
  value?: number | string,
  zeroFirst?: boolean
) => {
  const stringValue = typeof value === 'string' ? value : String(value) ?? '';
  if (!value || stringValue === '0' || +value < 0) {
    return i18n.t('messages.M_007', { field: field });
  }

  const regexp = zeroFirst
    ? REGEX_OBJECT.number
    : REGEX_OBJECT.numberWithDot2Decimal;
  if (!regexp.test(stringValue)) {
    return i18n.t('messages.M_009', { value: field });
  }
  if (value) {
    return validateMaxLength(stringValue, maxLength, field);
  }
  return undefined;
};

export const validateInputWeight = (
  field: string,
  maxLength: number,
  value?: number
) => {
  if (!value || value < 0) {
    return i18n.t('messages.M_007', { field: field });
  }
  if (!REGEX_OBJECT.numberWithDot2Decimal.test(String(value))) {
    return i18n.t('messages.M_009', { value: field });
  }
  if (value) {
    return validateMaxLength(String(value), maxLength, field);
  }
  return undefined;
};

export const validateEmailAddress: FieldValidator<string> = (
  value
): string | undefined => {
  if (!value) {
    return i18n.t('messages.M_007', { field: i18n.t('common.email') }) || '';
  }
  if (value && !REGEX_OBJECT.email.test(value)) {
    return i18n.t('messages.M_invalid_email') || '';
  }
  return undefined;
};

export const validateInputNumber = (value: string) => {
  const regexp = /^\d+$/;
  if (!regexp.test(value) || !value) {
    return i18n.t('messages.M_035');
  }
  return undefined;
};

export const validateEmailAddressNotRequired: FieldValidator<string> = (
  value
): string | undefined => {
  /* eslint-disable no-useless-escape */
  if (value && !REGEX_OBJECT.email.test(value)) {
    return i18n.t('messages.M_invalid_email') || '';
  }
  return undefined;
};

export const validateRequiredField = (value?: unknown, field?: string) => {
  if (
    value == null ||
    (typeof value === 'string' && !value.trim()) ||
    (Array.isArray(value) && !value.length) ||
    (typeof value === 'object' &&
      !Array.isArray(value) &&
      !Object.keys(value).length) ||
    (typeof value === 'number' && Number.isNaN(value))
  ) {
    return i18n.t('messages.M_007', { field: field });
  }

  return undefined;
};

export const validateSiteAddressesField = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any[],
  field: string,
  max: number
) => {
  if (value && value.length > 0 && value.length < max) {
    return undefined;
  }
  if (value.length <= 0) {
    return i18n.t('messages.M_007', { field: field });
  }
  if (value.length > max) {
    return `都道府県は${max}個まで登録できます。`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateRequiredSelectField = (value: any[], field: string) =>
  value && value.length > 0
    ? undefined
    : i18n.t('messages.M_007', { field: field });

export const validatePasswordField = (
  field: string,
  minLength: number,
  value?: string
) => {
  if (!value?.trim()) {
    return i18n.t('messages.M_007', { field: field });
  }
  if (!REGEX_OBJECT.password.test(value)) {
    return i18n.t('messages.M_077');
  }
  if (value) {
    return validateMinLength(value.trim(), minLength);
  }
  return undefined;
};

export const isValidTime = (
  field: string,
  value: string,
  required?: boolean
) => {
  if (!value) {
    return undefined;
  }
  const times = value.split(':');
  if (!required && !times[0] && !times[1]) {
    return undefined;
  }
  if (
    !times[0] ||
    !times[1] ||
    Number(times[0]) > 23 ||
    Number(times[1]) > 59
  ) {
    return field + 'の形式が正しくありません。';
  }
};
export const validateSelectField = (
  field: string,
  value?: string | number | null
) => {
  if (!String(value) || value == null) {
    return i18n.t('messages.M_select_required', { field: field });
  }
  return undefined;
};

export const validateSpecialCharactersWithJapanese = (
  field: string,
  maxLength: number,
  value: string
) => {
  if (!value?.trim()) {
    return i18n.t('messages.M_007', { field: field });
  }
  if (!REGEX_OBJECT.numberStringWithJapanese.test(value)) {
    return i18n.t('messages.M_039');
  }
  if (value) {
    return validateMaxLength(value.trim(), maxLength, field);
  }
  return undefined;
};

export const validatePhoneNumber = (
  field: string,
  maxLength: number,
  value?: string | number
) => {
  if (!value) {
    return i18n.t('messages.M_007', { field: field });
  }
  if (!REGEX_OBJECT.number.test(String(value)) || +value < 0) {
    return i18n.t('messages.M_037');
  }
  if (value) {
    return validateMaxLength(String(value), maxLength, field);
  }
  return undefined;
};

export const validatePriceField = (
  field: string,
  maxLength: number,
  value?: number
) => {
  if (!value && String(value) !== '0') {
    return i18n.t('messages.M_007', { field: field });
  }
  if (!/^\d+$/.test(String(value))) {
    return i18n.t('messages.M_009', {
      value: field,
    });
  }
  if (value) {
    return validateMaxLength(String(value), maxLength, field);
  }
  return undefined;
};

export const validatePostCodeForm = <T>(values: T) => {
  return {
    province: validateStringField(
      i18n.t('site_management.modal.field_province'),
      100,
      objectFetch(values, 'province')
    ),
    district: validateStringField(
      i18n.t('site_management.modal.field_district'),
      100,
      objectFetch(values, 'district')
    ),
    address: validateStringField(
      i18n.t('site_management.modal.field_address'),
      200,
      objectFetch(values, 'address')
    ),
    longitude: validateRequiredField(
      objectFetch(values, 'longitude'),
      i18n.t('site_management.modal.field_longitude') || ''
    ),
    latitude: validateRequiredField(
      objectFetch(values, 'latitude'),
      i18n.t('site_management.modal.field_latitude') || ''
    ),
  };
};

export const validateFiles = ({
  files,
  fileTypes = ['csv'],
  maxSize = MAX_CSV_FILE_SIZE,
  errorMessages,
}: {
  files: FileList;
  fileTypes?: string[];
  maxSize?: number;
  errorMessages?: {
    wrongType?: string | null;
    sizeExceeded?: string | null;
  };
}) => {
  for (const file of Array.from(files)) {
    const size = Number(file.size / (1024 * 1024)).toFixed(2);
    const fileType = file.name.split('.').pop()?.toLowerCase() || '';

    if (!fileTypes.includes(fileType)) {
      return errorMessages?.wrongType || i18n.t('messages.M_033');
    }

    if (Number(size) > maxSize) {
      return errorMessages?.sizeExceeded || i18n.t('messages.M_024');
    }
  }

  return undefined;
};

export const validateDateTime = (params: {
  compareValue: Dayjs;
  targetValue?: Date;
  timeUnit?: UnitTypeLongPlural;
}) => {
  const diff = params.compareValue.diff(
    params.targetValue ?? dayjs(),
    params.timeUnit ?? 'milliseconds'
  );

  if (diff < 0) {
    return i18n.t('purchase_page.resource.error.time');
  }
};

export const validateFieldEN = (
  field: string,
  value: string | string[],
  maxLength?: number
) => {
  const requiredCheck = validateRequiredField(value, field);

  if (requiredCheck) {
    return requiredCheck;
  }

  if (
    (typeof value === 'string' &&
      !REGEX_OBJECT.halfWidthCharacter.test(value)) ||
    (typeof value === 'object' &&
      value.some((e) => !REGEX_OBJECT.halfWidthCharacter.test(e)))
  ) {
    return i18n.t('messages.M_validate_EN', { field: field });
  }

  if (maxLength != null && typeof value === 'string') {
    return validateMaxLength(value.trim(), maxLength, field);
  }

  return undefined;
};

export const validateMaxMinValue = (params: {
  value: number | string;
  field: string;
  checkRequired: boolean;
  maxValue?: number;
  minValue?: number;
}) => {
  const { value, field, maxValue, minValue, checkRequired } = params;

  if (maxValue == null && minValue == null) return undefined;

  if (checkRequired) {
    const requiredCheck = validateRequiredField(value, field);

    if (requiredCheck) {
      return requiredCheck;
    }
  }

  const numericValue = Number(value);

  if (!isNaN(numericValue)) {
    if (numericValue < Number(minValue)) {
      return i18n.t('messages.M_139', {
        minValue,
        fieldName: field,
      });
    }
    if (numericValue > Number(maxValue)) {
      return i18n.t('messages.M_138', {
        maxValue,
      });
    }
  }

  return undefined;
};

export const validateExactLength = (params: {
  value?: string;
  length: number;
}) => {
  const { value, length } = params;

  if (value && value.length !== length) {
    return i18n.t('messages.M_140', {
      value: length,
    });
  }

  return undefined;
};
