// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { ErrorText } from 'components/atoms/ErrorText';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT, REGEX_OBJECT } from 'util/ConstantValues';
import { SelectField } from '../SelectField';
import styles from './RecycleCompletedEditCard.module.scss';

export type Props<T> = {
  id?: string;
  date: string;
  label: string;
  grade: string | number;
  totalInputWeight: string;
  options: { label: string; value: T }[];
  name: string;
  value: string;
  changeGrade?: string;
  placeholder?: string;
  suffix?: string;
  readOnly?: boolean;
  disabled?: boolean;
  validate?: FieldValidator<string>;
  recycleId?: string;
  unit: string;
  disableAutoComplete?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSelectList?: (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void | undefined;
  plasticType: string | number;
  plasticTypeOptions: { label: string; value: T }[];
  changePlasticType?: string;
  onChangePlasticTypeSelectList?: (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void | undefined;
  isOverload?: boolean;
};

export function RecycleCompletedEditCard<T extends string | number>({
  date,
  label,
  grade,
  totalInputWeight,
  options,
  name,
  placeholder,
  readOnly,
  disabled,
  validate,
  recycleId,
  unit,
  disableAutoComplete,
  onChange,
  onChangeSelectList,
  plasticType,
  plasticTypeOptions,
  onChangePlasticTypeSelectList,
  isOverload = false,
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <Field<string> name={name} type={recycleId ?? 'text'} validate={validate}>
      {({ input, meta }) => (
        <div className={styles.recycleEdit}>
          <div className={styles.recycleDate}>
            {getFormatDateWithCurrentTimezone(date, DATE_FORMAT.slaMDHm)}
          </div>
          <div className={styles.recycleType}>{recycleId}</div>
          <div className={styles.inputLabel}>
            <label>{label}</label>
          </div>

          <div className={styles.selectLabel}>
            <SelectField
              value={plasticType.toString()}
              disabled={disabled}
              options={plasticTypeOptions.map((i) => {
                return { label: i.label, value: i.value };
              })}
              name={`${name}.plasticType`}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                onChangePlasticTypeSelectList &&
                  onChangePlasticTypeSelectList(e);
              }}
              isDarkmode
            />
          </div>

          <div className={styles.selectLabel}>
            <SelectField
              value={grade.toString()}
              disabled={disabled}
              options={options.map((i) => {
                return { label: i.label, value: i.value };
              })}
              name={`${name}.grade`}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                onChangeSelectList && onChangeSelectList(e);
              }}
              isDarkmode
            />
          </div>

          <div className={styles.inputNum}>
            <input
              {...input}
              type={recycleId ?? 'text'}
              name={name}
              placeholder={placeholder}
              readOnly={readOnly}
              disabled={disabled}
              autoComplete={disableAutoComplete ? 'off' : 'on'}
              onChange={(e) => {
                if (
                  e.target.value &&
                  !REGEX_OBJECT.tenDigitsWith2Decimal.test(e.target.value)
                )
                  return;

                input?.onChange(e);
                onChange && onChange(e);
              }}
              className={meta.error && meta.touched ? 'error' : ''}
              title=""
            />
            <span className={styles.weightUnit}>
              {t('common.input_resources', {
                weight: totalInputWeight,
                unit: unit,
              })}
            </span>
          </div>
          {isOverload && (
            <div className={styles.inputNum}>
              <span className={styles.overload}>
                {t('emission_input.weight_over_total')}
              </span>
            </div>
          )}
          {meta.error && meta.touched && (
            <ErrorText text={meta.error} className="error" />
          )}
        </div>
      )}
    </Field>
  );
}
