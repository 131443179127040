// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React from 'react';
import './LabelContents.scss';
import { mergeClasses } from 'util/commons';

export type Props = {
  label: string;
  contents?: React.ReactNode | string | null;
  hideOnEmpty?: boolean;
};

export const LabelContents = ({ label, hideOnEmpty, contents }: Props) => {
  if (hideOnEmpty && !contents) {
    return null;
  }

  return (
    <div className="label-contents">
      <div className="title">{label}</div>
      <div>：</div>

      <div
        className={mergeClasses({
          contents: !React.isValidElement(contents),
        })}
      >
        {contents}
      </div>
    </div>
  );
};
