// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getTotalUnassignedBrands } from 'apis/brands';
import { fetchBrands } from 'apis/tenants';
import TableLoading from 'components/atoms/TableLoading';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import { BrandInput } from 'components/molecules/CreateBrandItemInputForm';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import BrandRowItem from 'components/organisms/BrandRowItem';
import CreateBrandItemModal from 'components/organisms/CreateBrandItemModal';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import DOMPurify from 'dompurify';
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createAxios } from 'ts/createAxios';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { ModalType } from 'util/Enums';
import { BrandItem } from 'util/Types';
import { handleError } from 'util/errorHandler';
import styles from './index.module.scss';

export interface ModalBrandState {
  isOpen: boolean;
  type: ModalType;
  brandEditing?: BrandItem;
  operatorId?: string;
}

const AdminBrandOwnerManagement = () => {
  const api = createAxios();
  const [brandList, setBrandList] = useState<BrandItem[]>([]);
  const [deleteBrand, setDeleteBrand] = useState<BrandItem | undefined>();
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [createUpdateBrandModal, setCreateUpdateBrandModal] =
    useState<ModalBrandState>({
      isOpen: false,
      type: ModalType.add,
    });
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);

  const fetchBrandList = (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    const params = {
      ...(nextPage || !keyword
        ? { page: nextPage || DEFAULT_PAGE, take: PAGE_SIZE }
        : {}),
      ...(keyword ? { keyword } : {}),
    };
    fetchBrands({
      params,
      callback: (data) => {
        if (keyword) {
          setBrandList(data.brands);
        } else {
          setBrandList(
            forceRefresh ? data.brands : [...brandList, ...data.brands]
          );
        }

        setTotal(data.total);
        setCurrentPage(data.page);
      },
    });
  };

  const debounceFn = useCallback(_debounce(fetchBrandList, 1000), []);
  const handleChange = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value.trim());
  };

  const handleSearch = useCallback(() => {
    search && fetchBrandList(search);
  }, [search]);

  // handle click
  const handleClickAddBrand = () => {
    setCreateUpdateBrandModal({
      isOpen: true,
      type: ModalType.add,
    });
  };

  const handleClickEditBrand = (brandEditing: BrandItem) => {
    setCreateUpdateBrandModal({
      isOpen: true,
      type: ModalType.update,
      brandEditing: brandEditing,
    });
  };

  const handleClickDeleteBrand = (brandItem: BrandItem) => {
    setDeleteBrand(brandItem);
  };

  // handle create & update brand
  const handleCreateBrand = (createBrandForm: BrandInput) => {
    api
      .post('/brands', {
        id: createBrandForm.id.trim(),
        name: createBrandForm.nameBrand.trim(),
        address: createBrandForm.address.trim(),
        image: createBrandForm.photo,
        brandItemNames: createBrandForm.brandItemNames,
      })
      .then(() => {
        fetchBrandList('', DEFAULT_PAGE, true);
        getTotalUnassignedBrands();
        toast.success(t('messages.M_010'));
        setCreateUpdateBrandModal({
          isOpen: false,
          type: ModalType.add,
        });
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleUpdateBrand = (createBrandForm: BrandInput) => {
    api
      .put(`/brands/${createBrandForm.id}`, {
        name: createBrandForm.nameBrand.trim(),
        address: createBrandForm.address.trim(),
        brandItemNames: createBrandForm.brandItemNames,
        ...(!createBrandForm.photo ? { imageDelete: true } : {}),
        ...(createBrandForm.photo !==
        createUpdateBrandModal.brandEditing?.imageUrl
          ? { image: createBrandForm.photo }
          : {}),
      })
      .then(() => {
        fetchBrandList('', DEFAULT_PAGE, true);
        getTotalUnassignedBrands();
        toast.success(t('messages.M_011'));
        setCreateUpdateBrandModal({
          isOpen: false,
          type: ModalType.add,
        });
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleDeleteBrand = (id?: string | number) => {
    api
      .delete(`/brands/${id}`)
      .then(() => {
        fetchBrandList('', DEFAULT_PAGE, true);
        getTotalUnassignedBrands();
        toast.success(t('messages.M_012'));
        setDeleteBrand(undefined);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  useEffect(() => {
    fetchBrandList();
  }, []);

  return (
    <AdminTemplate
      h1={t('brand_management.heading')}
      active="ブランド管理"
      breadcrumb={[{ label: t('brand_management.heading') }]}
    >
      <div
        className="count-list"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t('common.page', {
              page: total,
            }) ?? ''
          ),
        }}
      />

      <div className={`search-area ${styles.searchArea}`}>
        <div className="search-input">
          <input placeholder="検索" value={search} onChange={handleChange} />
          <div className="icon" onClick={handleSearch}>
            <IconSearch />
          </div>
        </div>
        <div className="button" onClick={handleClickAddBrand}>
          <IconAddCircleOutline />
          <span>{t('common.button.add_brand')}</span>
        </div>
      </div>
      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.brandListTable}>
          <thead>
            <tr>
              <th className={styles.operator}>
                {t('brand_management.thead_business_id')}
              </th>
              <th className={styles.image}>
                {t('brand_management.thead_image')}
              </th>
              <th className={styles.name}>
                {t('brand_management.thead_name')}
              </th>
              <th className={styles.address}>
                {t('brand_management.thead_location')}
              </th>
              <th className={styles.action}>{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {brandList.length ? (
              brandList.map((brand) => {
                return (
                  <BrandRowItem
                    key={brand.id}
                    brandItem={brand}
                    onHandleEditBrand={handleClickEditBrand}
                    onHandleDeleteBrand={handleClickDeleteBrand}
                  />
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
            {brandList && brandList.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    brandList.length >= PAGE_SIZE &&
                    brandList.length < total
                  ) {
                    setCurrentPage(currentPage + 1);
                    fetchBrandList(search, currentPage + 1);
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>

      {createUpdateBrandModal.isOpen && (
        <div className={styles.createItemModal}>
          <CreateBrandItemModal
            createUpdateBrandModal={createUpdateBrandModal}
            onClose={() => {
              setCreateUpdateBrandModal({
                isOpen: false,
                type: ModalType.add,
              });
            }}
            onCreateBrand={handleCreateBrand}
            onUpdateBrand={handleUpdateBrand}
          />
        </div>
      )}

      {deleteBrand && (
        <DeleteItemModal
          item={deleteBrand}
          onClose={() => {
            setDeleteBrand(undefined);
          }}
          onHandleDelete={handleDeleteBrand}
        />
      )}
    </AdminTemplate>
  );
};

export default AdminBrandOwnerManagement;
