// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { TenantIndustry } from 'apis/tenants';
import { TenantCreateEditModalType } from 'components/organisms/AdminTenantManagement';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FloorItem, TenantForm } from 'util/Types';
import { validateEmailAddress, validateStringField } from 'util/validator';
import { InputDataListField } from '../InputDataListField';
import { SelectField } from '../SelectField';
import styles from './CreateUpdateTenantInputForm.module.scss';
import { Label } from 'components/atoms/Label';
import Toggle from 'components/atoms/Toggle';

interface Props {
  initValues: TenantForm;
  sectionList: { label: string; value: number }[];
  createUpdateModal: TenantCreateEditModalType;
  brandList: { label: string; value: string }[];
  floorList: FloorItem[];
  tenantIndustries: TenantIndustry[];
  onNextStep: (tenantForm: TenantForm) => void;
}

const CreateUpdateTenantInputForm: React.FC<Props> = ({
  initValues,
  createUpdateModal,
  sectionList,
  brandList,
  floorList,
  tenantIndustries,
  onNextStep,
}) => {
  const { tenantEditing } = createUpdateModal;
  const [floorListState, setFloorListState] = useState<
    { label: string; value: number }[]
  >([]);
  const [isDashboardEnabled, setIsDashboardEnabled] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (tenantEditing?.section?.id) {
      setFloorListState(
        floorList
          .filter((floor) => floor.sectionId === tenantEditing.section?.id)
          .map((floor) => {
            return { value: floor.id, label: floor.name };
          })
      );
    }
  }, [tenantEditing]);

  useEffect(() => {
    setIsDashboardEnabled(initValues.isDashboardEnabled);
  }, [initValues]);

  const handleChangeSection = (sectionId: string | number) => {
    setFloorListState(
      floorList
        .filter((floor) => floor.sectionId === +sectionId)
        .map((floor) => {
          return { value: floor.id, label: floor.name };
        })
    );
  };

  return (
    <div className={styles.createBody}>
      <Form<TenantForm>
        initialValues={initValues}
        onSubmit={(values) => onNextStep({ ...values, isDashboardEnabled })}
        validate={(values) => {
          return {
            name: validateStringField('テナント名', 100, values.name),
            industry: validateStringField('テナント業種', 100, values.industry),
            contactName: validateStringField(
              t('tenant.thead_contact_name'),
              100,
              values.contactName
            ),
            contactEmail: validateStringField(
              t('tenant.thead_contact_email'),
              100,
              values.contactEmail
            ),
          };
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {(props) => (
          <form className={styles.formInput}>
            <div className={styles.inputFrame}>
              <SelectField
                label={t('tenant.thead_section') || 'セクション'}
                name="sectionId"
                placeholder={t('common.select') || '選択する'}
                options={sectionList}
                disabled={!sectionList.length}
                onChange={(value) => handleChangeSection(value)}
              />

              <SelectField
                label={t('tenant.thead_floor') || 'フロア'}
                name="floorId"
                placeholder={t('common.select') || '選択する'}
                options={floorListState}
                disabled={!floorListState.length}
              />

              <SelectField
                label={t('tenant.thead_brand_owner') || 'ブランドオーナー'}
                name="brandId"
                placeholder={t('common.select') || '選択する'}
                options={brandList}
                disabled={!brandList.length}
              />

              <InputDataListField
                label={t('tenant.thead_tenant_name') || 'テナント名'}
                placeholder={t('tenant.thead_tenant_name') || 'テナント名'}
                name="name"
                required
                maxlength={100}
              />

              <InputDataListField
                label={t('tenant.thead_tenant_number') || 'テナント番号'}
                placeholder={t('tenant.thead_tenant_number') || 'テナント番号'}
                name="tenantNumber"
                maxlength={100}
              />

              <InputDataListField
                label={t('tenant.thead_tenant_industry') || 'テナント業種'}
                placeholder={
                  t('tenant.thead_tenant_industry') || 'テナント業種'
                }
                name="industry"
                required
                maxlength={100}
                dataSuggestions={
                  tenantIndustries?.map((i) => {
                    return { name: i.industry };
                  }) || []
                }
                onSelectSuggestItem={(value) => {
                  props.form.mutators.setValue('industry', value);
                }}
              />
              <p className={styles.industryDesc}>
                ※テナント業種が分からない場合、「その他」を記入してください。
              </p>

              <InputDataListField
                label={t('tenant.thead_contact_name')}
                placeholder={t('tenant.thead_contact_name')}
                name="contactName"
                required
                maxlength={100}
              />
              <InputDataListField
                label={t('tenant.thead_contact_email')}
                placeholder={t('tenant.thead_contact_email')}
                name="contactEmail"
                required
                maxlength={100}
                validate={validateEmailAddress}
              />
              <div className={styles.viewReport}>
                <Label
                  className={styles.label}
                  text={t('tenant.thead_view_report') || ''}
                  tag={'div'}
                  required
                />
                <Toggle
                  value={isDashboardEnabled}
                  setValue={setIsDashboardEnabled}
                />
              </div>
            </div>

            <div className={styles.line} />

            <div className={styles.submit}>
              <button
                disabled={props.invalid}
                className={
                  props.invalid ? styles.disableButton : styles.submitButton
                }
                onClick={props.handleSubmit}
                type="button"
              >
                {t('common.button.next')}
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CreateUpdateTenantInputForm;
