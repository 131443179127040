// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import Dexie, { Table } from 'dexie';
import { DATABASE_NAME, DATABASE_VERSION } from './ConstantValues';
import {
  OfflineCategoryImages,
  OfflineUserInfo,
  SiteOfflineResponse,
  WasteRegistrationOfflineRequest,
} from 'types/offline';
import { Tenant } from 'components/molecules/SelectTenantCard';
import { Section } from 'components/molecules/SelectSectionCard';
import { Floor } from 'components/molecules/SelectFloorCard';
import { CategoryItem, TareItem } from './Types';

class DbManagerClass extends Dexie {
  sites!: Table<SiteOfflineResponse>;

  tenants!: Table<Tenant>;

  sections!: Table<Section>;

  floors!: Table<Floor>;

  tares!: Table<TareItem>;

  wasteRegistrations!: Table<WasteRegistrationOfflineRequest>;

  categories!: Table<
    CategoryItem & {
      wasteUnitsOneCategory?: CategoryItem[];
    }
  >;

  categoryImages!: Table<OfflineCategoryImages>;

  userInfo!: Table<OfflineUserInfo>;

  constructor() {
    super(DATABASE_NAME);

    this.version(DATABASE_VERSION).stores({
      sites: '++id, siteName, tenantAuthen, operatorName, dataSyncTime',
      tenants:
        '++id, name, brand, section, floor, industry, barcode, icCards, contactName, contactEmail',
      sections: '++id, name, siteId, floors, tenants',
      floors: '++id, name, sectionId, tenants',
      tares: '++id, name, weight, tenants',
      categories:
        '++id, name, other, imageUrl, materialName, size, siteId, typeObject, convertKg, quantity, type, unit, inputUnit, wasteUnitsOneCategory, sortIndex, categoryId, categoryName',
      wasteRegistrations:
        '++id, type, wasteUnitId, wasteUnitName, categoryId, categoryName, quantity, tenantId, tareName, tareWeight, timeRegis, createdBy',
      categoryImages: '++path, content',
      userInfo:
        '++userId, name, isAdmin, operatorType, operatorId, operatorName, siteName, siteId, role, isShowTenants, tenantAuthen, originalTenantAuthen, tenantId, tenantName',
    });
  }

  clearExceptWasteRegistrations = (skipUserInfo?: boolean) =>
    Promise.all([
      this.sites.clear(),
      this.tenants.clear(),
      this.sections.clear(),
      this.floors.clear(),
      this.tares.clear(),
      this.categories.clear(),
      this.categoryImages.clear(),
      ...(skipUserInfo ? [] : [this.userInfo.clear()]),
    ]);

  updateUserInfo = async (data: OfflineUserInfo) => {
    await this.userInfo.clear();
    await this.userInfo.add(data);
  };
}

export const DbManager = new DbManagerClass();
