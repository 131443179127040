// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './DeleteItemDarkModal.module.scss';
import { Modal } from 'components/atoms/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconDeleteOutline } from 'components/atoms/icons/IconDeleteOutline';
import { IconCloseTablet } from 'components/atoms/icons/IconCloseTablet';

interface Props {
  onClose: () => void;
  onHandleDelete: (id?: number) => void;
  deletingId?: number;
  deletingName?: string;
  content?: string | null;
  closeModalIcon?: React.ReactNode;
}

const DeleteItemDarkModal: React.FC<Props> = ({
  onClose,
  onHandleDelete,
  deletingId,
  deletingName,
  content,
  closeModalIcon,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.customModal}>
      <Modal>
        <div className={styles.deleteItemModal}>
          <div className={styles.customIcon}>
            <div className={styles.iconDeleteItem}>
              <IconDeleteOutline />
            </div>

            <div className={styles.top} onClick={onClose}>
              {closeModalIcon ?? <IconCloseTablet />}
            </div>
          </div>

          <div className={styles.customTitle}>
            <div className={styles.title}>{t('common.confirm')}</div>
            <p>{content}</p>
            {deletingName && (
              <p
                title={`${t('common.confirm_delete', { field: deletingName })}`}
              >
                {t('common.confirm_delete', { field: deletingName })}
              </p>
            )}
          </div>

          <div className={styles.submit}>
            <button
              className={styles.submitButton}
              type="submit"
              onClick={() => onHandleDelete(deletingId)}
            >
              {t('common.delete')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteItemDarkModal;
