// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import WasteChart from 'components/molecules/WasteChart';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import styles from './Dashboard.module.scss';
import ChartRecyclingRate from 'components/molecules/ChartRecyclingRate';
import ChartRecyclingRateArea from 'components/molecules/ChartRecyclingRateArea';
import ChartCO2Rate from 'components/molecules/ChartCO2Rate';
import ChartWasteAmountArea from 'components/molecules/ChartWasteAmountArea';
import DashboardGeneralArea from 'components/organisms/DashboardGeneralArea';
import DashboardSelectArea from 'components/organisms/DashboardSelectArea';
import { createAxios } from 'ts/createAxios';
import { useEffect, useMemo, useState } from 'react';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import dayjs from 'dayjs';
import RateRecycleMethodChart from 'components/organisms/RateRecycleMethodChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import RateOfWasteByItemChart from 'components/organisms/RateOfWasteByItemChart';
import SiteWasteRateChart from 'components/organisms/SiteWasteRateChart';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from 'util/endPoints';
import React from 'react';
import { useQuery } from 'util/queryParams';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { RoleType } from 'util/Enums';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  LineController,
  ChartDataLabels
);
export type FilterObject = {
  dates: { from: string; to: string };
  sites?: { id: string; name: string }[];
  wasteUnitNames?: number[];
  industries?: string[];
  tenants?: { id: number; name: string; tenantFullName: string }[];
  time_now?: number;
  brandItems?: { id: number; name: string }[];
  isDashboardSite?: boolean;
};
type RecycleMethod = {
  id: number;
  name: string;
  weight: number;
  rate: number;
};
type RateRecycleStatisticsDetail = {
  month: number;
  year: number;
  totalWeight: number;
  recycleWeight: number;
  recycleRate: number;
  recycleMethods: RecycleMethod[];
};
export type RateRecycleStatisticsType = {
  isYearData: boolean;
  totalWeight: number;
  totalRecycleWeight: number;
  totalFinalDisposal: number;
  recycleRate: number;
  dataDetail: RateRecycleStatisticsDetail[];
};
export type Co2EmissionsStatisticsDetail = {
  date: string;
  month: number;
  year: number;
  CO2Emissions: string;
};
export type Co2EmissionsStatisticsType = {
  isYearData: boolean;
  totalCO2Emissions: string;
  dataDetail: Co2EmissionsStatisticsDetail[];
};
const Dashboard = () => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const search = useQuery();
  const user = useSelector(selectUser);

  const { siteIdQuery, siteNameQuery } = useMemo(
    () => ({
      siteIdQuery: search.get('siteId'),
      siteNameQuery: search.get('siteName'),
    }),
    [search]
  );

  const isAdminOperatorSiteView = useMemo(
    () =>
      Boolean(
        user.role === RoleType.AdminOperator && siteIdQuery && siteNameQuery
      ),
    [siteIdQuery, siteNameQuery, user.role]
  );

  const [filter, setFilter] = useState<FilterObject>({
    dates: {
      from: getSlaFormatYearMonthDate(dayjs().subtract(6, 'month').format()),
      to: getSlaFormatYearMonthDate(dayjs().format()),
    },
    ...(isAdminOperatorSiteView &&
      siteIdQuery &&
      siteNameQuery && {
        sites: [{ name: siteNameQuery, id: siteIdQuery }],
        isDashboardSite: true,
      }),
  });
  const [rateRecycleStatistics, setRateRecycleStatistics] =
    useState<RateRecycleStatisticsType>();
  const [co2EmissionsStatistics, setCo2EmissionsStatistics] =
    useState<Co2EmissionsStatisticsType>();

  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const commonParams = React.useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants
          ?.filter((item) => item.id != null)
          ?.map((item) => (item.id.toString() === '' ? null : item.id)) || [],
      tenantNames:
        filter?.tenants
          ?.filter((item) => item.id != null)
          ?.map((item) => item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);

  const fetchDashboardRateRecycleStatistics = async () => {
    const res = await commonsApi.post(
      API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_STATICS,
      commonParams
    );
    return res;
  };

  const fetchDashboardCo2EmissionsStatistics = async () => {
    const res = await commonsApi.post(
      API_ENDPOINTS.DASHBOARD_CO2_EMISSIONS,
      commonParams
    );
    return res;
  };

  const {
    triggerFetch: fetchRecycleStatistics,
    loading: recycleStatisticsFetching,
  } = useCallbackLoading({
    callback: () =>
      fetchDashboardRateRecycleStatistics().then((res) => {
        setRateRecycleStatistics(res.data);
      }),
  });

  const {
    triggerFetch: fetchCo2EmissionsStatistics,
    loading: co2EmissionsStatisticsFetching,
  } = useCallbackLoading({
    callback: () =>
      fetchDashboardCo2EmissionsStatistics().then((res) => {
        setCo2EmissionsStatistics(res.data);
      }),
  });

  useEffect(() => {
    fetchRecycleStatistics();
    fetchCo2EmissionsStatistics();
  }, [filter]);

  const handleChangeFilter = (filter: FilterObject) => {
    setFilter(filter);
  };
  return (
    <PrimaryTemplate h1={t('dashboard.title')}>
      <div className={styles.DashboardContainer}>
        <div className={styles.generalsArea}>
          <DashboardGeneralArea
            rateRecycleStatistics={rateRecycleStatistics}
            co2EmissionsStatistics={co2EmissionsStatistics}
            isAdminOperatorSiteView={isAdminOperatorSiteView}
            recycleStatisticsFetching={recycleStatisticsFetching}
            co2EmissionsStatisticsFetching={co2EmissionsStatisticsFetching}
          />
          <DashboardSelectArea
            initialFilter={filter}
            onSearch={handleChangeFilter}
            siteId={
              isAdminOperatorSiteView && siteIdQuery ? siteIdQuery : undefined
            }
          />
        </div>
        <div className={styles.topChart}>
          <WasteChart
            filterObject={filter}
            isAdminOperatorSiteView={isAdminOperatorSiteView}
          />
          <div className={styles.donutChart}>
            <div className={styles.donutContainer}>
              <RateRecycleMethodChart
                filter={filter}
                isAdminOperatorSiteView={isAdminOperatorSiteView}
              />
              <RateOfWasteByItemChart
                filter={filter}
                isAdminOperatorSiteView={isAdminOperatorSiteView}
              />
              <SiteWasteRateChart
                filter={filter}
                isAdminOperatorSiteView={isAdminOperatorSiteView}
              />
            </div>
          </div>
        </div>
        <div className={styles.BarsArea}>
          <ChartRecyclingRate
            rateRecycleStatistics={rateRecycleStatistics}
            filter={filter}
            isAdminOperatorSiteView={isAdminOperatorSiteView}
            loading={recycleStatisticsFetching}
          />
          <ChartRecyclingRateArea
            filter={filter}
            isAdminOperatorSiteView={isAdminOperatorSiteView}
          />
          <ChartCO2Rate
            co2EmissionsStatistics={co2EmissionsStatistics}
            filter={filter}
            isAdminOperatorSiteView={isAdminOperatorSiteView}
            loading={co2EmissionsStatisticsFetching}
          />
          <ChartWasteAmountArea
            filter={filter}
            isAdminOperatorSiteView={isAdminOperatorSiteView}
          />
        </div>
      </div>
    </PrimaryTemplate>
  );
};

export default Dashboard;
