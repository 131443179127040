// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styles from './CreateUpdateTareInputForm.module.scss';
import { InputDataListField } from '../InputDataListField';
import { ModalType } from 'util/Enums';
import { useTranslation } from 'react-i18next';
import { AddEditTareProps } from 'components/organisms/CreateUpdateTareModal';
import { validateInputWeight, validateStringField } from 'util/validator';
import { REGEX_OBJECT } from 'util/ConstantValues';
import {
  OptionItem,
  SelectFieldMultipleChoise,
} from '../SelectFieldMultipleChoise';
import { FetchTenantData } from 'components/organisms/AdminTenantManagement';
import { createAxios } from 'ts/createAxios';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { handleError } from 'util/errorHandler';

interface Props {
  tareData: AddEditTareProps;
  onSubmit: (form: TareInput, tareId?: number) => void;
}

export type TareInput = {
  name: string;
  weight: number;
  tenantIds: number[];
};

const CreateUpdateTareInputForm: React.FC<Props> = ({ tareData, onSubmit }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const api = createAxios();
  const [tenantOptions, setTenantOptions] = useState<OptionItem[]>([]);

  const fetchTenants = async () => {
    try {
      const { data } = await api.get<FetchTenantData>(`/tenants`, {
        params: { siteId: user.siteId },
      });
      const tenants: OptionItem[] = data.tenants.map((tenant) => ({
        id: tenant.id,
        name: tenant.name,
      }));

      setTenantOptions(tenants);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  return (
    <div className={styles.createBody}>
      <Form<TareInput>
        keepDirtyOnReinitialize
        initialValues={
          tareData.tare
            ? {
                name: tareData.tare?.name,
                weight: tareData.tare?.weight,
                tenantIds: tareData.tare?.tenants.map((item) =>
                  Number(item.id)
                ),
              }
            : {}
        }
        onSubmit={(values) => {
          if (tareData.type === ModalType.add) {
            onSubmit(values);
          } else {
            onSubmit(values, tareData.tare?.id);
          }
        }}
        validate={(values) => {
          return {
            name: validateStringField(
              t('create_update_tare_modal.label_name'),
              100,
              values.name
            ),
            weight: validateInputWeight(
              t('create_update_tare_modal.label_weight'),
              10,
              values.weight
            ),
          };
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={styles.formInput}>
            <div className={styles.inputFrame}>
              <InputDataListField
                label={t('create_update_tare_modal.label_name') || ''}
                placeholder={t('create_update_tare_modal.label_name') || ''}
                name="name"
                required
                maxlength={100}
              />
              <div className={styles.weightField}>
                <InputDataListField
                  label={t('create_update_tare_modal.label_weight') || ''}
                  placeholder={t('create_update_tare_modal.label_weight') || ''}
                  name="weight"
                  required
                  validRegex={REGEX_OBJECT.numberWithDot2Decimal}
                  maxlength={10}
                />
                <span className={styles.weightUnit}>kg</span>
              </div>
              <SelectFieldMultipleChoise
                options={tenantOptions}
                label={t('common.tenant') || ''}
                placeholder={t('common.tenant') || ''}
                name="tenantIds"
                searchable
                onChange={(tenants) => {
                  props.form.mutators.setValue(
                    'tenantIds',
                    tenants.map((tenant) => tenant.id)
                  );
                }}
                selectedOptions={
                  tenantOptions.filter((tenant) =>
                    props.values.tenantIds?.includes(Number(tenant.id))
                  ) || []
                }
                darkMode
              />
            </div>

            <div className={styles.submit}>
              <button
                disabled={props.invalid}
                className={styles.submitButton}
                type="submit"
              >
                {tareData.type === ModalType.add
                  ? t('create_update_tare_modal.button_add')
                  : t('create_update_tare_modal.button_edit')}
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CreateUpdateTareInputForm;
