// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getResourceHistoryList } from 'apis/resource';
import TableLoading from 'components/atoms/TableLoading';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSlaFormatYearMonthDate } from 'ts/formatDate';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import {
  ResourceEditAvailableFields,
  ResourceEditAvailableFieldsName,
  ResourceEmissionsEditFieldName,
  ResourceHistoryItem,
  ResourseHistoryStatus,
} from 'util/adminResourceManagementTypes';
import styles from './AdminResourceHistoryTable.module.scss';

const AdminResourceHistoryTable = () => {
  const { t } = useTranslation();
  const [totalItems, setTotalItems] = useState(0);
  const [listResourceHistoies, setListResourceHistoies] = useState<
    ResourceHistoryItem[]
  >([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);

  const fetchResourceHistory = async (nextPage?: number) => {
    getResourceHistoryList(
      { page: nextPage || DEFAULT_PAGE, take: PAGE_SIZE },
      (data) => {
        if (data) {
          setListResourceHistoies([
            ...listResourceHistoies,
            ...(data?.resourceHistories || []),
          ]);
          setTotalItems(data?.total || 0);
        }
      }
    );
  };

  useEffect(() => {
    fetchResourceHistory();
  }, []);

  const getListFieldsEdited = (newData: ResourceEditAvailableFields) => {
    return Object.keys(newData);
  };
  return (
    <div className={styles.tableList}>
      <div className={styles.titleTable}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('common.page', {
                page: totalItems,
              }) ?? ''
            ),
          }}
        />
      </div>
      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.resourceHistoryTable}>
          <thead>
            <tr>
              <th className={styles.editTime}>編集日時</th>
              <th>編集者ユーザーID</th>
              <th>編集した資源のID</th>
              <th>編集したフィールド</th>
              <th>編集前のデータ</th>
              <th>編集後のデータ</th>
            </tr>
          </thead>
          <tbody>
            {listResourceHistoies && listResourceHistoies.length > 0 ? (
              listResourceHistoies.map((item: ResourceHistoryItem) => {
                return (
                  <tr key={item.id}>
                    <td className={styles.editTime}>
                      <p>{getSlaFormatYearMonthDate(item.registDatetime)}</p>
                    </td>
                    <td>
                      <p>{item.userId || '-'}</p>
                    </td>
                    <td>
                      <p>{item.targetId || '-'}</p>
                    </td>
                    <td>
                      {getListFieldsEdited(item.newData).map((field) => {
                        return item.target !==
                          ResourseHistoryStatus.Emissions ? (
                          <p>{ResourceEditAvailableFieldsName[field]}</p>
                        ) : (
                          <p>{ResourceEmissionsEditFieldName[field]}</p>
                        );
                      })}
                    </td>
                    <td>
                      {getListFieldsEdited(item.newData).map(
                        (field) =>
                          ResourceEditAvailableFieldsName[field] && (
                            <p>
                              {field === 'emissionDatetime'
                                ? getSlaFormatYearMonthDate(
                                    item.oldData[field] || ''
                                  )
                                : field === 'qualityInspectionMethod'
                                ? item.oldData[field]?.join(', ') || ''
                                : item.oldData[field] || '-'}
                            </p>
                          )
                      )}
                    </td>
                    <td>
                      {getListFieldsEdited(item.newData).map(
                        (field) =>
                          ResourceEditAvailableFieldsName[field] && (
                            <p>
                              {field === 'emissionDatetime'
                                ? getSlaFormatYearMonthDate(
                                    item.newData[field] || ''
                                  )
                                : field === 'qualityInspectionMethod'
                                ? item.newData[field]?.join(', ') || ''
                                : item.newData[field] || '-'}
                            </p>
                          )
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
          </tbody>
          {listResourceHistoies && listResourceHistoies.length > 0 && (
            <TableLoading
              onNext={() => {
                if (
                  listResourceHistoies.length >= PAGE_SIZE &&
                  listResourceHistoies.length < totalItems
                ) {
                  fetchResourceHistory(currentPage + 1);
                  setCurrentPage(currentPage + 1);
                }
              }}
            />
          )}
        </table>
      </div>
    </div>
  );
};

export default AdminResourceHistoryTable;
