// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import TableLoading from 'components/atoms/TableLoading';
import { FileImportIcon } from 'components/atoms/icons/FileImportIcon';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import DOMPurify from 'dompurify';
import _debounce from 'lodash/debounce';
import { getActiveSite } from 'pages/AdminOperatorListManagement/AdminOperatorListManagement';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Path } from 'routes';
import { DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { AdminManagementType, ModalType, OperatorType } from 'util/Enums';
import { useQuery } from 'util/queryParams';
import {
  OperatorItem,
  SiteAuthenticationItem,
  SiteDataItem,
  SiteDataList,
} from 'util/siteManagementTypes';
import styles from './AdminOperatorSiteTable.module.scss';
import { IconBarcode } from 'components/atoms/icons/IconBarcode';
import BarcodeICCardDetailModal from '../BarcodeICCardDetailModal';
import AddICCardModal from 'components/molecules/AddICCardModal';
import { addSiteAdminCard, deleteSiteAdminCard } from 'apis/sites';
import { handleError } from 'util/errorHandler';
import { toast } from 'react-toastify';
export interface OperatorCreateEditModalType {
  isShow: boolean;
  type: ModalType;
  id?: string;
}

type Props = {
  type: AdminManagementType;
  dataList: SiteDataList;
  totalItems: number;
  currentPage: number;
  updateData: (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean
  ) => void;
  onClickAddSite?: () => void;
  onClickEditSite?: (siteEditing: SiteDataItem) => void;
  onClickEditOperator?: (operatorId: string) => void;
  onClickAddOperator?: () => void;
  onClickDeleteOperator?: (operator: OperatorItem) => void;
  onClickDeleteSite?: (siteEditing: SiteDataItem) => void;
  onClickImportOperatorCSV?: () => void;
  onClickImportSiteCSV?: () => void;
};

const AdminOperatorSiteTable = ({
  type,
  dataList,
  totalItems,
  currentPage,
  updateData,
  onClickAddSite,
  onClickEditSite,
  onClickEditOperator,
  onClickAddOperator,
  onClickDeleteOperator,
  onClickDeleteSite,
  onClickImportOperatorCSV,
  onClickImportSiteCSV,
}: Props) => {
  const history = useHistory();
  const { operatorId } = useParams<{ operatorId: string }>();
  const { t } = useTranslation();
  const query = useQuery();
  const operatorType = query.get('type');
  const [search, setSearch] = useState('');
  const [adminCardDetailSiteData, setMasterCardDetailSiteData] = useState<
    SiteAuthenticationItem | undefined
  >();
  const [adminCardAddSiteData, setMasterCardAddSiteData] = useState<
    SiteAuthenticationItem | undefined
  >();
  const handleClickItem = (id: number) => {
    if (type === 'operator') {
      history.push(
        `${Path.adminOperatorListManagement}/${id}${window.location.search}`
      );
    } else if (
      type === AdminManagementType.site &&
      operatorType === OperatorType.Emissions
    ) {
      operatorId &&
        history.push(
          `${Path.adminSiteManagement}/${operatorId}/${id}${window.location.search}#0`
        );
    }
  };
  const activeType = getActiveSite(operatorType as OperatorType);

  const handleClickImportFile = () => {
    if (type === AdminManagementType.operator) {
      onClickImportOperatorCSV && onClickImportOperatorCSV();
    } else {
      onClickImportSiteCSV && onClickImportSiteCSV();
    }
  };

  const handleSubmitAddAdminCard = async (e) => {
    if (!adminCardAddSiteData) return;

    try {
      await addSiteAdminCard(adminCardAddSiteData.id, e.icCard.trim());
      setMasterCardDetailSiteData(undefined);
      setMasterCardAddSiteData(undefined);
      toast.success(t('messages.M_109'));
      updateData(search, currentPage, true);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteAdminCard = async (icCardId: number) => {
    if (!icCardId) return;

    try {
      await deleteSiteAdminCard(icCardId);
      setMasterCardDetailSiteData(undefined);
      toast.success(t('messages.M_012'));
      updateData(search, currentPage, true);
    } catch (error) {
      handleError(error);
    }
  };

  const debounceFn = useCallback(
    _debounce((value: string) => updateData(value, DEFAULT_PAGE), 1000),
    []
  );

  useEffect(() => {
    setSearch('');
  }, [operatorType]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value.trim());
  };

  const handleSearch = useCallback(() => {
    search && updateData(search, currentPage, true);
  }, [search]);

  return (
    <div className={styles.tableList}>
      <div className={styles.titleTable}>
        <div
          className="count-list"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('common.page', {
                page: totalItems,
              }) ?? ''
            ),
          }}
        />

        <div className={styles.customIcon}>
          {(type === AdminManagementType.operator ||
            operatorType === OperatorType.Emissions) && (
            <div
              className={styles.buttonFileImportIcon}
              onClick={handleClickImportFile}
            >
              <FileImportIcon />
              <span>{t('common.import_data')}</span>
            </div>
          )}

          <div
            className={styles.buttonAdd}
            onClick={
              type === AdminManagementType.site
                ? onClickAddSite
                : onClickAddOperator
            }
          >
            <IconAddCircleOutline />
            <span>
              {type === AdminManagementType.site
                ? `${activeType}拠点を追加`
                : t('operator_management.add')}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.searchInput}>
        <input
          placeholder={t('common.placeholder.search') || '検索'}
          value={search}
          onChange={handleChange}
        />

        <div className={styles.icon} onClick={handleSearch}>
          <IconSearch />
        </div>
      </div>

      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.customizeItemTable}>
          <thead>
            <tr>
              <th
                className={
                  type === AdminManagementType.operator
                    ? styles.discharger
                    : styles.dischargerBase
                }
              >
                {type === AdminManagementType.operator
                  ? t('operator_management.thead_operator')
                  : `${activeType}拠点`}
              </th>
              <th className={styles.actionField}>{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {dataList && dataList.length > 0 ? (
              dataList.map((item, i) => {
                return (
                  <tr
                    key={i}
                    onClick={() => {
                      handleClickItem(item.id);
                    }}
                  >
                    <td
                      className={
                        type === AdminManagementType.operator
                          ? styles.discharger
                          : styles.dischargerBase
                      }
                    >
                      <p>{item.name}</p>
                    </td>
                    <td className={styles.actionField}>
                      <div className={styles.contentAction}>
                        <div className={styles.actionEdit}>
                          <IconEdit
                            onClick={(e: ChangeEvent) => {
                              e.stopPropagation();
                              if (type === AdminManagementType.site)
                                onClickEditSite && onClickEditSite(item);
                              else {
                                onClickEditOperator &&
                                  onClickEditOperator(item.id);
                              }
                            }}
                          />
                        </div>
                        <div className={styles.actionDelete}>
                          <IconDelete
                            onClick={(e) => {
                              e.stopPropagation();
                              if (type === AdminManagementType.operator) {
                                onClickDeleteOperator &&
                                  onClickDeleteOperator(item);
                              } else {
                                onClickDeleteSite && onClickDeleteSite(item);
                              }
                            }}
                          />
                        </div>
                        {type === AdminManagementType.site &&
                          operatorType === OperatorType.Emissions && (
                            <div className={styles.actionMasterCard}>
                              <IconBarcode
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  setMasterCardDetailSiteData({
                                    type: 'site',
                                    name: item.name,
                                    id: item.id,
                                    adminCards: item.adminCards,
                                  });
                                }}
                              />
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
          </tbody>
          {dataList && dataList.length > 0 && (
            <TableLoading
              onNext={() => {
                if (
                  dataList.length >= PAGE_SIZE &&
                  dataList.length < totalItems
                ) {
                  updateData(search, currentPage + 1);
                }
              }}
            />
          )}
        </table>

        {adminCardDetailSiteData && (
          <BarcodeICCardDetailModal
            authenticationItem={adminCardDetailSiteData}
            onClose={() => {
              setMasterCardDetailSiteData(undefined);
            }}
            onClickShowAddICCard={() => {
              setMasterCardAddSiteData(adminCardDetailSiteData);
              setMasterCardDetailSiteData(undefined);
            }}
            onClickDeleteICCard={handleDeleteAdminCard}
          />
        )}

        {adminCardAddSiteData && (
          <AddICCardModal
            authenticationItem={adminCardAddSiteData}
            onSubmitAddICCard={handleSubmitAddAdminCard}
            onClose={() => {
              setMasterCardDetailSiteData(adminCardAddSiteData);
              setMasterCardAddSiteData(undefined);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AdminOperatorSiteTable;
