// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { DATE_FORMAT, REGEX_OBJECT } from 'util/ConstantValues';
import { Option } from 'util/Types';
import { InputDataListField } from '../InputDataListField';
import './CompressionEditCard.scss';

export type Props<T> = {
  id?: string;
  date: string;
  label: string;
  compressionId?: string;
  gradeOptions: { label: string; value: T }[];
  name: string;
  packingOptions: Option[];
  totalInputWeight: string;
  hasPackingStyles?: boolean;
  isOverload?: boolean;
};

const Error = ({ name }: { name: string }) => (
  <Field name={name} subscription={{ error: true }}>
    {({ meta: { error } }) => (error ? <p className="error">{error}</p> : null)}
  </Field>
);

export function CompressionEditCard<T extends string | number>({
  date,
  label,
  compressionId,
  gradeOptions,
  name,
  packingOptions,
  totalInputWeight,
  hasPackingStyles = false,
  isOverload = false,
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <div className={'compression-edit'}>
      <div className={'compression-date-info'}>
        <div className={'compression-date'}>
          {getFormatDateWithCurrentTimezone(date, DATE_FORMAT.slaMDHm)}
        </div>
        <div className={'compression-type'}>{compressionId}</div>
        <div className={'input-label'}>
          <label>{label}</label>
          {isOverload && (
            <label className="exceeded">
              {t('emission_input.weight_over_total')}
            </label>
          )}
        </div>
      </div>
      {hasPackingStyles && (
        <div>
          <div className="quantity pb-12">
            <div className="packingFieldLabel">
              {t('emission_input.packing_style')}
            </div>
            <div
              className={`space quantityFieldWidth weightField packingFieldSelect`}
            >
              <Field name={`${name}.packingStyle`}>
                {(props) => (
                  <select {...props.input}>
                    {packingOptions.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
            </div>
          </div>
          <div className="quantity">
            <div className={'quantityFieldWidth'}></div>
            <div className={'space'}>
              <Error name={`${name}.packingStyle`} />
            </div>
          </div>
        </div>
      )}

      <div className="quantity gap-12">
        <div className="quantityFieldWidth">
          <Field name={`${name}.gradeId`}>
            {(props) => (
              <select {...props.input}>
                {gradeOptions.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            )}
          </Field>
          <Error name={`${name}.gradeId`} />
        </div>
        <div className="quantityFieldWidth">
          <InputDataListField
            name={`${name}.weight`}
            type="text"
            validRegex={REGEX_OBJECT.tenDigitsWith2Decimal}
            isDarkMode
            normalizeNumber
            endExtraText={t('common.input_resources', {
              weight: totalInputWeight,
              unit: 'kg',
            })}
            className="full"
          />
          <Error name={`${name}.weight`} />
        </div>
      </div>
    </div>
  );
}
