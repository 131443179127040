// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { createAxios } from 'ts/createAxios';
import { GetEmissionParams, UnCollectEmissionObj } from 'util/Types';
import { API_ENDPOINTS } from 'util/endPoints';
import { handleError } from 'util/errorHandler';

export const getEmissions = async (p: {
  params?: GetEmissionParams;
  successCallback?: (data: UnCollectEmissionObj) => void;
}) => {
  const { params, successCallback } = p;
  const api = createAxios();

  try {
    const { data } = await api.get<UnCollectEmissionObj>(
      API_ENDPOINTS.EMISSIONS,
      {
        params,
      }
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};
