// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Bar } from 'react-chartjs-2';
import styles from './ChartRecyclingRateArea.module.scss';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { useEffect, useMemo, useRef, useState } from 'react';
import DashboardDownloadModal from '../DashboardDownloadModal';
import { useTranslation } from 'react-i18next';
import PreviewChartModal from '../PreviewChartModal';
import { FilterObject } from 'pages/Dashboard';
import { createAxios } from 'ts/createAxios';
import { getBgColor, initDatasetLabels } from 'util/dashboardTypes';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { CSVLink } from 'react-csv';
import { RoleType } from 'util/Enums';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'util/ConstantValues';
import { API_ENDPOINTS } from 'util/endPoints';
import { normalizeNumber } from 'util/commons';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';
import LoadingCoating from 'components/atoms/LoadingCoating';

interface Props {
  filter: FilterObject;
  isAdminOperatorSiteView?: boolean;
}

type RateRecycleAreaResponse = {
  id: string;
  name: string;
  englishName?: string;
  totalWeight: number;
  tenantFullName?: string;
  isOther?: boolean;
  recycleMethods: {
    id: number;
    name: string;
    weight: number;
    rate: number;
  }[];
}[];
type WasteCSVResponse = {
  dateRange: string;
  wasteUnits: string[];
  industries: string[];
  tenants: string[];
  sites: string[];
  dataDetails: {
    name: string;
    tenantFullName?: string;
    englishName?: string;
    recycleMethod: string;
    rate: number;
  }[];
};
const ChartRecyclingRateArea: React.FC<Props> = ({
  filter,
  isAdminOperatorSiteView,
}) => {
  const [isOpenDownload, setOpenDownload] = useState(false);
  const { t, i18n } = useTranslation();
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [isOpen, setOpen] = useState(false);
  const [rateRecycleArea, setRateRecycleArea] =
    useState<RateRecycleAreaResponse>();
  const user = useSelector(selectUser);

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const commonParams = useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants?.map((item) =>
          item.id.toString() === '' ? null : item.id
        ) || [],
      tenantNames:
        filter?.tenants?.map((item) => item?.tenantFullName || item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);

  const [downloadData, setDownloadData] = useState<WasteCSVResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = useRef<any>();

  const { triggerFetch: handleDownloadCSV, loading: csvDownloading } =
    useCallbackLoading({
      callback: () =>
        commonsApi
          .post(
            adminOperatorView
              ? API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_METHOD_SITE_EXPORT
              : API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_METHOD_TENANT_EXPORT,
            commonParams
          )
          .then((res) => {
            setDownloadData(res.data);
            setTimeout(() => {
              downloadCSVRef.current.link.click();
            }, 500);
          }),
    });

  const { triggerFetch: fetchData, loading: dataFetching } = useCallbackLoading(
    {
      callback: (params: typeof commonParams) =>
        commonsApi
          .post<RateRecycleAreaResponse>(
            adminOperatorView
              ? API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_METHOD_SITE
              : API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_METHOD_TENANT,
            params
          )
          .then((res) => {
            const rateRecycleAreaSize = res.data.length;

            setRateRecycleArea(
              rateRecycleAreaSize >= 4
                ? res.data
                : res.data.concat(
                    new Array(4 - rateRecycleAreaSize).fill({
                      id: '',
                      name: '',
                      totalWeight: 0,
                      recycleMethods: [],
                      ...(user.role === RoleType.BrandOwner
                        ? { tenantFullName: '' }
                        : {}),
                    })
                  )
            );
          }),
    }
  );

  useEffect(() => {
    fetchData(commonParams);
  }, [commonParams]);

  const data = {
    labels: rateRecycleArea
      ?.map((data) =>
        data.isOther
          ? adminOperatorView
            ? t('dashboard.other_sites')
            : adminSiteView
            ? t('dashboard.other_tenants')
            : t('dashboard.other_stores')
          : (data?.tenantFullName || data.name).length > 20
          ? (data?.tenantFullName || data.name).substring(0, 20) + '...'
          : i18n.language === 'ja'
          ? data?.tenantFullName || data.name
          : data?.englishName || data?.tenantFullName || data.name
      )
      ?.reverse(),
    datasets: [
      ...initDatasetLabels.map((item) => ({
        maxBarThickness: 100,
        label: i18n.language === 'ja' ? item.name : item.englishName,
        backgroundColor: getBgColor(item.id),
        data:
          rateRecycleArea
            ?.map((data) =>
              String(
                data.recycleMethods.find((method) => method?.id === item.id)
                  ?.rate || 0
              )
            )
            ?.reverse() || [],
        datalabels: {
          display: false,
        },
      })),
    ],
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartRef = useRef<any>();
  const options = {
    indexAxis: 'y' as const,
    layout: {
      padding: 24,
    },
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          color: '#E7E7E7',
          font: { size: 10 },
          usePointStyle: true,
          pointStyle: 'rectRounded',
          padding: 10,
          sort: (a, b) => b.datasetIndex - a.datasetIndex,
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            const reverseRateRecycleArea = rateRecycleArea
              ? [...rateRecycleArea]
              : [];
            const reverseRateRecycleAreaRender =
              reverseRateRecycleArea.reverse();
            const data = reverseRateRecycleAreaRender[tooltipItem[0].dataIndex];
            const title = data.isOther
              ? adminOperatorView
                ? t('dashboard.other_sites')
                : adminSiteView
                ? t('dashboard.other_tenants')
                : t('dashboard.other_stores')
              : i18n.language === 'ja'
              ? data?.tenantFullName || data.name
              : data?.englishName || data?.tenantFullName || data.name;
            return title;
          },
          label: function (tooltipItem) {
            return (
              (tooltipItem.dataset?.label || '') +
              `: ${normalizeNumber({ value: tooltipItem.raw })}%`
            );
          },
        },
      },
    },
    elements: {
      bar: {
        borderSkipped: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          color: '#3C3C3C',
        },
        max: 100,
        ticks: {
          color: '#CFCFCF',
          stepSize: 25,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          callback: function (value, index, values) {
            return value + '%';
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          crossAlign: 'far' as const,
          color: '#CFCFCF',
          font: {
            size: 10,
          },
        },
      },
    },
  };
  const plugins = (isPreview?: boolean) => {
    return [
      {
        id: 'customCanvasBackgroundColor',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeDraw: (chart, args, options) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = isPreview ? '#24252a' : '#2C2C2C';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
  };
  return (
    <div className={styles.RecyclingChartContainer}>
      <LoadingCoating
        show={dataFetching || csvDownloading}
        styles={{ borderRadius: 12 }}
      />

      <div className={styles.heading}>
        <p>
          {t(
            adminSiteView
              ? 'dashboard.recycling_chart_tenant'
              : adminOperatorView
              ? 'dashboard.recycling_chart'
              : 'dashboard.recycling_chart_store'
          )}
        </p>
        <div
          className={styles.buttonDownload}
          onClick={() => {
            setOpenDownload(true);
          }}
        >
          <IconDownload />
        </div>
      </div>
      <Bar
        onClick={() => setOpen(true)}
        options={options}
        data={data}
        plugins={plugins()}
      />
      {isOpen && (
        <PreviewChartModal
          title={
            t(
              adminSiteView
                ? 'dashboard.recycling_chart_tenant'
                : adminOperatorView
                ? 'dashboard.recycling_chart'
                : 'dashboard.recycling_chart_store'
            ) || ''
          }
          children={
            <Bar options={options} data={data} plugins={plugins(true)} />
          }
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {isOpenDownload && (
        <DashboardDownloadModal
          onClose={() => {
            setOpenDownload(false);
          }}
          handleDownload={(data) => {
            if (data.isImage && chartRef.current) {
              const link = document.createElement('a');
              link.download = `${t(
                adminSiteView
                  ? 'dashboard.recycling_chart_tenant'
                  : adminOperatorView
                  ? 'dashboard.recycling_chart'
                  : 'dashboard.recycling_chart_store'
              )} ${dayjs().format(DATE_FORMAT.slaYMDHm)}.png`;
              link.href = chartRef.current.toBase64Image('image/png', 1);
              link.click();
            }
            if (data.isCsv && handleDownloadCSV) {
              handleDownloadCSV();
            }
            setOpenDownload(false);
          }}
        />
      )}
      <div className={styles.chartHidden}>
        <Bar
          className={styles.chartHidden}
          ref={chartRef}
          options={{
            indexAxis: 'y',
            layout: {
              padding: 24,
            },
            plugins: {
              legend: {
                display: true,
                position: 'right',
                labels: {
                  color: '#000000',
                  font: { size: 18 },
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                  padding: 16,
                  sort: (a, b) => {
                    if (a.datasetIndex && b.datasetIndex) {
                      return b.datasetIndex - a.datasetIndex;
                    }
                    return -1;
                  },
                },
              },
            },
            elements: {
              bar: {
                borderSkipped: true,
              },
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
                grid: {
                  color: '#9EA0A2',
                },
                max: 100,
                ticks: {
                  color: 'black',
                  font: {
                    size: 18,
                  },
                  stepSize: 25,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  callback: function (value, index, values) {
                    return value + '%';
                  },
                },
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  crossAlign: 'far' as const,
                  color: 'black',
                  font: {
                    size: 18,
                  },
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              id: 'customCanvasBackgroundColor',
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
              },
            },
          ]}
        />
      </div>
      <CSVLink
        className="hidden"
        filename={
          t(
            adminSiteView
              ? 'dashboard.recycling_chart_tenant'
              : adminOperatorView
              ? 'dashboard.recycling_chart'
              : 'dashboard.recycling_chart_store'
          ) +
          ` ${dayjs().format(DATE_FORMAT.slaYMDHm)}` +
          '.csv'
        }
        data={[
          [
            adminOperatorView
              ? t('dashboard.co2_chart_csv.site_name')
              : adminSiteView
              ? t('dashboard.co2_chart_csv.tenant_name')
              : t('dashboard.co2_chart_csv.store_name'),
            t('dashboard.rate_recycle_method_chart.process_method'),
            t('dashboard.weight_percentage') + '(%)',
          ],
          downloadData?.dateRange
            ? [
                t('dashboard.co2_chart_csv.date_range'),
                downloadData?.dateRange || '',
              ]
            : [],
          downloadData?.sites && adminOperatorView
            ? [
                t('dashboard.co2_chart_csv.site_name'),
                downloadData?.sites?.join(', ') || '',
              ]
            : [],
          downloadData?.industries && adminSiteView
            ? [
                t('dashboard.co2_chart_csv.business_type'),
                downloadData?.industries?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && adminSiteView
            ? [
                t('dashboard.co2_chart_csv.tenant_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && user.role === RoleType.BrandOwner
            ? [
                t('dashboard.co2_chart_csv.store_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.wasteUnits
            ? [
                t('dashboard.co2_chart_csv.item'),
                downloadData?.wasteUnits?.join(', ') || '',
              ]
            : [],
          ...(downloadData?.dataDetails?.map((item) => {
            return [
              i18n.language === 'ja'
                ? item?.tenantFullName || item.name
                : item?.englishName || item?.tenantFullName || item.name,
              item.recycleMethod,
              Number(item.rate).toFixed(2),
            ];
          }) || []),
        ].filter((data) => data.length)}
        ref={downloadCSVRef}
      ></CSVLink>
    </div>
  );
};
export default ChartRecyclingRateArea;
