// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { ImportStatus, ItemSize, OverallRank } from 'util/Enums';
import { OperatorType } from './Enums';
import { WasteRegistrationOfflineRequest } from 'types/offline';
import { Tenant } from 'components/molecules/SelectTenantCard';
import { ICCardItem } from 'components/organisms/AdminTenantManagement';
import { GetListParams } from 'types/api';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

type TraceRecycle = {
  id: string;
  weight: string;
  description: string;
  datetime: string;
  operatorName: string;
  plasticType: string;
  materialType: string;
  grade: string;
  siteName: string;
};
type TraceCompression = {
  id: string;
  weight: string;
  description: string;
  deliveredDatetime: string;
  deliveryToOperatorName: string;
  bookingDatetime: string;
  deliveryFromOperatorName: string;
  grade: string;
  deliveryFromSiteName: string;
};

type TraceEmission = {
  id: string;
  description: string;
  weight: string;
  deliveredDatetime: string;
  deliveryToOperatorName: string;
  collectedDatetime: string;
  collectedAreaName: string;
  deliveryToSiteName: string;
};

export type TraceData = {
  stage: OperatorType;
  stageIndex: number;
  data: TraceRecycle[] | TraceCompression[] | TraceEmission[];
};

export type wasteUnit = {
  id: string;
  type: string;
  unit: string;
  quantity: string;
  convertKg: string;
  price: string;
  inputUnit: string;
};

export type UncollectEmission = {
  id: string;
  operatorId: string;
  siteId: string;
  areaId: string;
  description: string;
  type: string;
  weight: string;
  datetime: string;
  wasteUnitId?: number;
  wasteUnitQuantity?: string;
  nextStage: boolean;
  wasteUnit?: WasteUnit;
  collectionOperatorName?: string;
  emissionStatus: string;
  tenant?:
    | (TenantBase & {
        tenantNumber: string;
        industry: string;
        isDashboardEnabled: boolean;
        contactName: string;
        contactEmail: string;
        icCards?: ICCardItem[] | null;
        barcode?: string | null;
      })
    | null;
};

export type UnCollectEmissionObj = {
  page: number;
  total: number;
  data: UncollectEmission[];
};

export enum EmissionStatus {
  UnRequest = 'UnRequest',
  Requested = 'Requested',
  Close = 'Closed',
}
export type Emission = {
  datetime: string;
  operatorName: string;
  userId: string;
  userName: string;
  description: string;
  weight: string;
  emissionStatus: EmissionStatus;
};
export type EmissionRequest = {
  datetime: string;
  userId: string;
  userName: string;
};
export type EmissionCollect = {
  datetime: string;
  userId: string;
  userName: string;
  operatorName: string;
  ediNumber: string;
};
export type EmissionData = {
  id: string;
  emission: Emission;
  request?: EmissionRequest;
  collect?: EmissionCollect;
};

export type Site = {
  id: string;
  name: string;
  childSites: CommonSite[];
};

export type CommonSite = {
  id: string;
  name: string;
};

export type CollectHistory = {
  id: string;
  datetime: string;
  areaName: string;
  userId: string;
  userName: string;
  description: string;
  weight: string;
  targetIds: string[];
  ediNumber: string;
  receivedEdiStatus: EdiStatus;
  byTenant: Record<string, { name: string; weight: string; count: number }>;
  wasteUnitName: string;
};

export type CompressionReceiveData = {
  id: string;
  datetime: string;
  userId: string;
  userName: string;
  deliveryFromAreaName: string;
  deliveryFromSiteName: string;
  description: string;
  weight: string;
  targetType: string;
  targetIds: EdiIdMapper[];
  receptionMethod: string;
};

export type EdiIdMapper = {
  id: string;
  ediNumber?: string;
  ediStatus?: EdiStatus;
};

export enum EdiStatus {
  None = 'None',
  Success = 'Success',
  Failed = 'Failed',
  Processing = 'Processing',
  TransportDone = 'TransportDone',
  ProcessDone = 'ProcessDone',
  TransportError = 'TransportError',
  ProcessError = 'ProcessError',
}

export type CompressionCompleteData = {
  id: string;
  datetime: string;
  userId: string;
  userName: string;
  deliveryToOperatorName: string;
  description: string;
  grade: string;
  weight: string;
  targetType: string;
  targetIds: string[];
};

export type Compression = {
  compressionId: string;
  datetime: string;
  userId: string;
  userName: string;
  weight: string;
  description: string;
  grade: { id: number; name: string };
  gradeId: number;
  siteId: string;
  packingStyle: string;
  materialName?: string;
  totalInputWeight: string;
};

export type RecycleReceive = {
  deliveryId: string;
  deliveriedDatetime: string;
  deliveryFromOperatorName: string;
  grade: string;
  weight: string;
  targetType: string;
  targetIds: string[];
  description: string;
  ediNumber?: string;
  ediStatus?: EdiStatus;
};

export type RecycleReceiveData = {
  id: string;
  datetime: string;
  userId: string;
  userName: string;
  deliveryFromOperatorName: string;
  description: string;
  weight: string;
  targetType: string;
  targetIds: EdiIdMapper[];
  receptionMethod: string;
};

export type RecycleCompleteData = {
  id: string;
  datetime: string;
  userId: string;
  userName: string;
  materialType: { id: number; name: string };
  grade: { id: number; name: string };
  weight: string;
  targetType: string;
  targetIds: string[];
  description: string;
  materialTypeId: number;
  gradeId: number;
  siteId: string;
  totalInputWeight: string;
};

export type ScaleOperator = {
  id: string;
  name: string;
  siteId: string;
  waste: { id: string; name: string }[];
};

export type ScaleState = {
  operatorId: string;
  operatorName: string;
  siteId: string;
  wasteId: string;
  wasteName: string;
};

export type WasteUnit = {
  id: number;
  type: string;
  unit: string;
  quantity: string;
  convertKg: number;
  price: number;
  inputUnit: string;
};

export type CategoryItemEmissionType = {
  id: string;
  name: string;
  size: ItemSize;
  materialName: string | null;
  imageUrl?: string | null;
  convertKg: number | null;
  quantity: number | null;
  type: ItemType;
  unit: string | null;
};

export type CategoryItem = {
  id: number | string;
  name: string;
  other: boolean;
  imageUrl?: string | null;
  materialName: string | null;
  size: ItemSize;
  siteId: string;
  typeObject: 'category' | 'wasteUnit';
  convertKg: number | string | null;
  quantity: number | string | null;
  unit: string | null;
  inputUnit?: string | null;
  type?: ItemType;
  categoryId?: string | number;
  categoryName?: string;
  substituteRegistRelations?: SubstituteRegistRelations[];
};

export type SubstituteRegistRelations = {
  fromSiteId: string;
  toSiteId: string;
};

export type CustomizeItemBreadcrumbs = {
  operatorName?: string;
  siteName?: string;
  categoryName?: string;
};

export type SectionFloorBreadcrumbs = {
  operatorName?: string;
  siteName?: string;
  sectionName?: string;
};

export type DeleteFloorSectionItem = {
  id: number;
  name: string;
  sectionId?: number;
};
export enum ItemType {
  OPTIONAL = 'Optional',
  FIX = 'Fixed',
}

export type SectionItem = {
  id: number;
  name: string;
  siteId: string;
};

export type FloorItem = {
  id: number;
  name: string;
  sectionId: number;
};

export type BrandSubItem = {
  id: number;
  name: string;
};

export type BrandItem = {
  id: string;
  name: string;
  address: string;
  imageUrl: string;
  operatorId: string;
  brandItems: BrandSubItem[];
  totalUnassignedItems?: number;
};

export type TareItem = {
  id: number;
  name: string;
  weight: string;
  siteId?: string;
  tenants?: {
    id: number;
    name?: string;
  }[];
};

export type DownloadResourceDataForm = {
  operatorType: OperatorType[];
  startDate: string;
  endDate: string;
};

export type OperatorTypeForm = {
  operatorType: OperatorType[];
};

export type SelectRangeDownloadForm = {
  startTime: string;
  endTime: string;
};

export type DistrictError = {
  key: string;
  error: boolean;
};

export type BarcodeICCardInput = {
  barcode: string;
  icCard: string;
};

export type BarcodeInput = {
  barcode: string;
};

export enum InvoiceStatus {
  Draft = 'Draft',
  Enqueue = 'Enqueue',
  Failed = 'Failed',
  Success = 'Success',
}

export type TareData = {
  page: number;
  total: number;
  tares: TareItem[];
};

export type InputString = string | undefined;

export type TCollectEmission = {
  id: string;
  operatorId: string;
  siteId: string;
  areaId: string;
  description: string;
  type: string;
  weight: string;
  datetime: string;
  wasteUnitQuantity: string;
  wasteUnit: TCollectWasteUnit;
  isEdiEnabled: boolean;
  collectionSiteId: string;
  collectionSiteName: string;
  materialName: string;
};

export type TCollectWasteUnit = {
  id: number;
  categoryId: number;
  wasteTypeMasterId: number;
  recycleMethodId: number;
  name: string;
  materialName: string;
  imageUrl: string;
  sortIndex: number;
  type: string;
  unit: string;
  quantity: string;
  convertKg: string;
  price: number;
  inputUnit: string;
  size: string;
  isEdiEnabled: boolean;
  registDatetime: string;
  registUserId: string;
  updateDatetime: string;
  updateUserId: string;
  ediLargeCategoryId?: string;
  ediMediumCategoryId?: string;
  ediSmallCategoryId?: string;
  wasteId?: string;
  operatorId?: string;
  deleteUserId?: string;
  deleteDatetime?: string;
};

export type TArea = {
  siteId: string;
  siteName: string;
  areaId: string;
  areaName: string;
  areaLongitude: string;
  areaLatitude: string;
  emissionFlg: boolean;
  collectRequestFlg: boolean;
};

export type TCreateCollectBySiteId = {
  id: string;
  data: {
    emissionIds: string[];
  };
};

export type Truck = {
  id: number;
  truckNumber: string;
};

export type ResTruck = {
  total: number;
  page: number;
  trucks: Truck[];
};

export type ProvincesDistricts = {
  province: string[];
  district: string[];
};

export type UserCompression = {
  id: number;
  name: string;
  site: {
    id: string;
    name: string;
  };
};

export type Option = {
  label: string;
  value: string;
};

export type UserCompressionInfo = {
  date: string;
  siteId: string;
  receives: Receive[];
  compressions: Receive[];
};

export type Receive = {
  id: string;
  type: string;
  weight: string;
};

export type UserCompressionCreateRequest = {
  gradeId: string | number;
  weight: string;
  materialName: string;
  processingMethod: string;
  type: string;
  packingStyle: string;
  inputResources: Omit<PreRegisSelectedResource, 'materialName'>[];
  prevProcessingObjectId?: string;
};

export type TSyncError = {
  token: string;
  refreshToken: string;
  records: WasteRegistrationOfflineRequest[];
  createdAt: string;
  status: number;
};

export type PurchaseReceive = {
  deliveryId: number;
  datetime: string;
  operatorName: string;
  areaName: string;
  weight: string;
  siteIds: string[];
  description: string;
};

export type CommonType = {
  id: number;
  name: string;
};

export type Breadcrumb = {
  label: string;
  href?: string;
};

export type PreRegisResource = {
  processingObjectId: string;
  materialName: string;
  remainingWeight: string;
  receivedDatetime: string;
  site: {
    id: string;
    name: string;
  };
  grade?: {
    id: string;
    name: string;
  };
};

export type PreRegisSelectedResource = {
  processingObjectId: string;
  weight: string;
  materialName: string;
};

export type SelectedResources = Record<
  string,
  {
    weight: string;
    materialName: string;
    checked: boolean;
    hasError: boolean;
  }
>;

export type ResourcePreRegisFilter = {
  siteIds?: string[];
  receivedDatetime?: [string, string];
  materialName?: string;
};

export type ExternalScaleConnectionsHistoryItem = {
  id: string;
  linkDateTime: string;
  status: ImportStatus;
  importDateTime: string;
  fileSize: number;
};

export type TeraokaImportHistories = {
  page: number;
  total: number;
  data: ExternalScaleConnectionsHistoryItem[];
};

export type Station = {
  id: number;
  name: string;
};

export type Sites = {
  id: string;
  name: string;
  siteStations: Station[];
};

export type EmissionsSiteStation = {
  page: number;
  total: number;
  sites: Sites[];
};

export type AverageEmissionStatistics = {
  id: string;
  name: string;
  latitude: string;
  longitude: string;
  avgWeight: string;
  operatorName: string;
  siteStations: Station[];
};

export type DashboardsPoolResources = {
  date: string;
  siteStations: Station[];
  siteId: string;
  siteType: string;
  siteName: string;
  collectionSites: CommonSite[];
  materialName: string;
  englishMaterialName: string;
  wasteTypeMaster: string[];
  weight: string;
};

export type RatioItem = {
  name: string;
  weight: string;
  rate: string;
  type?: string;
};

export type DashboardsPoolRatioData = {
  total: string;
  items: RatioItem[];
};

// Tenant
export type InputTenantItem = {
  id: string;
  name: string;
  price: number;
};

export type TenantForm = {
  siteId: string;
  name: string;
  sectionId: string;
  floorId: string;
  brandId: string;
  industry: string;
  inputTenantItems: InputTenantItem[];
  tenantNumber: string;
  contactName: string;
  contactEmail: string;
  isDashboardEnabled: boolean;
};

export type TenantSiteForm = {
  name: string;
  sectionId: string;
  floorId: string;
  industry: string;
  tenantNumber: string;
  contactName: string;
  contactEmail: string;
  barcode: string;
  icCards: { card: string }[];
};

// Export Data Invoice
export type FilterInvoiceData = {
  dateFrom: string;
  dateTo: string;
};

export type TenantInvoiceItem = {
  itemName: string;
  weight: string;
  price: string;
  cost: string;
};

export type InvoiceData = {
  dateFrom: string;
  dateTo: string;
  invoices: {
    id: string;
    name: string;
    tenantNumber: string;
    totalCost: string;
    tenantItems: TenantInvoiceItem[];
  }[];
};

// Tenant Dashboards
export type RateByWasteUnit = {
  wasteUnitName: string;
  weight: number;
  rate: number;
};

export type DashboardsTenantsRateWasteUnits = {
  totalWeight: string;
  rateByWasteUnits: RateByWasteUnit[];
  itemMaxWeight?: string | null;
  recycleItemMinWeight?: string | null;
};

export type RateByRecycleMethod = {
  id: number;
  name: string;
  englishName: string;
  type: string;
  weight: string;
  rate: string;
};

export type DashboardsTenantsRateRecycleMethods = {
  totalWeight: string;
  totalRecycleRate: string;
  recycleMethods: RateByRecycleMethod[];
};

export type RateByWasteCost = {
  wasteUnitName: string;
  weight: string;
  price: number;
  cost: string;
  rate: string;
};

export type DashboardsTenantsRateWasteCosts = {
  totalCost: string;
  rateByWasteCost: RateByWasteCost[];
};

type WeightData = {
  name: string;
  weight: string;
};

export type DashboardsTenantsWastes = {
  date: string;
  day: number;
  month: number;
  year: number;
  data: WeightData[];
};

export type DashboardsTenantsCompareTotalEmissionWeight = {
  tenantName: string;
  totalWeight: string;
  rate: string;
};

export type DashboardsTenantsCompareRecycle = {
  siteName: string;
  tenantName: string;
  industry: string;
  currentMonth: string;
  previousMonth: string;
  siteRank: string;
  overallRank: OverallRank;
};

export type RateCO2ByWasteUnits = {
  wasteUnitName: string;
  CO2: string;
  rate: string;
};

export type DashboardsTenantsRateCO2WasteUnits = {
  totalCO2: string;
  rateCO2ByWasteUnits: RateCO2ByWasteUnits[];
};

export type TenantUpdateParams = Omit<Tenant, 'icCards'> & {
  icCards?: {
    id?: number;
    card: string;
  }[];
};

export type TenantBase = {
  id: number;
  name: string;
  section?: {
    id: number;
    name: string;
  };
  floor?: {
    id: number;
    name: string;
  };
};

export type GetEmissionParams = Pick<GetListParams, 'page' | 'take'> & {
  dateFrom?: string;
  dateTo?: string;
  wasteUnitNames?: string[];
  tenantIds?: string[];
  status?: string[];
};
