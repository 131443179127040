// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import 'react-calendar/dist/Calendar.css';
import styles from './UpdateMailTemplateModal.module.scss';
import { IconClose } from 'components/atoms/icons/IconClose';
import UpdateMailTemplateForm, {
  UpdateMailTemplate,
} from 'components/molecules/UpdateMailTemplateForm/UpdateMailTemplateForm';
import { TimeChoosen } from 'pages/AdminInvoiceManagement/AdminInvoiceManagement';
import { useEffect, useState } from 'react';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';

export type Props = {
  onClose: () => void;
  timeChoosen: TimeChoosen;
};

export const UpdateMailTemplateModal = (props: Props) => {
  const [initForm, setInitForm] = useState<UpdateMailTemplate>();
  const api = createAxios();
  useEffect(() => {
    api
      .get<UpdateMailTemplate>('/invoices/invoice-template')
      .then((res) => {
        const { title, body } = res.data;
        setInitForm({
          title: title,
          body: body ?? '',
        });
      })
      .catch((e) => {
        handleError(e);
      });
  }, []);
  return (
    <div className={styles.updateMailTemplateModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>デフォルトメールを更新</p>
            <div className={styles.top}>
              <IconClose onClick={props.onClose} />
            </div>
          </div>

          <UpdateMailTemplateForm
            timeChoosen={props.timeChoosen}
            onSuccess={props.onClose}
            initForm={initForm}
          />
        </div>
      </Modal>
    </div>
  );
};
