// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { ErrorText } from 'components/atoms/ErrorText';
import { Label } from 'components/atoms/Label';
import DOMPurify from 'dompurify';
import { TimeChoosen } from 'pages/AdminInvoiceManagement/AdminInvoiceManagement';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createAxios } from 'ts/createAxios';
import { handleError } from 'util/errorHandler';
import { validateMaxLength, validateRequiredField } from 'util/validator';
import { InputDataListField } from '../InputDataListField';
import { TextAreaDataListField } from '../TextAreaDataListField';
import styles from './UpdateMailTemplateForm.module.scss';

export type Props = {
  timeChoosen: TimeChoosen;
  onSuccess: () => void;
  initForm?: UpdateMailTemplate;
};

export type UpdateMailTemplate = {
  title: string;
  body: string;
};

export type DefaultInformation = {
  url: string;
  email: string;
};

const UpdateMailTemplateForm: React.FC<Props> = (props: Props) => {
  const [isEditable, setEditable] = useState(false);
  const api = createAxios();
  const { t } = useTranslation();
  const handleUpdateDefaultMailTemplate = (value: UpdateMailTemplate) => {
    api
      .put('/invoices/default-template', {
        title: value.title.trim(),
        bodyTemplate: DOMPurify.sanitize(value.body.trim()),
      })
      .then(() => {
        toast.success(t('messages.M_011'));
        props.onSuccess();
      })
      .catch((e) => {
        handleError(e);
      });
  };

  return (
    <div className={styles.updateMailTemplateForm}>
      <Form<UpdateMailTemplate>
        initialValues={props.initForm}
        onSubmit={() => {}}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values) => {
          return {
            title: validateRequiredField(values.title, 'メールタイトル'),
            body: validateRequiredField(values.body, 'メール本文'),
          };
        }}
      >
        {(props) => {
          const bodyError =
            props.touched &&
            props.touched.body &&
            props.errors &&
            props.errors.body;
          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <InputDataListField
                  validate={(value) =>
                    validateMaxLength(value, 100, 'メールタイトル')
                  }
                  label={'メールタイトル'}
                  placeholder={'メールタイトル'}
                  name="title"
                  required
                  maxlength={100}
                />

                <div
                  className={`${styles.textAreaField} ${
                    !isEditable ? styles.hidden : ''
                  }`}
                >
                  <TextAreaDataListField
                    required
                    name={'body'}
                    label={'メール本文'}
                    placeholder={'メール本文'}
                    onBlur={() => {
                      setEditable(false);
                    }}
                    handleChange={(value) => {
                      props.form.mutators.setValue('body', value);
                    }}
                    maxlength={5000}
                  />
                </div>
                {!isEditable && (
                  <div>
                    <Label text={'メール本文'} tag={'div'} required />
                    <div
                      className={`${styles.previewBody} ${
                        bodyError ? styles.previewError : ''
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          props.values?.body ? props.values?.body.trim() : ''
                        ),
                      }}
                      onClick={() => {
                        setEditable(true);
                      }}
                    />
                  </div>
                )}
                {bodyError && (
                  <ErrorText
                    text={props.errors?.body}
                    className={styles.error}
                  />
                )}
              </div>
              <div className={styles.submit}>
                <button
                  disabled={props.invalid}
                  className={styles.submitButton}
                  type="button"
                  onClick={() => {
                    handleUpdateDefaultMailTemplate(props.values);
                  }}
                >
                  更新する
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default UpdateMailTemplateForm;
