// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { SelectField } from 'components/molecules/SelectField';

import styles from './WasteInputForm.module.scss';
import { InputDataListField, Suggestion } from '../InputDataListField';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { WasteUnitItem } from 'pages/AdminCustomizeItemsDetail';
import { useTranslation } from 'react-i18next';
import {
  validateNumberField,
  validatePriceField,
  validateRequiredField,
  validateSelectField,
  validateStringField,
} from 'util/validator';
import {
  EmissionFactor,
  WasteTypeMaster,
  fetchEmissionFactors,
  fetchWasteTypeMaster,
  fetchWasteUnit,
} from 'apis/operator_site/siteApi';
import { REGEX_OBJECT, typeImages, unitOptions } from 'util/ConstantValues';
import UploadFiles from '../UploadFiles';
import { Label } from 'components/atoms/Label';
import CustomRadioGroup from '../CustomRadioGroup';
import Toggle from 'components/atoms/Toggle';
import {
  LargeCategories,
  MediumCategories,
  SmallCategories,
  WasteInput,
} from 'util/siteManagementTypes';
import { fetchEdiCategories } from 'apis/commons';
import {
  createWasteUnit,
  getCollectsStageSites,
  updateWasteUnit,
} from 'apis/waste_unit';
import { handleError } from 'util/errorHandler';
import { PrintLabel } from 'util/Enums';

export enum CreateItemInputType {
  add,
  update,
}

interface Props {
  type: CreateItemInputType;
  wasteUnitNames: Suggestion[];
  materialNames: Suggestion[];
  categoryName: string;
  siteName: string;
  handleFetchData: () => void;
  detailItem?: WasteUnitItem;
}

const CreateItemInputForm: React.FC<Props> = ({
  type,
  wasteUnitNames,
  materialNames,
  siteName,
  categoryName,
  detailItem,
  handleFetchData,
}) => {
  const { t } = useTranslation();
  const { categoryId, siteId } = useParams<{
    categoryId: string;
    siteId: string;
  }>();

  const [recycleMethods, setRecycleMethods] = useState<EmissionFactor[]>([]);
  const [wasteTypeMaster, setWasteTypeMaster] = useState<WasteTypeMaster[]>([]);
  const [wasteUnit, setWasteUnit] = useState<WasteUnitItem>();
  const [isEdiEnabled, setIsEdiEnabled] = useState<boolean>(false);
  const [formHasError, setFormHasError] = useState<boolean>(false);
  const [largeCategories, setLargeCategories] = useState<LargeCategories[]>([]);
  const [mediumCategories, setMediumCategories] = useState<MediumCategories[]>(
    []
  );
  const [smallCategories, setSmallCategories] = useState<SmallCategories[]>([]);
  // 該当品目の代理登録が可能な回収拠点リスト
  const [substituteRegistSites, setSubstituteRegistSites] = useState<
    { id: string; name: string }[]
  >([]);
  // 代理登録データ取得が完了したかどうかを追跡する
  const [updated, setUpdated] = useState(true);

  // 該当品目の代理登録が可能な回収拠点リストの取得
  const fetchCollectsStageSites = (materialName) => {
    getCollectsStageSites(materialName, (data) => {
      setSubstituteRegistSites(data ?? []);
    });
  };

  useEffect(() => {
    if (detailItem) {
      setUpdated(false);
      fetchWasteUnit(detailItem.id, (data) => {
        if (data) {
          setWasteUnit(data);
          setIsEdiEnabled(data.isEdiEnabled);
        }
      }).then(() => setUpdated(true));
    }
    fetchEmissionFactors((data) => {
      data && setRecycleMethods(data.map((item) => item));
    });
    fetchWasteTypeMaster((data) => {
      data && setWasteTypeMaster(data);
    });
    fetchEdiCategories((data) => {
      if (data) {
        setLargeCategories(data.largeCategories);
        setMediumCategories(data.mediumCategories);
        setSmallCategories(data.smallCategories);
      }
    });
  }, []);

  // 材料名が取得できた場合、代理登録が可能な回収拠点リストを取得する
  useEffect(() => {
    if (wasteUnit?.materialName) {
      fetchCollectsStageSites(wasteUnit.materialName);
    }
  }, [wasteUnit?.materialName]);

  const handleCreateUpdateWasteUnit = (formData: WasteInput) => {
    try {
      if (type === CreateItemInputType.add) {
        createWasteUnit({ isEdiEnabled, form: formData }, () => {
          toast.success(t('messages.M_010'));
          handleFetchData();
        });
      } else if (detailItem) {
        updateWasteUnit({ detailItem, isEdiEnabled, form: formData }, () => {
          toast.success(t('messages.M_011'));
          handleFetchData();
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  const mediumCategoryOptions = (bigId: number) => {
    const data = mediumCategories.filter(
      (option) => option.ediLargeCategoryId === bigId
    );
    if (data) {
      return data.map((i) => {
        return { label: i.name, value: i.id };
      });
    }

    return [];
  };

  const smallCategoryOptions = (mediumId: number) => {
    const data = smallCategories.filter(
      (option) => option.ediMediumCategoryId === mediumId
    );
    if (data) {
      return data.map((i) => {
        return { label: i.name, value: i.id };
      });
    }

    return [];
  };

  return (
    <div className={styles.createBody}>
      <Form<WasteInput>
        onSubmit={(values: WasteInput) => {
          handleCreateUpdateWasteUnit(values);
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={
          type === CreateItemInputType.add
            ? {
                emissionType: 'Fixed',
                categoryId: categoryId,
                siteId: siteId,
              }
            : {
                itemName: wasteUnit?.name,
                emissionType: wasteUnit?.type,
                categoryId: categoryId,
                siteId: siteId,
                materialName: wasteUnit?.materialName,
                convertKg: Number(wasteUnit?.convertKg),
                emissionMethod: wasteUnit?.emissionMethod?.name,
                quantity: Number(wasteUnit?.quantity),
                unit: unitOptions.find((unit) => unit.value === wasteUnit?.unit)
                  ? wasteUnit?.unit
                  : unitOptions[0].value,
                photo: wasteUnit?.imageUrl,
                inputUnit: wasteUnit?.inputUnit,
                wasteTypeMasterId: String(wasteUnit?.wasteTypeMaster.id),
                recycleMethodId: String(wasteUnit?.recycleMethod.id),
                price: wasteUnit?.price ? String(wasteUnit?.price) : '',
                substituteRegistToSiteId:
                  wasteUnit?.substituteRegistRelations &&
                  wasteUnit.substituteRegistRelations.length > 0
                    ? wasteUnit.substituteRegistRelations[0].toSiteId
                    : undefined,
                deleteSubstituteRegistToSiteId:
                  wasteUnit?.substituteRegistRelations &&
                  wasteUnit.substituteRegistRelations.length > 0
                    ? wasteUnit.substituteRegistRelations[0].toSiteId
                    : undefined,
                substituteRegist:
                  wasteUnit?.substituteRegistRelations &&
                  wasteUnit.substituteRegistRelations.length > 0
                    ? 'true'
                    : 'false',
                ediLargeCategoryId: wasteUnit?.ediLargeCategoryId,
                ediMediumCategoryId: wasteUnit?.ediMediumCategoryId,
                ediSmallCategoryId: wasteUnit?.ediSmallCategoryId,
              }
        }
        validate={(values) => {
          return {
            itemName: validateStringField(
              t('category_details.thead_item'),
              100,
              values.itemName
            ),
            materialName: validateStringField(
              t('category_details.thead_material'),
              100,
              values.materialName
            ),
            convertKg:
              values.emissionType === 'Fixed'
                ? validateNumberField(t('common.weight'), 10, values.convertKg)
                : undefined,
            quantity:
              values.emissionType === 'Fixed'
                ? validateNumberField(t('common.amount'), 10, values.quantity)
                : undefined,
            unit:
              values.emissionType === 'Fixed'
                ? validateSelectField(t('common.unit'), values.unit)
                : undefined,
            inputUnit:
              values.emissionType === 'Fixed'
                ? validateStringField(
                    t('common.input_unit'),
                    10,
                    values.inputUnit
                  )
                : undefined,
            wasteTypeMasterId: validateRequiredField(
              values.wasteTypeMasterId,
              '廃棄物種類'
            ),
            recycleMethodId: validateRequiredField(
              values.recycleMethodId,
              '処理方法'
            ),
            price: values.price
              ? validatePriceField('単価', 9, Number(values.price))
              : undefined,
            ediLargeCategoryId: isEdiEnabled
              ? validateSelectField('大分類コード', values.ediLargeCategoryId)
              : undefined,
            ediMediumCategoryId: isEdiEnabled
              ? validateSelectField('中分類コード', values.ediMediumCategoryId)
              : undefined,
            ediSmallCategoryId: isEdiEnabled
              ? validateSelectField('小分類コード', values.ediSmallCategoryId)
              : undefined,
          };
        }}
      >
        {(props) => {
          const recycleOptions = recycleMethods
            .filter(
              (i) =>
                i.wasteTypeMaster.id === Number(props.values.wasteTypeMasterId)
            )
            .map((i) => {
              return { label: i.recycleMethod.name, value: i.recycleMethod.id };
            });

          return (
            <form onSubmit={props.handleSubmit} className={styles.formInput}>
              <div className={styles.inputFrame}>
                <SelectField
                  label={t('operator_management.thead_site') || '排出拠点'}
                  name="siteId"
                  options={[{ value: siteId, label: siteName }]}
                  disabled
                />
                <SelectField
                  label={t('category_management.thead_category') || 'カテゴリ'}
                  name="categoryId"
                  options={[{ value: categoryId, label: categoryName }]}
                  disabled
                />
                <InputDataListField
                  label={t('category_details.thead_item') || '品目名'}
                  placeholder={t('category_details.thead_item') || '品目名'}
                  name="itemName"
                  required
                  dataSuggestions={wasteUnitNames}
                  onSelectSuggestItem={(value) => {
                    props.form.mutators.setValue('itemName', value);
                  }}
                  maxlength={100}
                />
                <div className={styles.edi}>
                  <Label
                    className={styles.label}
                    text={t('category_details.edi') || ''}
                    tag={'div'}
                    required
                  />
                  <Toggle value={isEdiEnabled} setValue={setIsEdiEnabled} />
                </div>
                <InputDataListField
                  label={t('category_details.thead_material') || '材料名'}
                  placeholder={t('category_details.thead_material') || '材料名'}
                  name="materialName"
                  required
                  dataSuggestions={materialNames}
                  onSelectSuggestItem={(value) => {
                    props.form.mutators.setValue('materialName', value);
                  }}
                  maxlength={100}
                  description="材料名を変更するとリサイクルチェーンに影響します。"
                />
                <SelectField
                  label="廃棄物種類"
                  placeholder="廃棄物種類"
                  required
                  disabled={type === CreateItemInputType.update}
                  name="wasteTypeMasterId"
                  options={wasteTypeMaster.map((i) => {
                    return { label: i.name, value: i.id };
                  })}
                  onChange={() => {
                    props.form.mutators.setValue('recycleMethodId', '');
                  }}
                />
                <SelectField
                  label="処理方法"
                  placeholder="処理方法"
                  required
                  value={props.values.recycleMethodId}
                  disabled={
                    type === CreateItemInputType.update ||
                    !props.values.wasteTypeMasterId
                  }
                  name="recycleMethodId"
                  options={recycleOptions}
                />
                <SelectField
                  label="大分類コード"
                  placeholder="大分類コード"
                  required={isEdiEnabled}
                  value={props.values.ediLargeCategoryId}
                  name="ediLargeCategoryId"
                  options={largeCategories.map((i) => {
                    return { label: i.name, value: i.id };
                  })}
                  onChange={(value) => {
                    if (value != props.values.ediLargeCategoryId) {
                      props.form.mutators.setValue('ediMediumCategoryId', '');
                      props.form.mutators.setValue('ediSmallCategoryId', '');
                    }
                  }}
                />
                <SelectField
                  label="中分類コード"
                  placeholder="中分類コード"
                  required={isEdiEnabled}
                  value={props.values.ediMediumCategoryId}
                  name="ediMediumCategoryId"
                  options={mediumCategoryOptions(
                    Number(props.values.ediLargeCategoryId)
                  )}
                  disabled={!props.values.ediLargeCategoryId}
                  onChange={(value) => {
                    if (value != props.values.ediMediumCategoryId.toString())
                      props.form.mutators.setValue('ediSmallCategoryId', '');
                  }}
                />
                <SelectField
                  label="小分類コード"
                  placeholder="小分類コード"
                  required={isEdiEnabled}
                  value={props.values.ediSmallCategoryId}
                  name="ediSmallCategoryId"
                  options={smallCategoryOptions(
                    Number(props.values.ediMediumCategoryId)
                  )}
                  disabled={!props.values.ediMediumCategoryId}
                />

                <SelectField
                  label={t('category_details.thead_type') || '品目種類'}
                  required
                  name="emissionType"
                  disabled={type === CreateItemInputType.update}
                  options={[
                    { label: t('common.fixed'), value: 'Fixed' },
                    { label: t('common.optional'), value: 'Optional' },
                  ]}
                />
                {props.values.emissionType === 'Fixed' && (
                  <>
                    <div className={styles.weightField}>
                      <InputDataListField
                        label={t('common.weight') || '重量'}
                        placeholder="重量"
                        name="convertKg"
                        required
                        type="number"
                        maxlength={10}
                        validRegex={REGEX_OBJECT.numberWithDot2Decimal}
                      />
                      <span className={styles.weightUnit}>kg</span>
                    </div>
                    <div className={styles.quantity}>
                      <div className={styles.quantityFieldWidth}>
                        <InputDataListField
                          label={t('common.amount') || '数量'}
                          placeholder={t('common.amount') || '数量'}
                          name="quantity"
                          required
                          maxlength={10}
                          type="number"
                          validRegex={REGEX_OBJECT.numberWithDot2Decimal}
                        />
                      </div>
                      <div
                        className={`${styles.quantityFieldWidth} ${styles.space}`}
                      >
                        <SelectField
                          label={t('common.unit') || '単位'}
                          placeholder={t('common.unit') || '単位'}
                          required
                          name="unit"
                          options={unitOptions}
                        />
                      </div>
                    </div>
                    <div>
                      <InputDataListField
                        label={t('common.input_unit') || '風体の数え方'}
                        placeholder={t('common.input_unit') || '風体の数え方'}
                        name="inputUnit"
                        required
                        type="text"
                        maxlength={10}
                      />
                    </div>
                  </>
                )}
                <div className={styles.weightField}>
                  <InputDataListField
                    label={'単価'}
                    placeholder="単価"
                    name="price"
                    validRegex={REGEX_OBJECT.nonNegativeNumber}
                    maxlength={9}
                  />
                  <span className={styles.weightUnit}>円</span>
                </div>
                <UploadFiles
                  label={t('common.choose_photo') || '画像を選択'}
                  isImage
                  fileTypes={typeImages}
                  defaultFile={
                    type === CreateItemInputType.update
                      ? detailItem?.imageUrl
                      : ''
                  }
                  onChangePhoto={(e) => {
                    props.form.mutators.setValue('photo', e);
                  }}
                  onError={setFormHasError}
                />
                {type === CreateItemInputType.update && updated ? (
                  <div className={styles.weightField}>
                    <Label
                      text={
                        t('category_details.thead_substitute_regist') ||
                        '代理登録'
                      }
                      description={
                        wasteUnit?.category?.site.printLabel ===
                          PrintLabel.QRCode &&
                        'QR回収が設定されているため、変更できません。'
                      }
                    />
                    <CustomRadioGroup
                      disabled={
                        wasteUnit?.category?.site.printLabel ===
                        PrintLabel.QRCode
                      }
                      name="substituteRegist"
                      defaultChecked={props.values.substituteRegist}
                      options={[
                        { label: 'はい', value: 'true' },
                        { label: 'いいえ', value: 'false' },
                      ]}
                      onchange={(e) => {
                        props.form.mutators.setValue('substituteRegist', e);

                        if (e === 'true') {
                          fetchCollectsStageSites(props.values.materialName);
                        } else {
                          setSubstituteRegistSites([]);
                        }
                      }}
                    />
                    <SelectField
                      label={
                        t('category_details.thead_substitute_regist_site') ||
                        '代理登録拠点'
                      }
                      required
                      name="substituteRegistToSite"
                      placeholder={t('common.select') || '選択する'}
                      value={props.values.substituteRegistToSiteId}
                      onChange={(e) => {
                        props.form.mutators.setValue(
                          'substituteRegistToSiteId',
                          e
                        );
                      }}
                      options={substituteRegistSites.map((i) => ({
                        label: i.name,
                        value: i.id,
                      }))}
                      disabled={
                        substituteRegistSites.length === 0 ||
                        props.values.substituteRegist === 'false' ||
                        wasteUnit?.category?.site.printLabel ===
                          PrintLabel.QRCode
                      }
                    />
                  </div>
                ) : null}
              </div>

              <div className={styles.line} />

              <div className={styles.submit}>
                <button
                  className={styles.submitButton}
                  disabled={props.invalid || formHasError}
                  type="submit"
                >
                  {type === CreateItemInputType.add
                    ? t('common.modal_create.add_button')
                    : t('common.modal_update.edit_button')}
                </button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default CreateItemInputForm;
