import { WasteUnitItem } from 'pages/AdminCustomizeItemsDetail';
import { createAxios } from 'ts/createAxios';
import { getApiPath } from 'util/commons';
import { API_ENDPOINTS } from 'util/endPoints';
import { OperatorType } from 'util/Enums';
import { handleError } from 'util/errorHandler';
import { WasteInput } from 'util/siteManagementTypes';

export const getCollectsStageSites = async (
  materialName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  successCallback?: (data: any) => void
) => {
  const api = createAxios();
  const { data } = await api.get(
    getApiPath(API_ENDPOINTS.RECYCLE_CHAIN_STAGE_SITES, {
      stage: OperatorType.Collects,
      materialName: materialName,
    })
  );
  successCallback?.(data);
};

export const getCommonWasteUnits = async (params?: {
  successCallback?: (data: { name: string }[]) => void;
}) => {
  const { successCallback } = params ?? {};

  try {
    const { data } = await createAxios().get<{ name: string }[]>(
      API_ENDPOINTS.COMMONS_WASTE_UNIT_NAMES
    );

    if (data) {
      successCallback?.(data);
    }
  } catch (error) {
    handleError(error);
  }
};

export const createWasteUnit = async (
  params: { isEdiEnabled: boolean; form: WasteInput },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.post(API_ENDPOINTS.WASTE_UNITS, {
      categoryId: Number(params.form.categoryId),
      name: params.form.itemName.trim(),
      materialName: params.form.materialName.trim(),
      type: params.form.emissionType,
      size: 'Large',
      image: params.form.photo,
      wasteTypeMasterId: Number(params.form.wasteTypeMasterId),
      recycleMethodId: Number(params.form.recycleMethodId),
      isEdiEnabled: params.isEdiEnabled ?? false,
      ediLargeCategoryId: Number(params.form.ediLargeCategoryId) || null,
      ediMediumCategoryId: Number(params.form.ediMediumCategoryId) || null,
      ediSmallCategoryId: Number(params.form.ediSmallCategoryId) || null,
      ...(params.form.price && { price: Number(params.form.price) }),
      ...(params.form.emissionType === 'Fixed' && {
        convertKg: Number(params.form.convertKg),
        quantity: Number(params.form.quantity),
        unit: params.form.unit,
        inputUnit: params.form.inputUnit.trim(),
      }),
    });
    data && successCallback?.(data);
  } catch (error) {
    handleError(error);
  }
};

export const updateWasteUnit = async (
  params: {
    detailItem: WasteUnitItem;
    isEdiEnabled: boolean;
    form: WasteInput;
  },
  successCallback?: (data: { data: string }) => void
) => {
  const api = createAxios();
  try {
    const data = await api.put(
      getApiPath(API_ENDPOINTS.WASTE_UNITS_UPDATE, {
        id: params.detailItem.id,
      }),
      {
        categoryId: Number(params.form.categoryId),
        name: params.form.itemName.trim(),
        materialName: params.form.materialName.trim(),
        type: params.form.emissionType,
        size: 'Large',
        id: Number(params.detailItem.id),
        wasteTypeMasterId: Number(params.form.wasteTypeMasterId),
        recycleMethodId: Number(params.form.recycleMethodId),
        substituteRegistToSiteId:
          params.form.substituteRegist === 'true' &&
          params.form.substituteRegistToSiteId
            ? params.form.substituteRegistToSiteId
            : undefined,
        deleteSubstituteRegistToSiteId:
          params.form.substituteRegist === 'false' &&
          params.form.deleteSubstituteRegistToSiteId
            ? params.form.deleteSubstituteRegistToSiteId
            : undefined,
        isEdiEnabled: params.isEdiEnabled ?? false,
        ediLargeCategoryId: Number(params.form.ediLargeCategoryId) || null,
        ediMediumCategoryId: Number(params.form.ediMediumCategoryId) || null,
        ediSmallCategoryId: Number(params.form.ediSmallCategoryId) || null,
        ...(params.form.photo !== params.detailItem?.imageUrl && {
          image: params.form.photo,
        }),
        ...(!params.form.photo && { imageDelete: true }),
        ...(params.form.price && { price: Number(params.form.price) }),
        ...(params.form.emissionType === 'Fixed' && {
          convertKg: Number(params.form.convertKg),
          quantity: Number(params.form.quantity),
          unit: params.form.unit,
          inputUnit: params.form.inputUnit.trim(),
        }),
      }
    );
    data && successCallback && successCallback(data);
  } catch (error) {
    handleError(error);
  }
};
