// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import styles from './HistoryCollectComplete.module.scss';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { createAxios } from 'ts/createAxios';
import { CollectHistory } from 'util/Types';
import { LabelContents } from 'components/molecules/LabelContents';
import {
  getFormatCurrentDate,
  getFormatCurrentDateTime,
  getFormatDateWithCurrentTimezone,
} from 'ts/formatDate';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'util/ConstantValues';
import { normalizeNumber } from 'util/commons';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { getCollectHistoryCSVDataGroupedByTenant } from 'util/csvExportUtils';

export type Props = {
  id?: string;
};

export const HistoryCollectComplete = () => {
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { t } = useTranslation();
  const [collectedData, setCollectedData] = useState<CollectHistory[]>([]);

  const callApi = async () => {
    const res = await commonsApi.get('/work-histories/collect/delivered');
    setCollectedData(res.data);
  };

  useEffect(() => {
    callApi();
  }, []);
  return (
    <div className={styles.historyCollectCompletes}>
      {collectedData &&
        collectedData.length > 0 &&
        collectedData.map((collect, index) => {
          return (
            <div className={styles.historyCollectCompleteCard} key={index}>
              <div className={styles.historyCollectCompleteContentCol}>
                <LabelContents
                  label={t('common_history_field.date_time')}
                  contents={getFormatDateWithCurrentTimezone(
                    collect.datetime,
                    DATE_FORMAT.slaYMDHm
                  )}
                />
                <LabelContents
                  label={t('common_history_field.responsible_person')}
                  contents={collect.userId + '(' + collect.userName + ')'}
                />
                <LabelContents
                  label={t('common_history_field.place')}
                  contents={collect.areaName}
                />
                <LabelContents
                  label={t('common_history_field.material_name')}
                  contents={collect.description}
                />
                <LabelContents
                  label={t('common_history_field.weight')}
                  contents={
                    normalizeNumber({
                      value: collect.weight,
                    }) + 'kg'
                  }
                />
                <LabelContents
                  label={t('common_history_field.registration_id')}
                  contents={collect.targetIds.join('\n')}
                />
              </div>
              <div className={styles.historyCollectCompleteActionCol}>
                <CSVLink
                  filename={`${t(
                    'histories.csv_file_name_collect_by_waste_unit'
                  )}_${getFormatCurrentDate()}`}
                  data={getCollectHistoryCSVDataGroupedByTenant(
                    collect,
                    getFormatCurrentDateTime(),
                    t
                  )}
                >
                  <IconDownload />
                </CSVLink>
              </div>
            </div>
          );
        })}
    </div>
  );
};
