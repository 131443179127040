// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Modal } from 'components/atoms/Modal';
import { IconClose } from 'components/atoms/icons/IconClose';
import Barcode from 'react-barcode';
import styles from './BarcodeDetailModal.module.scss';
import 'react-calendar/dist/Calendar.css';
import { TenantItem } from 'components/organisms/AdminTenantManagement';
import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';
import { BarcodeInput } from 'util/Types';
import { mergeClasses } from 'util/commons';
import { useTranslation } from 'react-i18next';
import React from 'react';

type Props = {
  barcode?: TenantItem | BarcodeInput;
  itemName?: string;
  onClose: () => void;
  darkMode?: boolean;
};

const BarcodeDetailModal: React.FC<Props> = ({
  barcode,
  itemName,
  onClose,
  darkMode,
}) => {
  const { t } = useTranslation();
  const handleBarcodeDownload = async () => {
    const barcodeDownload = document.getElementById('barcodeDownload');
    barcodeDownload?.setAttribute('style', 'background: #fff');
    const canvas = await html2canvas(barcodeDownload as HTMLElement);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, `${barcode?.barcode}.png`, 'image/png');
  };

  return (
    <div
      className={mergeClasses(styles.barcodeDetailModal, {
        [styles.darkMode]: darkMode,
      })}
    >
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>{itemName}</p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          <div className={styles.body} id="barcodeDownload">
            <Barcode
              margin={12}
              textMargin={20}
              fontSize={14}
              width={3}
              lineColor={'#000'}
              background={'#fff'}
              value={barcode?.barcode || ''}
              displayValue={false}
            />

            <p>{barcode?.barcode || ''}</p>
          </div>

          <div className={styles.line} />

          <div className={styles.submit}>
            <button
              className={styles.submitButton}
              type="submit"
              onClick={handleBarcodeDownload}
            >
              {t('common.download')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BarcodeDetailModal;
