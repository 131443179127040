// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
// **************************************
// WebView側の定数を以下に設定
// **************************************

// **************************************
// 設定値
// **************************************
import i18n from 'i18n';
import { OperatorMenuItem, OperatorType, RoleType } from './Enums';
import { SiteRecycleItem } from './siteManagementTypes';
import { TenantForm, TenantSiteForm } from './Types';
// APIのタイムアウトの設定
export const API_DEFAULT_TIMEOUT = 150000;
export const POSTAL_CODE_LENGTH = 7;
export const REGEX_OBJECT = {
  number: /^0*?[0-9]\d*$/,
  tenDigitsWith2Decimal:
    /^(?!0{2,})(?:\d{1,10}|(?=\d+\.\d{0,2}?$)[\d.]{1,10})$/,
  numberStringOnly: /^[A-Za-z0-9]*$/,
  numberStringWithJapanese:
    /^([A-Za-z0-9\s]|[ぁ-んァ-ン]|[ぁ-んァ-ン一-龥]|[ｧ-ﾝﾞﾟ]|([ァ-ン]|ー)|[ぁ-ん]|[一-龥][一-龥ぁ-ん])*$/,
  email:
    /^(?![.\-@])(?!.*((\.\.)|(\.-)|(\.@)|(-@)))[A-Za-z0-9'./{}|`!#$%&*+\-=?^_~]*@[A-Za-z0-9]+([-.][A-Za-z0-9]+)*\.[A-Za-z]{2,}$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])[A-Za-z\d^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]{6,20}$/,
  nonNegativeNumber: /^\d+$/,
  decimalNumberWithDot5: /^(?:[0-9]{1,45}(\.[0-9]{0,5})?|[0-9]{1,45})$/,
  decimalNumberWithDot10: /^(?:[0-9]{1,40}(\.[0-9]{0,10})?|[0-9]{1,40})$/,
  positiveIntegerRegex: /^[1-9]\d*$/,
  numberWithCommas: /\B(?=(\d{3})+(?!\d))/g,
  halfWidthCharacter: /^[^\u3000-\uff60\u4e00-\u9faf\u3400-\u4dbf]*$/,
  onlyThreeIntegersAndNotLimitDecimals: /^\d{0,3}(\.\d*)?$/,
  fiveDigitsWith2Decimal: /^(?!0\d)\d{1,5}(\.\d{0,2})?$/,
  fiveDigits: /^(?!0\d)\d{1,5}?$/,
  numberWithDot2Decimal: /^(([1-9]\d*)|([0]{1}))(\.\d{0,2})?$/,
  nativeNumberRegex: /^[0-9.]+$/,
};

export const DATE_FORMAT = {
  full_date: 'YYYY/MM/DD',
  full_date_dash: 'YYYY-MM-DD',
  slaYMDHm: 'YYYY/MM/DD HH:mm',
  slaMDHm: 'MM/DD HH:mm',
  slaYMDHms: 'YYYY/MM/DD HH:mm:ss',
  hhss: 'HH:mm',
  YMDHm: 'YYYYMMDD_HHmm',
  YMD: 'YYYYMMDD',
};
export const typeImages = ['jpg', 'jpeg', 'png', 'heic'];
export const PdfType = ['pdf'];
export const ImagePath = {
  Plastic: '/assets/images/plastic_001.png',
  PlasticPnt: '/assets/images/plastic_002.png',
  defaultImage: '/assets/images/default-image.png',
  defaultImageDarkMode: '/assets/images/default-image-dark-mode.png',
  background404: '/assets/images/p404-bgr.svg',
};

export const operatorsList = [
  { id: 1, type: 'type', name: 'Sun*', postalCode: 122, address: 'Hanoi' },

  { id: 2, type: 'type', name: 'Sun* 2', postalCode: 122, address: 'Hanoi' },

  { id: 2, type: 'type', name: 'Sun* 3', postalCode: 122, address: 'Hanoi' },
];

export const operatorTypeList = [
  { value: OperatorType.Emissions, label: '排出' },
  { value: OperatorType.Collects, label: '回収' },
  { value: OperatorType.Compressions, label: '中間処理 ' },
  { value: OperatorType.Recycle, label: 'リサイクル' },
  { value: OperatorType.Producer, label: '製品化' },
  { value: OperatorType.Purchase, label: '調達' },
  { value: OperatorType.Admin, label: 'Recotech管理' },
];
export const operatorTypeStep = [
  {
    value: OperatorMenuItem.Emissions,
    label: i18n.t('operator_list.emissions') || '排出',
  },
  {
    value: OperatorMenuItem.Collects,
    label: i18n.t('operator_list.collects') || '回収',
  },
  {
    value: OperatorMenuItem.Compressions,
    label: i18n.t('operator_list.compressions') || '中間処理 ',
  },
  {
    value: OperatorMenuItem.Recycle,
    label: i18n.t('operator_list.recycle') || 'リサイクル',
  },
  {
    value: OperatorMenuItem.Producer,
    label: i18n.t('operator_list.producer') || '製品化',
  },
  {
    value: OperatorMenuItem.Purchase,
    label: i18n.t('operator_list.purchase') || '調達',
  },
];

export const OperatorTypeStepEnglish = {
  [OperatorMenuItem.Emissions]: 'Emissions',
  [OperatorMenuItem.Collects]: 'Collects',
  [OperatorMenuItem.Compressions]: 'Compressions',
  [OperatorMenuItem.Recycle]: 'Recycle',
  [OperatorMenuItem.Producer]: 'Producer',
  [OperatorMenuItem.Purchase]: 'Purchase',
};

export const operatorTypeStep2 = [
  { value: OperatorType.Compressions, label: '中間処理 ' },
  { value: OperatorType.Recycle, label: 'リサイクル' },
];

export type SiteItem = {
  id: number;
  dischargerBase: string;
};
export type OperatorItem = {
  id: number;
  discharger: string;
  siteList: SiteItem[];
};

export const PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;
export const MAX_IMAGE_FILE_SIZE = 5;
export const MAX_CSV_FILE_SIZE = 1;
export const QUANTITY_INPUT_MAX_LENGTH = 10;

export const initialFormData: SiteRecycleItem = {
  id: '',
  name: '',
  englishName: '',
  acceptanceGrade: [],
  enrollableType: [],
  enrollableGrade: [],
  recycleMethod: [],
  province: '',
  district: '',
  address: '',
  machineType: [],
  acceptanceMaterial: [],
  packingStyles: [],
  wastePermit: '',
  postalCode: '',
  latitude: '',
  longitude: '',
};

export const operatorTypeData = [
  {
    value: OperatorType.Emissions,
    label: OperatorMenuItem.Emissions,
  },
  {
    value: OperatorType.Compressions,
    label: OperatorMenuItem.Compressions,
  },
  {
    value: OperatorType.Recycle,
    label: OperatorMenuItem.Recycle,
  },
  {
    value: OperatorType.Producer,
    label: OperatorMenuItem.Producer,
  },
];

export enum LanguageType {
  Japanese = '日本語',
  English = 'English',
}

export enum LanguageValue {
  Japanese = 'ja',
  English = 'en',
}

export type LanguageTypeForm = {
  language: LanguageValue;
};

export const languageTypeData = [
  {
    value: LanguageValue.Japanese,
    label: LanguageType.Japanese,
  },
  {
    value: LanguageValue.English,
    label: LanguageType.English,
  },
];

export const listChain = [
  { id: '1', name: 'POOL PROJECT (アパレル由来PP/PE)' },
  { id: '2', name: 'グレードA POOL PROJECT (アパレル由来PP/PE)' },
  {
    id: '3',
    name: 'グレードB 硬質プラ（歯ブラシ・アメニティ - PP/PS） 生ごみ',
  },
  { id: '4', name: 'グレードA POOL PROJECT (アパレル由来PP/PE)' },
  { id: '5', name: 'POOL PROJECT (アパレル由来PP/PE)' },
  { id: '6', name: 'POOL PROJECT (アパレル由来PP/PE) 2' },
  { id: '7', name: 'POOL PROJECT (アパレル由来PP/PE) 3' },
  { id: '8', name: 'POOL PROJECT (アパレル由来PP/PE) 4' },
  { id: '9', name: 'POOL PROJECT (アパレル由来PP/PE) 5' },
  { id: '10', name: 'POOL PROJECT (アパレル由来PP/PE) 6' },
  { id: '11', name: 'POOL PROJECT (アパレル由来PP/PE) 7' },
];

export const unitOptions = [
  { value: '01', label: 'バラ' },
  { value: '02', label: 'コンテナ' },
  { value: '03', label: 'ドラム缶 ' },
  { value: '04', label: '一斗缶' },
  { value: '05', label: 'プラスチック容器' },
  { value: '06', label: '袋' },
  { value: '07', label: 'フレコンバック' },
  { value: '08', label: '段ボール箱  ' },
  { value: '09', label: 'その他' },
];

export const unitLabelMapper = unitOptions.reduce(
  (rs: Record<string, string>, { value, label }) => {
    rs[value] = label;

    return rs;
  },
  {}
);

/** Name of the indexedDb that stores web's data */
export const DATABASE_NAME = 'pool_database';

/**
 * Version of the indexedDb
 * @Note Please increase this constant by 1 each time update the indexedDb's structure.
 */
export const DATABASE_VERSION = 11;

/**
 * Version of the service worker
 * @Note If you want to force update service worker in user's client without any changes, please increase this constant by 1.
 * If not, no need to update this constant
 */
export const WORKER_VERSION = 1;

export const DATA_SYNC_TIME_KEY = 'DATA_SYNC_TIME_KEY';

export const MAP_NIGHT_MODE = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
];

export const MAP_BUBBLE_STROKECOLOR = '#A1F48452';
export const MAP_BUBBLE_FILLCOLOR = '#489933';
export const FILL_OPACITY = 0.35;
export const TOKYO_COORDINATES = {
  lat: 39,
  lng: 139,
};

export const COMPRESSION_METHOD_OPTIONS = [
  {
    id: '圧縮',
    name: '圧縮（Compress）',
  },
  {
    id: '破砕',
    name: '破砕（Shred）',
  },
  {
    id: '保管',
    name: '保管（Store）',
  },
  {
    id: '選別',
    name: '選別（Sort）',
  },
  {
    id: '溶融',
    name: '溶融（Melt）',
  },
];

export const LAYOUT_BREAK_POINTS = {
  MD: 768,
};

export const JWNET_MAXIMUM_WEIGHT = 99999.99;

export const baseTenantForm: TenantForm = {
  siteId: '',
  name: '',
  sectionId: '',
  floorId: '',
  brandId: '',
  industry: '',
  inputTenantItems: [],
  tenantNumber: '',
  contactName: '',
  contactEmail: '',
  isDashboardEnabled: false,
};

export const baseTenantSiteForm: TenantSiteForm = {
  name: '',
  sectionId: '',
  floorId: '',
  industry: '',
  tenantNumber: '',
  contactName: '',
  contactEmail: '',
  barcode: '',
  icCards: [{ card: '' }],
};

export const MonthOptionValues = [
  { value: '1', label: '01' },
  { value: '2', label: '02' },
  { value: '3', label: '03' },
  { value: '4', label: '04' },
  { value: '5', label: '05' },
  { value: '6', label: '06' },
  { value: '7', label: '07' },
  { value: '8', label: '08' },
  { value: '9', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

export const FORM_ERROR_CLASSNAME = 'form-has-error';

export const OFFLINE_USAGE_SUPPORT_PERMISSIONS: {
  role: RoleType[];
  operatorType: OperatorType[];
}[] = [
  {
    role: [RoleType.AdminSite, RoleType.UserNormal],
    operatorType: [OperatorType.Emissions],
  },
];
