// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { TenantIndustry } from 'apis/tenants';
import React, { useEffect, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FloorItem, TenantSiteForm } from 'util/Types';
import {
  validateEmailAddress,
  validateMaxLength,
  validateStringField,
} from 'util/validator';
import { InputDataListField } from '../InputDataListField';
import { SelectField } from '../SelectField';
import styles from './CreateUpdateTenantSiteForm.module.scss';
import { ModalType, TenantAuthen } from 'util/Enums';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { FieldArray } from 'react-final-form-arrays';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import arrayMutators from 'final-form-arrays';
import { TenantCreateEditModalType } from 'pages/TenantManagement';
import { IconBarcodeV2 } from 'components/atoms/icons/IconBarcodeV2';

interface Props {
  initValues: TenantSiteForm;
  sectionList: { label: string; value: number }[];
  createUpdateModal: TenantCreateEditModalType;
  floorList: FloorItem[];
  tenantIndustries: TenantIndustry[];
  onSubmit: (tenantForm: TenantSiteForm, tenantId?: number) => void;
  hanldeGenerateBarcode: (props: FormRenderProps<TenantSiteForm>) => void;
}

const CreateUpdateTenantSiteForm: React.FC<Props> = ({
  initValues,
  createUpdateModal,
  sectionList,
  floorList,
  tenantIndustries,
  onSubmit,
  hanldeGenerateBarcode,
}) => {
  const { tenantEditing } = createUpdateModal;
  const [floorListState, setFloorListState] = useState<
    { label: string; value: number }[]
  >([]);
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (tenantEditing?.section?.id) {
      setFloorListState(
        floorList
          .filter((floor) => floor.sectionId === tenantEditing.section?.id)
          .map((floor) => {
            return { value: floor.id, label: floor.name };
          })
      );
    }
  }, [tenantEditing]);

  const handleChangeSection = (sectionId: string | number) => {
    setFloorListState(
      floorList
        .filter((floor) => floor.sectionId === +sectionId)
        .map((floor) => {
          return { value: floor.id, label: floor.name };
        })
    );
  };

  return (
    <>
      <div className={styles.createBody}>
        <Form<TenantSiteForm>
          initialValues={initValues}
          onSubmit={(values) =>
            createUpdateModal.type === ModalType.update &&
            tenantEditing?.id != null
              ? onSubmit(values, tenantEditing.id)
              : onSubmit(values)
          }
          validate={(values) => {
            return {
              name: validateStringField(
                t('tenant.thead_tenant_name'),
                100,
                values.name
              ),
              industry: validateStringField(
                t('tenant.thead_tenant_industry'),
                100,
                values.industry
              ),
              contactName: validateStringField(
                t('tenant.thead_contact_name'),
                100,
                values.contactName
              ),
              contactEmail: validateStringField(
                t('tenant.thead_contact_email'),
                100,
                values.contactEmail
              ),
            };
          }}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
            ...arrayMutators,
          }}
        >
          {(props) => (
            <form className={styles.formInput}>
              <div className={styles.inputFrame}>
                <SelectField
                  label={t('tenant.thead_section') || 'セクション'}
                  name="sectionId"
                  placeholder={t('common.select') || '選択する'}
                  options={sectionList}
                  disabled={!sectionList.length}
                  onChange={(value) => handleChangeSection(value)}
                  isDarkmode
                />

                <SelectField
                  label={t('tenant.thead_floor') || 'フロア'}
                  name="floorId"
                  placeholder={t('common.select') || '選択する'}
                  options={floorListState}
                  disabled={!floorListState.length}
                  isDarkmode
                />

                <InputDataListField
                  label={t('tenant.thead_tenant_name') || 'テナント名'}
                  placeholder={t('tenant.thead_tenant_name') || 'テナント名'}
                  name="name"
                  required
                  maxlength={100}
                  isDarkMode
                />

                <InputDataListField
                  label={t('tenant.thead_tenant_number') || 'テナント番号'}
                  placeholder={
                    t('tenant.thead_tenant_number') || 'テナント番号'
                  }
                  name="tenantNumber"
                  maxlength={100}
                  isDarkMode
                />

                <InputDataListField
                  label={t('tenant.thead_tenant_industry') || 'テナント業種'}
                  placeholder={
                    t('tenant.thead_tenant_industry') || 'テナント業種'
                  }
                  name="industry"
                  required
                  maxlength={100}
                  dataSuggestions={
                    tenantIndustries?.map((i) => {
                      return { name: i.industry };
                    }) || []
                  }
                  onSelectSuggestItem={(value) => {
                    props.form.mutators.setValue('industry', value);
                  }}
                  isDarkMode
                />
                <p className={styles.industryDesc}>
                  {t('tenant.thead_tenant_industry_notice')}
                </p>

                <InputDataListField
                  label={t('tenant.thead_contact_name')}
                  placeholder={t('tenant.thead_contact_name')}
                  name="contactName"
                  required
                  maxlength={100}
                  isDarkMode
                />
                <InputDataListField
                  label={t('tenant.thead_contact_email')}
                  placeholder={t('tenant.thead_contact_email')}
                  name="contactEmail"
                  required
                  maxlength={100}
                  validate={validateEmailAddress}
                  isDarkMode
                />

                {user.originalTenantAuthen === TenantAuthen.Barcode && (
                  <>
                    <InputDataListField
                      validate={(value) =>
                        validateMaxLength(value, 15, t('tenant.thead_barcode'))
                      }
                      label={t('tenant.thead_barcode')}
                      placeholder={t('tenant.thead_barcode')}
                      name="barcode"
                      maxlength={15}
                      isDarkMode
                      type="number"
                    />

                    {createUpdateModal.type === ModalType.update &&
                      tenantEditing?.id != null && (
                        <div className={styles.btnBarcodeNew}>
                          <div>
                            <div
                              onClick={() => {
                                hanldeGenerateBarcode(props);
                              }}
                            >
                              <IconBarcodeV2 fill="#a1f484" />
                              <p>{t('tenant.generate_barcode')}</p>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}

                {user.originalTenantAuthen === TenantAuthen.ICCard && (
                  <div>
                    <p className={styles.labelICCard}>
                      {t('tenant.thead_ic_card')}
                    </p>
                    <FieldArray name="icCards">
                      {({ fields }) => {
                        return (
                          <div>
                            {fields.map((name, index) => (
                              <div key={index} className={styles.icCard}>
                                <div className={styles.icCardField}>
                                  <InputDataListField
                                    validate={(value) =>
                                      validateMaxLength(
                                        value,
                                        32,
                                        t('tenant.thead_ic_card')
                                      )
                                    }
                                    placeholder={t('tenant.thead_ic_card')}
                                    name={`${name}.card`}
                                    maxlength={32}
                                    isDarkMode
                                  />

                                  {fields.length && fields.length > 1 && (
                                    <div
                                      className={styles.deleteIcon}
                                      onClick={() => {
                                        fields.remove(index);
                                      }}
                                    >
                                      <IconDelete size={16} stroke="#86EA5C" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div className={styles.addICCardField}>
                              <div>
                                <div onClick={() => fields.push('icCards')}>
                                  <IconAddCircleOutline stroke="#a1f484" />
                                  <p>{t('tenant.add_ic_card')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </FieldArray>
                  </div>
                )}
              </div>

              <div className={styles.submit}>
                <button
                  disabled={props.invalid}
                  className={
                    props.invalid ? styles.disableButton : styles.submitButton
                  }
                  onClick={props.handleSubmit}
                  type="button"
                >
                  {createUpdateModal.type === ModalType.add
                    ? t('common.modal_create.add_button')
                    : t('common.modal_update.edit_button')}
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </>
  );
};

export default CreateUpdateTenantSiteForm;
