// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { useFetchRecycleInputResourcesQuery } from 'apis/booking/bookingApi';
import {
  getSiteUserInfo,
  regisRecycleProduct,
} from 'apis/user_site/recycleApi';
import BigNumber from 'bignumber.js';
import { ConfirmModal } from 'components/organisms/ConfirmModal';
import { useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/slices/userSlice';
import { REGEX_OBJECT } from 'util/ConstantValues';
import { OperatorType } from 'util/Enums';
import {
  Option,
  PreRegisSelectedResource,
  ResourcePreRegisFilter,
  SelectedResources,
} from 'util/Types';
import { baseUserRecycleSiteForm } from 'util/constants/sites';
import { UserRecycleSiteForm } from 'util/siteManagementTypes';
import { validateRequiredField } from 'util/validator';
import PreRegisResourceSelect from '../PreRegisResourceSelect';
import styles from './RecycleComplete.module.scss';
import { normalizeNumber, normalizeNumberString } from 'util/commons';
import LabelOrSelectField from 'components/molecules/LabelOrSelectField';
import { BottomField } from 'components/molecules/BottomField';
import { FormApi } from 'final-form';

type Step = 'regist' | 'info' | 'weight';

export function RecycleComplete() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [inputForm, setInputForm] = useState<UserRecycleSiteForm>(
    baseUserRecycleSiteForm
  );
  const [confirm, setConfirm] = useState<{
    open: boolean;
    alert?: string;
  }>();
  const [step, setStep] = useState<Step>('regist');
  const [resources, setResources] = useState<PreRegisSelectedResource[]>([]);
  const [selectedResources, setSelectedResources] = useState<SelectedResources>(
    {}
  );
  const [filter, setFilter] = useState<ResourcePreRegisFilter>({});
  const [prevProcessingObjectId, setPrevProcessingObjectId] =
    useState<string>();
  const [prevRegisWeight, setPrevRegisWeight] = useState<BigNumber>(
    new BigNumber(0)
  );

  const { data: inputResources, refetch: refetchInputResources } =
    useFetchRecycleInputResourcesQuery();

  const processingMethods = useMemo(
    (): Option[] =>
      inputForm?.processingMethods?.map((x: string) => ({
        label: x,
        value: x,
      })) || [],
    [inputForm]
  );

  const grades = useMemo(
    (): Option[] =>
      inputForm?.grades?.map((x) => ({
        label: x.name,
        value: x.id.toString(),
      })) || [],
    [inputForm]
  );

  const materialTypes = useMemo(
    (): Option[] =>
      inputForm?.materialTypes?.map((x) => ({
        label: x.name,
        value: x.id.toString(),
      })) || [],
    [inputForm]
  );

  const packingStyles = useMemo(
    (): Option[] =>
      inputForm?.packingStyles?.map((x: string) => ({
        label: x,
        value: x,
      })) || [],
    [inputForm]
  );

  const confirmContents = (values: UserRecycleSiteForm) => [
    {
      label: `${t('user_recycle.material_name')}:`,
      contents: inputForm?.materialName || '',
    },
    {
      label: `${t('user_recycle.processing_method')}:`,
      contents:
        inputForm.processingMethods?.find(
          (item) => item === values.processingMethod
        ) || '',
    },
    {
      label: `${t('user_recycle.kind')}:`,
      contents:
        inputForm?.materialTypes?.find(
          (item) => item.id === Number(values.materialTypeId)
        )?.name || '',
    },
    {
      label: `${t('user_recycle.grade')}:`,
      contents:
        inputForm?.grades?.find((item) => item.id === Number(values.gradeId))
          ?.name || '',
    },
    {
      label: `${t('user_recycle.packing')}:`,
      contents:
        inputForm?.packingStyles?.find(
          (item) => item === values.packingStyle
        ) || '',
    },
    {
      label: `${t('user_recycle.weight')}:`,
      contents: `${normalizeNumber({
        value: values.weight,
        toFixed: false,
      })}kg`,
    },
  ];

  const handleGetUserInfo = () => {
    getSiteUserInfo(user?.siteId || '', (data) => {
      if (data) {
        setInputForm({
          ...baseUserRecycleSiteForm,
          ...data,
          materialName: resources?.[0]?.materialName || '',
          processingMethod: data.recycleMethod[0] || '',
          packingStyle: data.packingStyles[0] || '',
          materialTypeId: data?.materialTypes[0]?.id.toString() || '',
          materialTypes: data?.materialTypes || [],
          gradeId: data.grades[0]?.id.toString() || '',
          processingMethods: data.recycleMethod || [],
          grades: data?.grades || [],
          packingStyles: data?.packingStyles || [],
        });
      }
    });
  };

  const onClick = (values) => {
    const totalPrevRegistWeight = new BigNumber(values.weight || '').plus(
      prevRegisWeight
    );

    setConfirm({
      open: true,
      alert:
        totalPrevRegistWeight.comparedTo(totalRegistWeight) > 0
          ? t('compressions.alert') || ''
          : undefined,
    });
  };

  const resetFormData = (
    form: FormApi<UserRecycleSiteForm, Partial<UserRecycleSiteForm>>
  ) => {
    form.reset();
    setFilter({});
    setSelectedResources({});
    setPrevProcessingObjectId(undefined);
    setPrevRegisWeight(new BigNumber(0));
    refetchInputResources().unwrap();
  };

  const onSubmit = async (
    values: UserRecycleSiteForm,
    form: FormApi<UserRecycleSiteForm, Partial<UserRecycleSiteForm>>,
    isSubmitContinue?: boolean
  ) => {
    regisRecycleProduct(
      {
        form: {
          ...values,
          weight: normalizeNumberString(values.weight),
          inputResources: resources.map(({ processingObjectId, weight }) => ({
            processingObjectId,
            weight,
          })),
          prevProcessingObjectId: prevProcessingObjectId ?? undefined,
        },
      },
      (res) => {
        toast.success(t('messages.M_128'));
        setConfirm(undefined);

        if (isSubmitContinue) {
          setStep('info');
          setPrevProcessingObjectId(res.id);
          setPrevRegisWeight((prev) => prev.plus(new BigNumber(values.weight)));
          form.mutators.setValue('weight', '');
        } else {
          setStep('regist');
          resetFormData(form);
        }
      }
    );
  };

  const handlePre2Info = (values: PreRegisSelectedResource[]) => {
    setResources(values);
    setStep('info');
  };

  const totalRegistWeight = useMemo(
    () =>
      resources.reduce((total, pre) => {
        return total.plus(pre.weight);
      }, new BigNumber(0)),
    [resources]
  );

  useEffect(() => {
    setInputForm({
      ...inputForm,
      materialName: resources?.[0]?.materialName,
    });
  }, [resources]);

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  const handleSubmitContinue = (
    values: UserRecycleSiteForm,
    form: FormApi<UserRecycleSiteForm, Partial<UserRecycleSiteForm>>
  ) => {
    onSubmit(values, form, true);
  };

  return (
    <div className={styles.recycleComplete}>
      <Form<UserRecycleSiteForm>
        onSubmit={(values, form) => onSubmit(values, form)}
        initialValues={inputForm}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values) => ({
          weight: validateRequiredField(
            values.weight,
            t('emission_input.label_weight') || ''
          ),
          materialName: validateRequiredField(
            values.materialName,
            t('user_recycle.material_name') || ''
          ),
          processingMethod: validateRequiredField(
            values.processingMethod,
            t('user_recycle.processing_method') || ''
          ),
          materialTypes: validateRequiredField(
            values.materialTypes,
            t('user_recycle.kind') || ''
          ),
          gradeId: validateRequiredField(
            values.gradeId,
            t('user_recycle.grade') || ''
          ),
          packingStyle: validateRequiredField(
            values.packingStyle,
            t('user_recycle.packing') || ''
          ),
        })}
      >
        {({ handleSubmit, valid, values, form }) => {
          return (
            <form
              onSubmit={(values) => {
                handleSubmit(values);
                setConfirm(undefined);
              }}
            >
              {step === 'regist' && (
                <div className={styles.regist}>
                  <PreRegisResourceSelect
                    operatorType={OperatorType.Recycle}
                    resourceData={inputResources || []}
                    onNext={handlePre2Info}
                    className={styles.registTable}
                    actionProps={{
                      filter,
                      setFilter,
                      selectedResources,
                      setSelectedResources,
                    }}
                  />
                </div>
              )}
              {step === 'info' && (
                <>
                  <div className={styles.scrollContents}>
                    <LabelOrSelectField
                      options={[]}
                      label={t('user_recycle.material_name')}
                      fieldName="materialName"
                    />
                    <LabelOrSelectField
                      label={t('user_recycle.processing_method')}
                      fieldName={'processingMethod'}
                      options={processingMethods}
                    />
                    <LabelOrSelectField
                      label={t('user_recycle.kind')}
                      fieldName="materialTypeId"
                      options={materialTypes}
                    />
                    <LabelOrSelectField
                      label={t('user_recycle.grade')}
                      fieldName="gradeId"
                      options={grades}
                    />
                    <LabelOrSelectField
                      label={t('user_recycle.packing')}
                      fieldName="packingStyle"
                      options={packingStyles}
                    />
                  </div>

                  <BottomField>
                    <div className={styles.bottomBtn}>
                      <button
                        className={styles.btnBack}
                        onClick={() => {
                          if (prevProcessingObjectId) {
                            resetFormData(form);
                          }

                          setStep('regist');
                        }}
                      >
                        {t('common.return')}
                      </button>
                      <button
                        className={styles.btnRequest}
                        type="button"
                        onClick={() => setStep('weight')}
                      >
                        {t('common.button.next')}
                      </button>
                    </div>
                  </BottomField>
                </>
              )}
              {step === 'weight' && (
                <div className={styles.weightContent}>
                  <div className={styles.weightContainer}>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>
                        {t('user_recycle.weight')}
                      </div>
                      <div className={styles.overload}>
                        {t('user_recycle.resources', {
                          material: values.materialName,
                          weight: totalRegistWeight,
                        })}
                      </div>
                    </div>
                    <div className={styles.field}>
                      <Field name="weight">
                        {({ input }) => (
                          <input
                            {...input}
                            className={styles.input}
                            onChange={(e) => {
                              if (
                                e.target.value &&
                                !REGEX_OBJECT.tenDigitsWith2Decimal.test(
                                  e.target.value
                                )
                              ) {
                                return;
                              }
                              input.onChange(e);
                            }}
                          />
                        )}
                      </Field>
                      <div className={styles.unit}>kg</div>
                    </div>
                    <div className={styles.bottom}>
                      <div className={styles.buttons}>
                        <button
                          className={styles.back}
                          onClick={() => setStep('info')}
                        >
                          {t('common.return')}
                        </button>
                        <button
                          className={styles.submit}
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(values);
                          }}
                          disabled={!valid}
                        >
                          {t('common.button.register')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <ConfirmModal
                fullBottomWidth
                title={t('common.alert.confirm')}
                submitAndFinishLabel={
                  t('common.button.continue_to_register') || ''
                }
                submitLabel={t('common.button.processing_completed') || ''}
                alert={confirm?.alert}
                isOpen={confirm?.open}
                closeEditModal={() => {
                  setConfirm(undefined);
                }}
                onClickSubmitAndFinish={() =>
                  handleSubmitContinue(values, form)
                }
                onClick={handleSubmit}
                confirmContents={confirmContents(values)}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
}
