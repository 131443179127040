// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import TableLoading from 'components/atoms/TableLoading';
import Toggle from 'components/atoms/Toggle';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconDelete } from 'components/atoms/icons/IconDelete';
import { IconEdit } from 'components/atoms/icons/IconEdit';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import { Suggestion } from 'components/molecules/InputDataListField';
import { CreateItemInputType } from 'components/molecules/WasteInputForm';
import CreateItemModal from 'components/organisms/CreateItemModal';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import DOMPurify from 'dompurify';
import _debounce from 'lodash/debounce';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { DEFAULT_PAGE, ImagePath, PAGE_SIZE } from 'util/ConstantValues';
import { CustomizeItemBreadcrumbs } from 'util/Types';
import { getUnitLabel } from 'util/commons';
import { PrintLabel } from 'util/Enums';
import { handleError } from 'util/errorHandler';
import styles from './AdminCustomizeItemsDetail.module.scss';

export type ItemsChildData = {
  id: string;
  unit: string;
  weight: number;
  name: string;
  branch: string;
  imageUrl: string;
  tenant: string;
  tag: string;
};
interface ModalState {
  isOpen: boolean;
  type: CreateItemInputType;
  itemId?: string;
  dataItemEdit?: WasteUnitItem;
}
export type WasteUnitItem = {
  id: number;
  name: string;
  materialName: string;
  convertKg: string;
  price?: number;
  type: 'Fixed' | 'Optional';
  categoryId: number;
  imageUrl: string;
  emissionMethod?: {
    name: string;
  };
  quantity: string;
  unit: string;
  inputUnit: string;
  recycleMethod: {
    id: number;
    name: string;
  };
  wasteTypeMaster: {
    id: number;
    name: string;
  };
  substituteRegistRelations?: {
    fromSiteId: string;
    toSiteId: string;
    wasteUnitId: string;
  }[];
  isEdiEnabled: boolean;
  ediLargeCategoryId: string;
  ediMediumCategoryId: string;
  ediSmallCategoryId: string;
  category?: {
    site: {
      printLabel: PrintLabel;
    };
  };
};
type FetchItemData = {
  wasteUnits: WasteUnitItem[];
  total: number;
  page: number;
  operatorName: string;
  siteName: string;
  categoryName: string;
};
const AdminCustomizeItems = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState<WasteUnitItem[]>([]);
  const api = createAxios();
  const commonsApi = createAxios(
    undefined,
    undefined,
    false,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const { push } = useHistory();
  const { operatorId, siteId, categoryId } = useParams<{
    siteId: string;
    operatorId: string;
    categoryId: string;
  }>();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState(0);
  const [categoryName, setCategoryName] = useState('');
  const [siteName, setSiteName] = useState('');
  const [isOpenCreate, setOpenCreate] = useState<ModalState>({
    isOpen: false,
    type: CreateItemInputType.add,
  });
  const [breadcrumb, setBreadcrumb] = useState<CustomizeItemBreadcrumbs>({
    operatorName: '-',
    siteName: '-',
    categoryName: '-',
  });
  const [deleteItemsChild, setDeleteItemsChild] =
    useState<WasteUnitItem | null>(null);
  const [wastUnitNames, setWasteUnitNames] = useState<Suggestion[]>([]);
  const [materialNames, setMaterialNames] = useState<Suggestion[]>([]);

  const fetchCategories = async (
    keyword?: string,
    nextPage?: number,
    forceRefresh?: boolean
  ) => {
    const params = new URLSearchParams([['categoryId', categoryId]]);
    if (keyword) {
      params.append('keyword', keyword);
    }
    if (nextPage || !keyword) {
      params.append('page', String(nextPage || DEFAULT_PAGE));
      params.append('take', String(PAGE_SIZE));
    }
    const fetchedData = await api.get<FetchItemData>(`/waste-units`, {
      params,
    });
    if (keyword) {
      setItems(fetchedData.data.wasteUnits);
    } else {
      setItems(
        forceRefresh
          ? fetchedData.data.wasteUnits
          : [...items, ...fetchedData.data.wasteUnits]
      );
    }
    const { operatorName, siteName, categoryName } = fetchedData.data;
    if (!operatorName && !siteName && !categoryName) {
      push(`${Path.adminSiteManagement}/${operatorId}/${siteId}#1`);
    }
    setBreadcrumb({
      operatorName,
      siteName,
      categoryName,
    });
    setTotal(fetchedData.data.total);
    setCurrentPage(fetchedData.data.page);
    setCategoryName(fetchedData.data.categoryName);
    setSiteName(fetchedData.data.siteName);
  };

  const fetchSuggestions = async () => {
    try {
      Promise.all([
        commonsApi.get('/commons/waste-unit-names'),
        commonsApi.get('/commons/material-names'),
      ]).then((response) => {
        setWasteUnitNames(response[0].data);
        setMaterialNames(
          response[1].data.map((i) => {
            return { name: i.materialName };
          })
        );
      });
    } catch (error) {
      toast.error(t('messages.M_013'));
    }
  };

  const handleItemDelete = async (id) => {
    api
      .delete('/waste-units/' + id)
      .then(function () {
        fetchCategories('', DEFAULT_PAGE, true);
        toast.success(t('messages.M_012'));
      })
      .catch(function (e) {
        handleError(e);
      });
    setDeleteItemsChild(null);
  };

  const debounceFn = useCallback(_debounce(fetchCategories, 1000), []);

  const handleChange = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value.trim());
  };

  useEffect(() => {
    if (categoryId) {
      fetchCategories();
      fetchSuggestions();
    }
  }, [categoryId]);

  const handleClickAdd = () => {
    setOpenCreate({ isOpen: true, type: CreateItemInputType.add });
  };

  const handleClickEdit = (e: ChangeEvent, item: WasteUnitItem) => {
    e.stopPropagation();
    setOpenCreate({
      isOpen: true,
      type: CreateItemInputType.update,
      itemId: String(item.id),
      dataItemEdit: item,
    });
  };

  const handleClickDelete = (e: ChangeEvent, item: WasteUnitItem) => {
    e.stopPropagation();
    setDeleteItemsChild(item);
  };

  const handleSearch = useCallback(() => {
    fetchCategories(search);
  }, [search]);
  return (
    <AdminTemplate
      h1={t('category_details.heading')}
      active="排出"
      breadcrumb={[
        {
          label: t('common.sidebar_operator'),
          href: `${Path.adminOperatorListManagement}${window.location.search}`,
        },
        {
          label: breadcrumb.operatorName || '-',
          href: `${Path.adminOperatorListManagement}/${operatorId}${window.location.search}`,
        },
        {
          label: breadcrumb.siteName || '-',
          href: `${Path.adminSiteManagement}/${operatorId}/${siteId}${window.location.search}#1`,
        },
        {
          label: breadcrumb.categoryName || '-',
        },
      ]}
      footerChildren={
        <div className={styles.backToCategoryList}>
          <Link
            to={`${Path.adminSiteManagement}/${operatorId}/${siteId}` + '#1'}
            className="back-link"
          >
            {t('common.button.return_category_list')}
          </Link>
        </div>
      }
    >
      <div
        className="count-list"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t('common.page', {
              page: total,
            }) ?? ''
          ),
        }}
      />

      <div className={`search-area ${styles.searchArea}`}>
        <div className="search-input">
          <input
            placeholder={t('common.placeholder.search') || '検索'}
            value={search}
            onChange={handleChange}
          />
          <div className="icon" onClick={handleSearch}>
            <IconSearch />
          </div>
        </div>
        <div className="button" onClick={handleClickAdd}>
          <IconAddCircleOutline />
          <span>{t('common.button.add_item')}</span>
        </div>
      </div>

      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.customizeItemChildTable}>
          <thead>
            <tr>
              <th className={styles.image}>
                {t('category_details.thead_item')}
              </th>
              <th className={styles.branch}>
                {t('category_details.thead_material')}
              </th>
              <th className={styles.tag}>
                {t('category_details.thead_kinds')}
              </th>
              <th className={styles.action}>{t('common.action')}</th>
              <th className={styles.action}>{t('category_details.edi')}</th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((item) => {
                return (
                  <tr key={item.id}>
                    <td className={styles.image}>
                      <div className={styles.contentImageName}>
                        <div className={styles.contentImage}>
                          <img
                            loading="lazy"
                            src={
                              item.imageUrl
                                ? `${process.env.REACT_APP_CLOUDFRONT}${item.imageUrl}`
                                : ImagePath.defaultImage
                            }
                          />
                        </div>

                        <div className={styles.contentName}>
                          <p title={item.name}>
                            {item.type === 'Fixed'
                              ? `${item.name} ${item.convertKg}kg/${
                                  item.quantity
                                }${getUnitLabel(item.unit)}`
                              : `${item.name}`}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td className={styles.branch} title={item.materialName}>
                      <p>{item.materialName || '-'}</p>
                    </td>
                    <td className={styles.tag}>
                      <span>{item.type === 'Fixed' ? '定型' : '任意'}</span>
                    </td>
                    <td className={styles.action}>
                      <div className={styles.contentAction}>
                        <div className={styles.actionEdit}>
                          <IconEdit
                            onClick={(e: ChangeEvent) => {
                              handleClickEdit(e, item);
                            }}
                          />
                        </div>
                        <div className={styles.actionDelete}>
                          <IconDelete
                            onClick={(e: ChangeEvent) => {
                              handleClickDelete(e, item);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td className={styles.action}>
                      <Toggle value={item.isEdiEnabled} disabled />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
            {items && items.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (items.length >= PAGE_SIZE && items.length < total) {
                    setCurrentPage(currentPage + 1);
                    fetchCategories('', currentPage + 1);
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>

      {isOpenCreate.isOpen && (
        <div className={styles.createItemModal}>
          <CreateItemModal
            onClose={() => {
              setOpenCreate({
                isOpen: false,
                type: CreateItemInputType.add,
                itemId: undefined,
              });
            }}
            wasteUnitNames={wastUnitNames}
            materialNames={materialNames}
            type={isOpenCreate.type}
            itemId={isOpenCreate.itemId}
            categoryName={categoryName}
            siteName={siteName}
            detailItem={isOpenCreate.dataItemEdit}
            handleFetchData={() => {
              fetchCategories('', DEFAULT_PAGE, true);
              fetchSuggestions();
            }}
          />
        </div>
      )}

      {deleteItemsChild && (
        <DeleteItemModal
          item={deleteItemsChild}
          onClose={() => setDeleteItemsChild(null)}
          onHandleDelete={handleItemDelete}
        />
      )}
    </AdminTemplate>
  );
};

export default AdminCustomizeItems;
