// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { Modal } from 'components/atoms/Modal';
import 'react-calendar/dist/Calendar.css';
import styles from './SeeMoreModal.module.scss';
import { IconClose } from 'components/atoms/icons/IconClose';

export type SeeMoreModalModalProps = {
  title: string;
  data: string[];
  onClose: () => void;
};

export const SeeMoreModal = ({
  title,
  data,
  onClose,
}: SeeMoreModalModalProps) => {
  return (
    <div className={styles.tareModal}>
      <Modal>
        <div className={styles.customTitle}>
          <div className={styles.title}>{title}</div>
          <div className={styles.top} onClick={onClose}>
            <IconClose />
          </div>
        </div>

        <div className={styles.modalContent}>
          <ul>
            {data.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
};
