// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { getOfflineSiteData } from 'apis/offline';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { OperatorType, RoleType } from 'util/Enums';
import { DbManager } from 'util/DbManager';
import { CategoryOfflineResponse } from 'types/offline';
import { setOfflineInitialized } from 'redux/slices/loadingSlice';
import uniqBy from 'lodash/uniqBy';

import { fnSaveDataSyncTime } from 'util/commons';

export const cacheImageUrls = async (imageUrls: string[], reset?: boolean) => {
  let cvImageUrls = imageUrls;

  if (reset) {
    await DbManager.categoryImages.clear();
  } else {
    const cachedImages = await DbManager.categoryImages
      .where('path')
      .anyOf(imageUrls)
      .toArray();

    const cachedUrls = cachedImages.map(({ path }) => path);
    cvImageUrls = imageUrls.filter((url) => !cachedUrls.includes(url));
  }

  if (!cvImageUrls.length) {
    return;
  }

  const imagesMapper = await Promise.all(
    uniqBy(cvImageUrls).map((e) =>
      fetch(`${process.env.REACT_APP_CLOUDFRONT}${e}`).then(async (data) => {
        const content = await data.blob();

        return {
          path: e,
          content,
        };
      })
    )
  ).catch(() => []);

  if (imagesMapper?.length) {
    await DbManager.categoryImages.bulkAdd(imagesMapper);
  }
};

export const cacheCategoryImages = async (
  categories: CategoryOfflineResponse[]
) => {
  const imageUrls = categories.reduce(
    (rs: string[], { imageUrl, wasteUnitsOneCategory }) => {
      if (imageUrl) {
        rs.push(imageUrl);
      }

      wasteUnitsOneCategory?.forEach(({ imageUrl: subImg }) => {
        if (subImg) {
          rs.push(subImg);
        }
      });

      return rs;
    },
    []
  );

  await cacheImageUrls(imageUrls, true);
};

export const useInitOfflineSiteData = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      if (
        (user.role === RoleType.AdminSite ||
          user.role === RoleType.UserNormal) &&
        user.operatorType === OperatorType.Emissions
      ) {
        if (navigator.onLine) {
          dispatch(setOfflineInitialized(false));

          try {
            await getOfflineSiteData({
              successCallback: async (data) => {
                const {
                  tenants,
                  sections,
                  floors,
                  tares,
                  categories,
                  ...siteInfo
                } = data;

                await DbManager.clearExceptWasteRegistrations(true);

                await DbManager.open();

                fnSaveDataSyncTime(siteInfo.dataSyncTime);
                await Promise.all([
                  DbManager.sites.add(siteInfo),
                  DbManager.tenants.bulkAdd(tenants),
                  DbManager.sections.bulkAdd(
                    sections.map((section) => ({
                      ...section,
                      siteId: siteInfo.id,
                    }))
                  ),
                  DbManager.floors.bulkAdd(floors),
                  DbManager.tares.bulkAdd(
                    tares.map(({ tareTenants, ...rest }) => ({
                      ...rest,
                      siteId: siteInfo.id,
                      tenants: tareTenants?.map(({ tenantId }) => ({
                        id: tenantId,
                      })),
                    }))
                  ),
                  DbManager.categories.bulkAdd(
                    categories.map((category) => ({
                      ...category,
                      other: Boolean(category.other),
                      materialName: category.materialName ?? null,
                      convertKg: category.convertKg ?? null,
                      quantity: category.quantity ?? null,
                      unit: category.unit ?? null,
                      sortIndex: category.sortIndex ?? 0,
                      wasteUnitsOneCategory:
                        category.wasteUnitsOneCategory?.map(
                          (wasteUnit) => ({
                            ...wasteUnit,
                            materialName: wasteUnit.materialName ?? null,
                            convertKg: wasteUnit.convertKg ?? null,
                            quantity: wasteUnit.quantity ?? null,
                            unit: wasteUnit.unit ?? null,
                          })
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ) as any[],
                    }))
                  ),
                  cacheCategoryImages(categories),
                ]);
              },
            });
          } finally {
            dispatch(setOfflineInitialized(true));
          }
        } else {
          dispatch(setOfflineInitialized(true));
        }
      }
    };

    init();
  }, [user.role, user.operatorType]);
};
