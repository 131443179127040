// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import {
  downloadTeraokaCsv,
  getWorkHistoriesEmissionTeraokaImports,
} from 'apis/teraoka/teraokaApi';
import TableLoading from 'components/atoms/TableLoading';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate';
import DOMPurify from 'dompurify';
import _snakeCase from 'lodash/snakeCase';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormatDateWithCurrentTimezone } from 'ts/formatDate';
import { GetListParams } from 'types/api';
import { DATE_FORMAT, DEFAULT_PAGE, PAGE_SIZE } from 'util/ConstantValues';
import { ImportStatus } from 'util/Enums';
import {
  ExternalScaleConnectionsHistoryItem,
  TeraokaImportHistories,
} from 'util/Types';
import { downLoadBase64CSV, fileSizeConverter } from 'util/commons';
import styles from './ExternalScaleConnectionsHistory.module.scss';

const ExternalScaleConnectionsHistory = () => {
  const { t } = useTranslation();
  const [teraokaImports, setTeraokaImports] = useState<TeraokaImportHistories>({
    page: 0,
    total: 0,
    data: [],
  });

  useEffect(() => {
    fetchWorkHistoriesEmissionTeraokaImports();
  }, []);

  const fetchWorkHistoriesEmissionTeraokaImports = (params?: GetListParams) => {
    getWorkHistoriesEmissionTeraokaImports({
      params: {
        ...params,
        take: PAGE_SIZE,
        page: params?.page || DEFAULT_PAGE,
      },
      successCallback: (res) => {
        setTeraokaImports({
          ...teraokaImports,
          ...res,
          data: [...teraokaImports.data, ...(res.data || [])],
        });
      },
    });
  };

  const handleDownload = (item: ExternalScaleConnectionsHistoryItem) => {
    downloadTeraokaCsv(item.id, (res) => {
      downLoadBase64CSV({
        data: res.file,
        filename: `${t(
          'histories.external_scale_connections.teraokaCsvFileName'
        )}_${getFormatDateWithCurrentTimezone(
          item.linkDateTime,
          DATE_FORMAT.YMDHm
        )}`,
        charset: 'shift_jis',
      });
    });
  };

  return (
    <PrimaryTemplate
      h1={t('histories.external_scale_connections.heading')}
      className={styles.externalScaleConnectionsHistory}
    >
      <div
        className={styles.countList}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t('common.page', {
              page: teraokaImports.total,
            }) ?? ''
          ),
        }}
      ></div>

      <div className={styles.externalScaleConnectionsHistoryTable}>
        <table>
          <thead>
            <tr>
              <th>
                {t('histories.external_scale_connections.link_date_time')}
              </th>
              <th>
                {t('histories.external_scale_connections.import_status.label')}
              </th>
              <th>
                {t('histories.external_scale_connections.import_date_time')}
              </th>
              <th>{t('histories.external_scale_connections.file_size')}</th>
              <th className={styles.buttonDownload}>
                {t('histories.external_scale_connections.download')}
              </th>
            </tr>
          </thead>
          <tbody>
            {teraokaImports.data.length > 0 ? (
              teraokaImports.data.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      {getFormatDateWithCurrentTimezone(
                        item.linkDateTime,
                        DATE_FORMAT.slaYMDHm
                      )}
                    </td>
                    <td>
                      {t(
                        `histories.external_scale_connections.import_status.${_snakeCase(
                          item.status
                        )}`
                      )}
                    </td>
                    <td>
                      {item.status === ImportStatus.success
                        ? getFormatDateWithCurrentTimezone(
                            item.importDateTime,
                            DATE_FORMAT.slaYMDHm
                          )
                        : ''}
                    </td>
                    <td>{fileSizeConverter(item.fileSize)}</td>
                    <td className={styles.buttonDownload}>
                      <button onClick={() => handleDownload(item)}>
                        <IconDownload />
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className={styles.msgError}>
                  {t('messages.M_008')}
                </td>
              </tr>
            )}

            {teraokaImports.data?.length > 0 && (
              <TableLoading
                onNext={() => {
                  if (
                    teraokaImports.data.length >= PAGE_SIZE &&
                    teraokaImports.data.length < teraokaImports.total
                  ) {
                    fetchWorkHistoriesEmissionTeraokaImports({
                      page: (teraokaImports.page || DEFAULT_PAGE) + 1,
                    });
                  }
                }}
              />
            )}
          </tbody>
        </table>
      </div>
    </PrimaryTemplate>
  );
};

export default ExternalScaleConnectionsHistory;
