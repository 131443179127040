// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Chart } from 'react-chartjs-2';
import styles from './ChartRecyclingRate.module.scss';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import { useMemo, useRef, useState } from 'react';
import { FilterObject, RateRecycleStatisticsType } from 'pages/Dashboard';
import { createAxios } from 'ts/createAxios';
import { useTranslation } from 'react-i18next';
import DashboardDownloadModal from '../DashboardDownloadModal';
import PreviewChartModal from '../PreviewChartModal';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'util/ConstantValues';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { RoleType } from 'util/Enums';
import { getBgColor, initDatasetLabels } from 'util/dashboardTypes';
import { getMonthNameFromMonthNumber } from 'ts/formatDate';
import { API_ENDPOINTS } from 'util/endPoints';
import { normalizeNumber } from 'util/commons';
import { useCallbackLoading } from 'util/hooks/useCallbackLoading';
import LoadingCoating from 'components/atoms/LoadingCoating';

type RateRecycleStatisticsResponse = {
  dateRange: string;
  wasteUnits: string[];
  industries: string[];
  tenants: string[];
  sites: string[];
  isYearData: boolean;
  dataDetails: {
    date: string;
    name: string;
    rate: number;
  }[];
};
const ChartRecyclingRate = ({
  rateRecycleStatistics,
  filter,
  isAdminOperatorSiteView,
  loading,
}: {
  rateRecycleStatistics?: RateRecycleStatisticsType;
  filter: FilterObject;
  isAdminOperatorSiteView?: boolean;
  loading?: boolean;
}) => {
  const { i18n, t } = useTranslation();
  const commonsApi = createAxios(
    undefined,
    undefined,
    true,
    process.env.REACT_APP_API_COMMONS_URL
  );
  const [isOpenDownload, setOpenDownload] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const user = useSelector(selectUser);

  const { adminOperatorView, adminSiteView } = useMemo(
    () => ({
      adminOperatorView:
        user.role === RoleType.AdminOperator && !isAdminOperatorSiteView,
      adminSiteView:
        user.role === RoleType.AdminSite || isAdminOperatorSiteView,
    }),
    [user.role, isAdminOperatorSiteView]
  );

  const data = {
    labels: rateRecycleStatistics?.dataDetail.map((data) =>
      rateRecycleStatistics?.isYearData
        ? `${data.year}`
        : i18n.language === 'ja'
        ? `${data.month}月`
        : getMonthNameFromMonthNumber(data.month - 1)
    ),
    datasets: [
      {
        maxBarThickness: 100,
        type: 'line' as const,
        pointRadius: 5,
        label: t('dashboard.recycle_rate_line'),
        data:
          rateRecycleStatistics?.dataDetail.map((item) =>
            String(item.recycleRate || 0)
          ) || [],
        borderColor: 'white',
        borderWidth: 4,
        backgroundColor: '#1C8766',
        borderRadius: 100,
        pointStyle: 'circle',
        datalabels: {
          display: false,
        },
      },
      ...initDatasetLabels.map((item) => ({
        label: i18n.language === 'ja' ? item.name : item.englishName,
        backgroundColor: getBgColor(item.id),
        data:
          rateRecycleStatistics?.dataDetail.map((data) =>
            String(
              data.recycleMethods.find((method) => method.id === item.id)
                ?.rate || 0
            )
          ) || [],
        maxBarThickness: 100,
        datalabels: {
          display: false,
        },
      })),
    ],
  };
  const dataDownLoadImage = {
    ...data,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datasets: data.datasets.map((dataset: any) =>
      dataset?.type === 'line'
        ? {
            ...dataset,
            borderColor: 'black',
            backgroundColor: 'white',
            borderWidth: 8,
            pointRadius: 14,
          }
        : dataset
    ),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartRef = useRef<any>();

  const options = {
    indexAxis: 'x' as const,
    layout: {
      padding: 24,
    },
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          color: '#E7E7E7',
          font: { size: 10 },
          usePointStyle: true,
          pointStyle: 'rectRounded',
          sort: (a, b) =>
            a.datasetIndex && b.datasetIndex
              ? b.datasetIndex - a.datasetIndex
              : 0,
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return (
              (tooltipItem.dataset?.label || '') +
              `: ${normalizeNumber({ value: tooltipItem.raw })}%`
            );
          },
        },
      },
    },
    elements: {
      bar: {
        borderSkipped: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#CFCFCF',
        },
      },
      y: {
        stacked: true,
        grid: {
          color: '#3C3C3C',
        },
        max: 100,
        ticks: {
          color: '#CFCFCF',
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          callback: function (value, index, values) {
            return `${value}%`;
          },
        },
      },
    },
  };
  const plugins = (isPreview?: boolean) => {
    return [
      {
        id: 'customCanvasBackgroundColor',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeDraw: (chart, args, options) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = isPreview ? '#24252a' : '#2C2C2C';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
  };

  const [downloadData, setDownloadData] =
    useState<RateRecycleStatisticsResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSVRef = useRef<any>();

  const brandItems = filter?.brandItems?.filter((item) => item.id != null);

  const commonParams = useMemo(() => {
    return {
      dateFrom: filter.dates.from,
      dateTo: filter.dates.to,
      siteIds: filter?.sites?.map((item) => item.id) || [],
      siteNames: filter?.sites?.map((item) => item.name) || [],
      wasteUnitNames: filter.wasteUnitNames || [],
      industries: filter.industries || [],
      tenantIds:
        filter?.tenants?.map((item) =>
          item.id.toString() === '' ? null : item.id
        ) || [],
      tenantNames: filter?.tenants?.map((item) => item.name) || [],
      brandItemIds: brandItems?.map((item) => item.id) || [],
      brandItemNames: brandItems?.map((item) => item.name) || [],
      isDashboardSite: filter.isDashboardSite,
    };
  }, [filter]);

  const { triggerFetch: handleDownloadCSV, loading: csvDownloading } =
    useCallbackLoading({
      callback: () =>
        commonsApi
          .post(
            API_ENDPOINTS.DASHBOARD_RATE_RECYCLE_STATICS_EXPORT,
            commonParams
          )
          .then((res) => {
            setDownloadData(res.data);
            setTimeout(() => {
              downloadCSVRef.current.link.click();
            }, 500);
          }),
    });

  return (
    <div className={styles.RecyclingChartContainer}>
      <LoadingCoating
        show={loading || csvDownloading}
        styles={{ borderRadius: 12 }}
      />

      <div className={styles.heading}>
        <p>{t('dashboard.recycle_rate')}</p>
        <div
          className={styles.buttonDownload}
          onClick={() => {
            setOpenDownload(true);
          }}
        >
          <IconDownload />
        </div>
      </div>
      <Chart
        onClick={() => setOpen(true)}
        type="bar"
        options={options}
        data={data}
        plugins={plugins()}
      />
      {isOpen && (
        <PreviewChartModal
          title={t('dashboard.recycle_rate') || ''}
          children={
            <Chart
              type="bar"
              options={options}
              data={data}
              plugins={plugins(true)}
            />
          }
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {isOpenDownload && (
        <DashboardDownloadModal
          onClose={() => {
            setOpenDownload(false);
          }}
          handleDownload={(data) => {
            if (data.isImage && chartRef.current) {
              const link = document.createElement('a');
              link.download = `${t('dashboard.recycle_rate')} ${dayjs().format(
                DATE_FORMAT.slaYMDHm
              )}.png`;
              link.href = chartRef.current.toBase64Image('image/png', 1);
              link.click();
            }
            if (data.isCsv && handleDownloadCSV) {
              handleDownloadCSV();
            }
            setOpenDownload(false);
          }}
        />
      )}
      <div className={styles.chartHidden}>
        <Chart
          className={styles.chartHidden}
          ref={chartRef}
          type="bar"
          options={{
            indexAxis: 'x',
            layout: {
              padding: 24,
            },
            plugins: {
              legend: {
                display: true,
                position: 'right',
                labels: {
                  color: '#000000',
                  font: { size: 18 },
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                  sort: (a, b) =>
                    a.datasetIndex && b.datasetIndex
                      ? b.datasetIndex - a.datasetIndex
                      : 0,
                  padding: 16,
                },
              },
            },
            elements: {
              bar: {
                borderSkipped: true,
              },
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  color: 'black',
                  font: {
                    size: 18,
                  },
                },
              },
              y: {
                stacked: true,
                grid: {
                  color: '#9EA0A2',
                },
                max: 100,
                ticks: {
                  color: 'black',
                  font: {
                    size: 18,
                  },
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  callback: function (value, index, values) {
                    return `${value}%`;
                  },
                },
              },
            },
          }}
          data={dataDownLoadImage}
          plugins={[
            {
              id: 'customCanvasBackgroundColor',
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
              },
            },
          ]}
        />
      </div>
      <CSVLink
        className="hidden"
        filename={
          t('dashboard.recycle_rate_csv_name', {
            date_time: dayjs().format(DATE_FORMAT.slaYMDHm),
          }) + '.csv'
        }
        data={[
          [
            downloadData?.isYearData
              ? t('dashboard.recycling_rate_csv.year')
              : t('dashboard.recycling_rate_csv.month'),
            t('dashboard.recycling_rate_csv.processing_method'),
            t('dashboard.recycling_rate_csv.rate') + '(%)',
          ],
          downloadData?.dateRange
            ? [
                t('dashboard.recycling_rate_csv.date_range'),
                downloadData?.dateRange || '',
              ]
            : [],
          downloadData?.sites && adminOperatorView
            ? [
                t('dashboard.recycling_rate_csv.site_name'),
                downloadData?.sites?.join(', ') || '',
              ]
            : [],
          downloadData?.industries && adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.business_type'),
                downloadData?.industries?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && adminSiteView
            ? [
                t('dashboard.recycling_rate_csv.tenant_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.tenants && user.role === RoleType.BrandOwner
            ? [
                t('dashboard.recycling_rate_csv.store_name'),
                downloadData?.tenants?.join(', ') || '',
              ]
            : [],
          downloadData?.wasteUnits
            ? [
                t('dashboard.recycling_rate_csv.item'),
                downloadData?.wasteUnits?.join(', ') || '',
              ]
            : [],
          ...(downloadData?.dataDetails
            .sort((a, b) => {
              return dayjs(b.date).valueOf() - dayjs(a.date).valueOf();
            })
            .map((data) => {
              return [
                data.date,
                String(data.name),
                Number(data.rate).toFixed(2),
              ];
            }) || []),
        ].filter((data) => data.length)}
        ref={downloadCSVRef}
      ></CSVLink>
    </div>
  );
};
export default ChartRecyclingRate;
