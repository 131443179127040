// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { fetchFloors, fetchSections } from 'apis/tenants';
import { IconAddCircleOutline } from 'components/atoms/icons/IconAddCircleOutline';
import { IconSearch } from 'components/atoms/icons/IconSearch';
import { FloorInput } from 'components/molecules/CreateUpdateFloorTenantInputForm/CreateUpdateFloorTenantInputForm';
import DeleteItemModal from 'components/molecules/DeleteItemForm/DeleteItemModal';
import AdminSectionFloorRowItem from 'components/organisms/AdminSectionFloorManagement/AdminSectionFloorRowItem';
import CreateUpdateFloorTenantModal from 'components/organisms/CreateUpdateFloorTenantModal/CreateUpdateFloorTenantModal';
import CreateUpdateSectionTenantModal from 'components/organisms/CreateUpdateSectionTenantModal/CreateUpdateSectionTenantModal';
import { AdminTemplate } from 'components/templates/AdminTemplate';
import DOMPurify from 'dompurify';
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Path } from 'routes';
import { createAxios } from 'ts/createAxios';
import { ModalType } from 'util/Enums';
import { FloorItem, SectionFloorBreadcrumbs, SectionItem } from 'util/Types';
import { handleError } from 'util/errorHandler';
import styles from './AdminSectionFloorManagement.module.scss';

export interface ModalFloorState {
  isOpen: boolean;
  type: ModalType;
  floorEditing?: FloorItem;
  sectionId?: number;
}

export interface ModalSectionState {
  isOpen: boolean;
  type: ModalType;
  sectionId: string;
  sectionName: string;
}

enum DeleteModalType {
  floor = 'floor',
  section = 'section',
}

interface DeleteState {
  item: SectionItem | FloorItem;
  type: DeleteModalType;
}

const AdminSectionFloorManagement = () => {
  const { t } = useTranslation();
  const api = createAxios();
  const [sectionList, setSectionList] = useState<SectionItem[]>([]);
  const [floorList, setFloorList] = useState<FloorItem[]>([]);
  const [totalSection, setTotalSection] = useState(0);
  const { operatorId, siteId } = useParams<{
    operatorId: string;
    siteId: string;
  }>();
  const [breadcrumb, setBreadcrumb] = useState<SectionFloorBreadcrumbs>({
    operatorName: '-',
    siteName: '-',
    sectionName: '-',
  });
  const [search, setSearch] = useState('');
  const [createUpdateSectionModal, setCreateUpdateSectionModal] =
    useState<ModalSectionState>({
      isOpen: false,
      type: ModalType.add,
      sectionName: '',
      sectionId: '',
    });

  const [deleteItem, setDeleteItem] = useState<DeleteState | undefined>();

  const [createUpdateFloorModal, setCreateUpdateFloorModal] =
    useState<ModalFloorState>({
      isOpen: false,
      type: ModalType.add,
      sectionId: undefined,
    });

  // Get list api floors
  const callAPIGetListFLoor = async (forceRefresh?: boolean) => {
    fetchFloors({ siteId }, (fetchedData) => {
      setFloorList(forceRefresh ? fetchedData.floors : [...fetchedData.floors]);
    });
  };

  //get list section
  const callAPIGetListSection = async (
    keyword?: string,
    forceRefresh?: boolean
  ) => {
    fetchSections({ siteId, keyword: keyword || '' }, (fetchedData) => {
      if (keyword) {
        setSectionList(fetchedData.sections);
      } else {
        setSectionList(
          forceRefresh ? fetchedData.sections : [...fetchedData.sections]
        );
      }

      setBreadcrumb({
        operatorName: fetchedData.operatorName,
        siteName: fetchedData.siteName,
        sectionName: 'セクション・フロア管理',
      });
      setTotalSection(fetchedData.sections.length);
    });
  };

  // debounce search
  const debounceFn = useCallback(_debounce(callAPIGetListSection, 1000), []);

  const handleChange = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value.trim());
  };

  useEffect(() => {
    callAPIGetListFLoor();
    callAPIGetListSection();
  }, []);

  // handle click
  const handleClickAddSection = () => {
    setCreateUpdateSectionModal({
      isOpen: true,
      type: ModalType.add,
      sectionName: '',
      sectionId: '',
    });
  };
  const handleClickAddFloor = (sectionId: number) => {
    setCreateUpdateFloorModal({
      isOpen: true,
      type: ModalType.add,
      sectionId: sectionId,
    });
  };

  const handleClickEditSection = (item: SectionItem) => {
    setCreateUpdateSectionModal({
      isOpen: true,
      type: ModalType.update,
      sectionName: item.name,
      sectionId: String(item.id),
    });
  };

  const handleClickDeleteSection = (item: SectionItem) => {
    setDeleteItem({
      item,
      type: DeleteModalType.section,
    });
  };

  const handleClickEditFloor = (sectionId: number, floorEditing: FloorItem) => {
    setCreateUpdateFloorModal({
      isOpen: true,
      type: ModalType.update,
      floorEditing: floorEditing,
      sectionId: sectionId,
    });
  };

  const handleClickDeleteFloor = (item: FloorItem) => {
    setDeleteItem({
      item,
      type: DeleteModalType.floor,
    });
  };

  const getFloorOfSection = (section: SectionItem) => {
    return floorList.filter((floor) => floor.sectionId === section.id);
  };

  const handleSearch = useCallback(() => {
    callAPIGetListSection(search);
  }, [search]);

  const handleDelete = async (id?: number | string) => {
    const endpoint =
      deleteItem?.type === DeleteModalType.floor
        ? `/floors/${id}`
        : `/sections/${id}`;
    await api
      .delete(endpoint)
      .then(function () {
        toast.success(t('messages.M_012'));
      })
      .catch(function (e) {
        handleError(e);
      });
    callAPIGetListFLoor();
    callAPIGetListSection();
    setDeleteItem(undefined);
  };

  const handleCreateFloorTenant = async (createFloorForm: FloorInput) => {
    try {
      await api.post('/floors', {
        name: createFloorForm.floorName.trim(),
        sectionId: Number(createFloorForm.sectionId),
      });
      toast.success(t('messages.M_010'));
      callAPIGetListFLoor(true);
      callAPIGetListSection('', true);

      setCreateUpdateFloorModal({
        isOpen: false,
        type: ModalType.add,
        sectionId: undefined,
      });
    } catch (e) {
      handleError(e);
    }
  };

  const handleUpdateFloorTenant = async (
    createFloorForm: FloorInput,
    floorId: number
  ) => {
    try {
      await api.put(`/floors/${floorId}`, {
        name: createFloorForm.floorName.trim(),
        sectionId: Number(createFloorForm.sectionId),
      });
      toast.success(t('messages.M_011'));
      callAPIGetListFLoor(true);
      callAPIGetListSection('', true);

      setCreateUpdateFloorModal({
        isOpen: false,
        type: ModalType.add,
        sectionId: undefined,
      });
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <AdminTemplate
      h1={t('section_floor_management.section_list')}
      active="排出"
      breadcrumb={[
        {
          label: t('common.sidebar_operator'),
          href: `${Path.adminOperatorListManagement}${window.location.search}`,
        },
        {
          label: breadcrumb.operatorName || '-',
          href: `${Path.adminOperatorListManagement}/${operatorId}${window.location.search}`,
        },
        {
          label: breadcrumb.siteName || '-',
          href: `${Path.adminSiteManagement}/${operatorId}/${siteId}${window.location.search}#0`,
        },
        {
          label: t('section_floor_management.heading'),
        },
      ]}
      footerChildren={
        <div className={styles.backToTenantList}>
          <Link
            to={`${Path.adminSiteManagement}/${operatorId}/${siteId}` + '#0'}
            className="back-link"
          >
            {t('common.button.return_tenant_list')}
          </Link>
        </div>
      }
    >
      <div
        className="count-list"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t('common.page', {
              page: totalSection,
            }) ?? ''
          ),
        }}
      />

      <div className={`search-area ${styles.searchArea}`}>
        <div className="search-input">
          <input
            placeholder={t('common.placeholder.search') || '検索'}
            value={search}
            onChange={handleChange}
          />
          <div className="icon" onClick={handleSearch}>
            <IconSearch />
          </div>
        </div>
        <div className="button" onClick={handleClickAddSection}>
          <IconAddCircleOutline />
          <span>{t('common.button.add_section')}</span>
        </div>
      </div>

      <div className={`scroll-wrapper ${styles.scrollWrapper}`}>
        <table id={styles.areaFloorTable}>
          <thead>
            <tr>
              <th className={styles.name}>{t('tenant.thead_section')}</th>
              <th className={styles.action}>{t('common.action')}</th>
            </tr>
          </thead>
          <tbody>
            {sectionList.length ? (
              sectionList.map((section) => {
                return (
                  <AdminSectionFloorRowItem
                    key={section.id}
                    section={section}
                    floors={getFloorOfSection(section)}
                    onClickDeleteSection={handleClickDeleteSection}
                    onClickEditSection={handleClickEditSection}
                    onClickDeleteFloor={handleClickDeleteFloor}
                    onClickEditFloor={handleClickEditFloor}
                    onClickAddFloor={handleClickAddFloor}
                  />
                );
              })
            ) : (
              <tr>
                <td className="msg-error">{t('messages.M_008')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {createUpdateFloorModal.isOpen && (
        <CreateUpdateFloorTenantModal
          sectionList={sectionList}
          onClose={() => {
            setCreateUpdateFloorModal({
              isOpen: false,
              type: ModalType.add,
              sectionId: undefined,
            });
          }}
          createUpdateFloorModal={createUpdateFloorModal}
          onCreateFloorTenant={handleCreateFloorTenant}
          onUpdateFloorTenant={handleUpdateFloorTenant}
        />
      )}

      {createUpdateSectionModal.isOpen && (
        <CreateUpdateSectionTenantModal
          onClose={() => {
            setCreateUpdateSectionModal({
              isOpen: false,
              type: createUpdateSectionModal.type,
              sectionName: '',
              sectionId: createUpdateSectionModal.sectionId,
            });
          }}
          createUpdateSectionModal={createUpdateSectionModal}
          onSubmit={() => {
            callAPIGetListSection();
          }}
        />
      )}
      {deleteItem && (
        <DeleteItemModal
          item={deleteItem.item}
          onClose={() => {
            setDeleteItem(undefined);
          }}
          onHandleDelete={handleDelete}
        />
      )}
    </AdminTemplate>
  );
};

export default AdminSectionFloorManagement;
