// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import { Chart, ChartData, ChartDataset, ChartOptions, Plugin } from 'chart.js';
import Popover from 'components/atoms/Popover';
import { IconDownload } from 'components/atoms/icons/IconDownload';
import dayjs from 'dayjs';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import React, { useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { DATE_FORMAT } from 'util/ConstantValues';
import {
  forceBreakLine,
  getBrightnessFromColor,
  mergeClasses,
  normalizeNumber,
} from 'util/commons';
import { initDatasetColors } from 'util/dashboardTypes';
import DashboardDownloadModal from '../DashboardDownloadModal';
import PreviewChartModal from '../PreviewChartModal';
import styles from './DonutChart.module.scss';
import LoadingCoating from 'components/atoms/LoadingCoating';

export interface DonutChartProps {
  data: {
    label: string;
    value: number;
  }[];
  title?: string;
  content?: string;
  textCenter?: string;
  handleDownloadCSV?: () => void;
  isDownloadPNG?: boolean;
  emptyMessage?: string;
  chartOptions?: ChartOptions<'doughnut'>;
  height?: string | number;
  description?: React.ReactNode | string | null;
  customCore?: {
    content: React.ReactNode;
    tooltipOdd?: number;
  };
  tooltipMapper?: {
    mapper?: Record<number, number>;
    unit?: string;
  };
  showPercentValue?: boolean;
  onChartClick?: () => void;
  percentShowLimit?: number | boolean;
  colorsPalette?: string[];
  datasetsConfig?: Partial<ChartDataset<'doughnut', number[]>>;
  detailChartConfig?: {
    alternativeData?: {
      label: string;
      value: number;
    }[];
    colorsPalette?: string[];
  };
  chartOnlyMode?: PluginMode | false;
  wrapperStyle?: React.CSSProperties;
  customAction?: React.ReactNode;
  legendMode?: 'row' | 'column';
  downloadable?: boolean;
  loading?: boolean;
}

export enum PluginMode {
  Normal = 'normal',
  Preview = 'preview',
  Download = 'download',
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  title,
  content,
  textCenter,
  handleDownloadCSV,
  isDownloadPNG,
  emptyMessage,
  chartOptions,
  height,
  description,
  customCore,
  tooltipMapper,
  showPercentValue = true,
  onChartClick,
  percentShowLimit = 10,
  colorsPalette = initDatasetColors,
  datasetsConfig,
  detailChartConfig,
  chartOnlyMode,
  wrapperStyle,
  customAction,
  legendMode = 'row',
  downloadable,
  loading,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenDownload, setOpenDownload] = useState(false);
  const [isChartTooltipShown, setIsChartTooltipShown] =
    useState<boolean>(false);

  const [coreWrapperStyle, setCoreWrapperStyle] =
    useState<
      Partial<Record<PluginMode, { width?: number; fontSize?: number }>>
    >();
  const coreWrapperStyleInitialized = useRef<
    Partial<Record<PluginMode, boolean>>
  >({});

  const [showCoreAboveChart, setShowCoreAboveChart] = useState(false);

  const textColors = colorsPalette.map((color) =>
    getBrightnessFromColor(color) <= 100 ? '#ffffff' : '#1b1b1b'
  );

  const getChartData = (mode?: PluginMode): ChartData<'doughnut'> => {
    let dataArr = data;
    let colorsArr = colorsPalette;

    if (mode === PluginMode.Preview || mode === PluginMode.Download) {
      dataArr = detailChartConfig?.alternativeData ?? data;
      colorsArr = detailChartConfig?.colorsPalette ?? colorsPalette;
    }

    return {
      labels: dataArr.map((i) => i.label),
      datasets: [
        {
          data: dataArr.map((i) => i.value),
          backgroundColor: colorsArr,
          borderColor: '#2C2C2C',
          borderWidth: 2,
          datalabels: {
            color: textColors,
            font: { size: 11, weight: 'bold' },
            formatter: function (value) {
              const cvValue = Number(value);

              if (showPercentValue) {
                return percentShowLimit === false ||
                  cvValue >= Number(percentShowLimit)
                  ? normalizeNumber({ value }) + '%'
                  : '';
              }

              const total = data.reduce(
                (sum: number, { value }) => sum + value,
                0
              );

              return percentShowLimit === false ||
                (cvValue / total) * 100 >= Number(percentShowLimit)
                ? normalizeNumber({ value })
                : '';
            },
          },
          ...datasetsConfig,
        },
      ],
    };
  };

  const options = (): ChartOptions<'doughnut'> => {
    const { plugins, ...restOptions } = chartOptions ?? {};
    const { tooltip: tooltipConfig, ...restPlugins } = plugins ?? {};

    return {
      cutout: '55%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (item) =>
              forceBreakLine({
                text:
                  item.label +
                  ': ' +
                  normalizeNumber({
                    value:
                      tooltipMapper?.mapper?.[item.parsed.toString()] ??
                      item.parsed,
                  }) +
                  (tooltipMapper?.unit ?? '%'),
                breakNode: 20,
              }).split('\n'),
          },
          external: ({ tooltip }) => {
            setIsChartTooltipShown(tooltip.getActiveElements().length > 0);
          },
          ...tooltipConfig,
        },
        ...restPlugins,
      },
      ...restOptions,
    };
  };

  const plugins = (
    mode: PluginMode = PluginMode.Normal
  ): Plugin<'doughnut'>[] => {
    if (customCore) {
      return [
        {
          id: 'getCoreWrapperStyle',
          beforeDraw: ({ chartArea: { height, width } }: Chart) => {
            const sizeVal = Math.min(width, height) / 2;

            if (!coreWrapperStyleInitialized.current[mode]) {
              setCoreWrapperStyle((prev) => ({
                ...prev,
                [mode]: { width: sizeVal, fontSize: sizeVal / 4.5 },
              }));

              coreWrapperStyleInitialized.current[mode] = true;
            }
          },
        },
      ];
    }

    return [];
  };

  const renderLegend = (params?: { isColumn?: boolean; mode?: PluginMode }) => {
    if (data.every(({ value }) => !value)) {
      return null;
    }

    const { isColumn, mode } = params ?? {};
    let dataArr = data;
    let colorsArr = colorsPalette;

    if (mode === PluginMode.Download || mode === PluginMode.Preview) {
      dataArr = detailChartConfig?.alternativeData ?? dataArr;
      colorsArr = detailChartConfig?.colorsPalette ?? colorsArr;
    }

    return (
      <div className={isColumn ? styles.legendColum : styles.legendArea}>
        {dataArr.map((i, idx) => {
          return (
            <div
              key={idx}
              className={
                isColumn
                  ? styles.legendColumItem
                  : mergeClasses(styles.legend, {
                      [styles.legend2]: idx % 2 > 0,
                    })
              }
            >
              <div>
                <div
                  style={{
                    backgroundColor: colorsArr[idx],
                    ...(mode === PluginMode.Download && {
                      marginTop: 2.5,
                    }),
                  }}
                  className={styles.legendColor}
                />
              </div>
              <p
                className={
                  isColumn
                    ? styles.legendColumn
                    : `${styles.legendLabel} ${title}-chartLegendLabel`
                }
                title={i.label}
              >
                {i.label}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const handleDownloadPNG = async () => {
    const exportChart = document.getElementById(`${title}-exportChart`);
    const canvas = await html2canvas(exportChart as HTMLElement, {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    });

    const dataURL = canvas.toDataURL('image/png');
    downloadjs(
      dataURL,
      `${title} ${dayjs().format(DATE_FORMAT.slaYMDHm)}.png`,
      'image/png'
    );
  };

  const renderCoreWrapper = (mode: PluginMode = PluginMode.Normal) => {
    if (!customCore) {
      return null;
    }

    const style = coreWrapperStyle?.[mode];

    return (
      <div
        style={{
          ...style,
          color: 'white',
          fontFamily: 'Arial',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          zIndex: !isChartTooltipShown
            ? mode === PluginMode.Preview
              ? 10001
              : 10000
            : undefined,
        }}
      >
        {textCenter && (
          <span
            style={
              style?.fontSize
                ? { fontSize: style.fontSize / 1.75, visibility: 'hidden' }
                : undefined
            }
          >
            {textCenter}
          </span>
        )}

        {mode !== PluginMode.Download ? (
          <Popover
            positions={['top', 'bottom', 'right', 'left']}
            arrowContainerConfigs={{
              arrowColor: 'rgba(0, 0, 0, 0.8)',
            }}
            popUpStyle={{
              background: 'rgba(0, 0, 0, 0.8)',
            }}
            containerStyle={{
              zIndex: '100000',
            }}
            autoGen={{
              wrapperStyle: {
                fontWeight: 'bold',
                marginTop: 8,
                marginBottom: 8,
              },
              odd: customCore.tooltipOdd,
            }}
            onTextOverflow={setShowCoreAboveChart}
          >
            <>{customCore.content}</>
          </Popover>
        ) : (
          <div style={{ marginTop: 8, marginBottom: 8 }}>
            {customCore.content}
          </div>
        )}

        {textCenter && (
          <span
            style={
              style?.fontSize ? { fontSize: style.fontSize / 1.75 } : undefined
            }
          >
            {textCenter}
          </span>
        )}
      </div>
    );
  };

  if (chartOnlyMode) {
    return (
      <div className={styles.main}>
        <div className={styles.donutchart}>
          <Doughnut
            options={options()}
            plugins={plugins(chartOnlyMode)}
            data={getChartData(chartOnlyMode)}
            height={height}
          />

          {renderCoreWrapper(chartOnlyMode)}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.donut} style={wrapperStyle}>
      <LoadingCoating
        show={loading}
        styles={{ borderRadius: 12, zIndex: 98 }}
      />

      <div className={styles.heading}>
        <div className={styles.headingBox}>
          <p>{title}</p>
          {content && <p className={styles.content}>{content}</p>}
        </div>

        <div className={styles.actionsWrapper}>
          {customAction}

          {data.length > 0 && downloadable && (
            <div
              className={styles.buttonDownload}
              onClick={() => {
                isDownloadPNG ? handleDownloadPNG() : setOpenDownload(true);
              }}
            >
              <IconDownload />
            </div>
          )}
        </div>
      </div>

      {data.length > 0 ? (
        <>
          <div
            className={legendMode === 'column' ? styles.preview : styles.main}
            style={{
              zIndex: 97,
            }}
            onClick={() => {
              if (data.every(({ value }) => !value)) {
                return;
              }

              if (onChartClick) {
                onChartClick();
              } else {
                setOpen(true);
              }
            }}
          >
            <div
              className={
                legendMode === 'column' ? styles.chart : styles.donutchart
              }
            >
              <Doughnut
                options={options()}
                plugins={plugins(PluginMode.Normal)}
                data={getChartData()}
                height={height}
              />

              {renderCoreWrapper()}
            </div>
            {renderLegend({
              isColumn: legendMode === 'column',
            })}
          </div>

          {downloadable && (
            <div
              id={`${title}-exportChart`}
              className={styles.main}
              style={{
                position: 'fixed',
                zIndex: -Number.MAX_SAFE_INTEGER,
                top: 0,
                left: -Number.MAX_SAFE_INTEGER,
                width: 650,
              }}
            >
              {showCoreAboveChart && customCore != null && (
                <div
                  className={styles.total}
                  style={{
                    fontSize:
                      coreWrapperStyle?.[PluginMode.Download]?.fontSize ?? 14,
                  }}
                >
                  {customCore.content} {textCenter}
                </div>
              )}

              <div className={styles.donutchart}>
                <Doughnut
                  options={{
                    ...options(),
                    maintainAspectRatio: false,
                  }}
                  plugins={plugins(PluginMode.Download)}
                  data={getChartData(PluginMode.Download)}
                  height={650}
                  width={650}
                />
                {!showCoreAboveChart && renderCoreWrapper(PluginMode.Download)}
              </div>
              {renderLegend({
                mode: PluginMode.Download,
              })}
            </div>
          )}
        </>
      ) : (
        <div className={styles.emptyView}>
          <p>{emptyMessage}</p>
        </div>
      )}

      {isOpen && (
        <PreviewChartModal
          title={title}
          description={description}
          children={
            <>
              <div className={styles.preview}>
                <div className={styles.chart}>
                  <Doughnut
                    options={options()}
                    plugins={plugins(PluginMode.Preview)}
                    data={getChartData(PluginMode.Preview)}
                    width={350}
                    height={350}
                  />

                  {renderCoreWrapper(PluginMode.Preview)}
                </div>
                {renderLegend({
                  isColumn: true,
                  mode: PluginMode.Preview,
                })}
              </div>
            </>
          }
          onClose={() => {
            setOpen(false);
          }}
        />
      )}

      {isOpenDownload && (
        <DashboardDownloadModal
          onClose={() => {
            setOpenDownload(false);
          }}
          handleDownload={(data) => {
            if (data.isImage) {
              handleDownloadPNG();
            }
            if (data.isCsv && handleDownloadCSV) {
              handleDownloadCSV();
            }
            setOpenDownload(false);
          }}
        />
      )}
    </div>
  );
};

DonutChart.defaultProps = {
  downloadable: true,
};

export default DonutChart;
