// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
import './ScaleEmissionInput.scss';
import { Form, Field } from 'react-final-form';
import { PrimaryBtnField } from 'components/molecules/PrimaryBtnField';
import { requiredHalfWidthDecimalNumber } from 'util/validator';
import { ImagePath } from 'util/ConstantValues';
import { useTranslation } from 'react-i18next';

interface Props {
  wasteId: string;
  wasteName: string;
  weight?: string;
  onSubmit: (values) => void;
}

type Input = {
  weight: string;
};

export function ScaleEmissionInput({
  wasteId,
  wasteName,
  weight,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="description">{t('scale_emission.input.description')}</div>

      <Form<Input>
        initialValues={{ weight: weight }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit} className="scale-input">
            <div className="input-type">
              <div>{wasteName}</div>
              <img src={ImagePath[wasteId]} />
            </div>
            <div className="input-field">
              <Field
                name={'weight'}
                type={'text'}
                validate={(value) =>
                  requiredHalfWidthDecimalNumber(value, t('common.weight'))
                }
              >
                {({ input }) => (
                  <>
                    <div className={'input-num'}>
                      <input
                        {...input}
                        id={'weight'}
                        type={'text'}
                        name={'weight'}
                        onChange={(e) => {
                          input?.onChange(e);
                        }}
                      />
                      <span className="unit">kg</span>
                    </div>
                  </>
                )}
              </Field>
            </div>

            <PrimaryBtnField
              text={t('scale_emission.input.button_confirm')}
              disabled={!valid}
            ></PrimaryBtnField>
          </form>
        )}
      </Form>
    </>
  );
}
