// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

import { IconClose } from 'components/atoms/icons/IconClose';
import { Modal } from 'components/atoms/Modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'util/Enums';
import { FloorItem, SectionItem, TenantSiteForm } from 'util/Types';
import styles from './CreateUpdateTenantSiteModal.module.scss';
import { TenantIndustry } from 'apis/tenants';
import { baseTenantSiteForm } from 'util/ConstantValues';
import CreateUpdateTenantSiteForm from 'components/molecules/CreateUpdateTenantSiteForm/CreateUpdateTenantSiteForm';
import { TenantCreateEditModalType } from 'pages/TenantManagement';
import { FormRenderProps } from 'react-final-form';

interface Props {
  onClose: () => void;
  createUpdateModal: TenantCreateEditModalType;
  floorList: FloorItem[];
  sectionList: SectionItem[];
  onSubmit: (tenantForm: TenantSiteForm, tenantId?: number) => void;
  tenantIndustries: TenantIndustry[];
  hanldeGenerateBarcode: (props: FormRenderProps<TenantSiteForm>) => void;
}

const CreateUpdateTenantSiteModal: React.FC<Props> = ({
  floorList,
  sectionList,
  onClose,
  createUpdateModal,
  onSubmit,
  tenantIndustries,
  hanldeGenerateBarcode,
}) => {
  const { t } = useTranslation();
  const [initValues, setInitValuesForm] =
    useState<TenantSiteForm>(baseTenantSiteForm);
  const { tenantEditing } = createUpdateModal;

  useEffect(() => {
    if (
      createUpdateModal.type === ModalType.update &&
      tenantEditing?.id != null
    ) {
      const values: TenantSiteForm = {
        ...baseTenantSiteForm,
        ...(tenantEditing.section?.id && {
          sectionId: tenantEditing.section.id.toString(),
        }),
        ...(tenantEditing.floor?.id && {
          floorId: tenantEditing.floor.id.toString(),
        }),
        ...(tenantEditing.icCards &&
          tenantEditing.icCards.length > 0 && {
            icCards: tenantEditing.icCards,
          }),
        name: tenantEditing.name,
        industry: tenantEditing.industry || '',
        tenantNumber: tenantEditing.tenantNumber || '',
        contactName: tenantEditing.contactName || '',
        contactEmail: tenantEditing.contactEmail || '',
        barcode: tenantEditing.barcode || '',
      };

      setInitValuesForm(values);
    }
  }, [createUpdateModal]);

  return (
    <div className={styles.createTenantModal}>
      <Modal>
        <div className={styles.modalContent}>
          <div className={styles.customTitle}>
            <p className={styles.descriptionTitle}>
              {createUpdateModal.type === ModalType.add
                ? t('common.button.add_tenant')
                : t('common.button.edit_tenant')}
            </p>
            <div className={styles.top}>
              <IconClose onClick={onClose} />
            </div>
          </div>

          <CreateUpdateTenantSiteForm
            initValues={initValues}
            createUpdateModal={createUpdateModal}
            onSubmit={onSubmit}
            floorList={floorList}
            sectionList={sectionList.map((section) => {
              return { value: section.id, label: section.name };
            })}
            tenantIndustries={tenantIndustries}
            hanldeGenerateBarcode={hanldeGenerateBarcode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateUpdateTenantSiteModal;
